import {useState, useCallback, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import { Button } from '@material-ui/core';
import SimpleGrid from "../../components/SimpleGrid/SimpleGrid";
import VisitRowTemplate from "./VisitRowTemplate";
import VisitFilterTemplate from "./VisitFilterTemplate";
import {getCenterVisits} from "../../apiCalls/getCenterVisits";
import {getOptions} from "../../apiCalls/getOptions";
import axios from 'axios';
import {connect} from 'react-redux';

const DEFAULT_VISITS_LIST_HEADCELLS = [
    {id: 'centerId', label: 'Center Code', showColumn: true, sortable: 'true'},
    {id: 'membershipId', label: 'Member Number', showColumn: true, sortable: 'true'},
    {id: 'primaryMember', label: 'Primary Member Name', showColumn: true, sortable: 'true'},
    {id: 'visitDate', label: 'Visit Date', showColumn: true, sortable: 'true'},
    {id: 'visitTypeId', label: 'Type of Visit', showColumn: true, sortable: 'true'},
    {id: 'isValid', label: 'Valid', showColumn: true, sortable: 'true'},
];

const DEFAULT_VISITS_FILTER_VALUES = {
    centerId: '',
    membershipId: '',
    dateOfVisit: '',
    visitTypeId: ''
};

const DEFAULT_VISITS_PER_PAGE = 50;

const VisitsFeature = props => {

    const {accountInfo} = props;

    const [filterValues, setFilterValues] = useState({...DEFAULT_VISITS_FILTER_VALUES});
    const [filterCount, setFilterCount] = useState(0);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [totalRecords, setTotalRecords] = useState([]);
    const [visitTypes, setVisitTypes] = useState([]);
    const [authToken, setAuthToken] = useState("");

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    const source = axios.CancelToken.source();
    const history = useHistory();

    useEffect(() => {
        if(authToken) {
            getOptions(axios.CancelToken.source(), 'visits', authToken)
            .then(response => {
                setVisitTypes(response.map(d => {
                    return {
                        label: d.label,
                        value: d.code
                    }
                }));
            });
            getCenterVisits(source, 0, 100000, {}, authToken)
            .then(response => {
                setTotalRecords(response.data || []);
            });
        }
    }, [authToken]);

    const handleFilterChange = (key, value) => {
        setFilterValues(prevValues => {
            return {
                ...prevValues,
                [key]: value
            }
        });
    };

    const handleApplyFilter = () => {
        console.log('Filters applied ', filterValues);
        let count = 0;
        for(const field in filterValues) {
            if(filterValues[field]) count = count+1;
        }
        setFilterCount(count);
        setRefreshGrid(true);
    }

    const handleClearFilter = () => {
        setFilterValues({...DEFAULT_VISITS_FILTER_VALUES});
        setFilterCount(0);
        setRefreshGrid(true);
    }

    const handleGetData = useCallback(index => {
        return getCenterVisits(source, index, DEFAULT_VISITS_PER_PAGE,
            { ...filterValues, centerId: filterValues.centerId ? `like:${filterValues.centerId}` : ''}, authToken);
    }, [filterValues, authToken]);

    return authToken && <SimpleGrid
        title="Center-visit"
        rowsPerPage={DEFAULT_VISITS_PER_PAGE}
        headCells={[...DEFAULT_VISITS_LIST_HEADCELLS]}
        getData={handleGetData}
        identifier="centerId"
        rowTemplate={<VisitRowTemplate visitTypes={visitTypes}/>}
        createTemplate={<div/>}
        editTemplate={<div/>}
        filterTemplate={
            <VisitFilterTemplate
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
                visitTypes={visitTypes}
            />
        }
        onApplyFilter={handleApplyFilter}
        onClearFilter={handleClearFilter}
        filterCount={filterCount}
        refreshGrid={refreshGrid}
        resetRefreshGrid={setRefreshGrid}
        exportToExcel={true}
        excelData= {{ columns: DEFAULT_VISITS_LIST_HEADCELLS, data: totalRecords }}
        additionalButtons={[(
            <Button 
                variant="contained"
                color="primary"
                onClick={() => history.push('/commissions')}
                size="large"
                style={{padding: '5px 15px'}}
            >
                Commissions
            </Button>
        ), (
            <Button 
                variant="contained"
                color="primary"
                onClick={() => history.push('/members')}
                size="large"
                style={{padding: '5px 15px'}}
            >
               Payments
            </Button>
        )]}
    />
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(VisitsFeature);