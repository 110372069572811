import {
    Grid
} from '@material-ui/core';
import TextField from "../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import SmartDropDown from "../common/SmartDropDown/SmartDropDown";

const VisitFilterTemplate = props => {

    const {filterValues, onFilterChange, visitTypes} = props;

    return <>
                <Grid item xs={6}>
                    <TextField 
                        label="Center Code"
                        value={filterValues.centerId}
                        onChange={val => onFilterChange('centerId', val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Member Number"
                        value={filterValues.memberId}
                        onChange={val => onFilterChange('membershipId', val)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField 
                        label="Date of Visit"
                        fullWidth={true}
                        value={filterValues.dateOfVisit}
                        onChange={date => onFilterChange('dateOfVisit', date)}
                        required={true}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SmartDropDown
                        label="Visit Type"
                        category={'visits'}
                        value={filterValues.visitTypeId}
                        loading={true}
                        onChange={val => onFilterChange('visitTypeId', val)}
                        menuItems={visitTypes}
                    />
                </Grid>
            </>
}

export default VisitFilterTemplate;