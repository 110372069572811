import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { ReactComponent as BackOfficeIcon } from "./category.svg";
import {
  makeStyles,
  useTheme,
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  SvgIcon,
  Popover,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import {
  Menu,
  ChevronLeft,
  ChevronRight
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import companyLogo from "../../assets/media/companyLogo.png";
import { COLOR_PALETTE } from "../../config/colorPalette";
import { connect } from 'react-redux';
import ExcelExporter from "../../components/ExcelExporter/ExcelExporter";
import {getPaymentPerformance} from '../../apiCalls/getPaymentPerformance';
import axios from 'axios';
import ReportDropDown from '../../features/common/ReportDropDown/ReportDropDown';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: COLOR_PALETTE.orange.opacity100
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    overflowY: 'hidden',
    marginTop: 65,
    width: '100%'
  },
  link: {
    textDecoration: 'none',
    color: COLOR_PALETTE.green.opacity100
  },
  agentInfo: process.env.REACT_APP_ENVIRONMENT === 'PROD'?  {
    color: COLOR_PALETTE.green.opacity100,
    //border: `2px solid ${COLOR_PALETTE.orange.opacity100}`,
    float: 'right',
    borderRadius: '3px',
    padding: '2px 6px',
    marginTop: '12px'
  } : {
    color: COLOR_PALETTE.red.opacity100,
    //border: `2px solid ${COLOR_PALETTE.orange.opacity100}`,
    float: 'right',
    borderRadius: '3px',
    padding: '2px 6px',
    marginTop: '12px'
  },
  icon: {
    float: 'right',
    marginTop: '10px'
  },
  flexStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10
  },
  userName: {
    cursor: 'pointer'
  },
  tableWrapper: {
    padding: `10px 10px 0px 10px`,
  },
  table: {
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  tableHead: {
    '& th': {
      fontWeight: 'bold',
      color: '#0C2340'
    }
  }
}));

const DEFAULT_PAYMENT_LIST_HEADCELLS = [
  {id: 'label', label: 'Category'},
  {id: 'value', label: 'Amount'}
];

const Navigation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [authToken, setAuthToken] = useState("");

  const { routes, children, accountInfo, logout } = props
  const source = axios.CancelToken.source();

  useEffect(() => {
    if(accountInfo && accountInfo.jwtIdToken) {
        setAuthToken(accountInfo.jwtIdToken);
    }
}, [accountInfo])

  useEffect(() => {
    if(authToken) getPaymentPerformance(source, authToken).then((response) => setPaymentData(response?.data || []));
  }, [authToken]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const show = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs={1}><IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <Menu />
            </IconButton></Grid>
            <Grid item xs={4}><Typography variant="h6" noWrap>
              <img src={companyLogo} alt="Company Logo" height="40" />
            </Typography></Grid>
            <Grid item xs={3}>
              <ReportDropDown></ReportDropDown>
            </Grid>
            <Grid item xs={4} onClick={handleClick} className={classes.userName}>
              <SvgIcon color="primary" className={classes.icon}>
                <BackOfficeIcon></BackOfficeIcon>
              </SvgIcon>
              <Typography className={classes.agentInfo}>
                {
                  accountInfo && accountInfo.account && accountInfo.account.name ?
                  accountInfo.account.name:
                  ''
                }
              </Typography>
              <Typography className={classes.agentInfo}>{process.env.REACT_APP_ENVIRONMENT}</Typography>                            
            </Grid>
            <Popover
              id={id}
              open={show}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <TableContainer classes={{ root: classes.tableWrapper }}>
                    <Table size="small" classes={{ root: classes.table }}>
                        <TableHead classes={{ root: classes.tableHead }}>
                            <TableRow>
                                {
                                    DEFAULT_PAYMENT_LIST_HEADCELLS.map(headCell => {
                                        return <TableCell
                                            key={headCell.id}
                                            align="left"
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                              paymentData && paymentData?.map((row, index) => {
                                  return <TableRow
                                      hover
                                      key={index}
                                  >
                                      <TableCell align="left">{row.label}</TableCell>
                                      <TableCell align="left">{`$${row.value}`}</TableCell>
                                  </TableRow>
                              })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.flexStyle}>
                  <ExcelExporter fileName={"Performance Management"} sheetName={"Performance Management"}
                    columns = {DEFAULT_PAYMENT_LIST_HEADCELLS}
                    data={paymentData}
                  />
                </div>
            </Popover>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {
            routes.map(route => {
              if (route.showInNavigation) {
                if (route.linkName === 'Signout') {
                  return <Link
                    to={location => location}
                    style={{ textDecoration: 'none' }}
                    onClick={logout}
                    key={route.linkName}
                    className={classes.link}>
                    <ListItem button>
                      <ListItemIcon>{route.icon}</ListItemIcon>
                      <ListItemText primary={route.linkName} />
                    </ListItem>
                  </Link>
                }
                return <Link to={route.path} key={route.linkName} className={classes.link}>
                  <ListItem button>
                    <ListItemIcon>{route.icon}</ListItemIcon>
                    <ListItemText primary={route.linkName} />
                  </ListItem>
                </Link>
              }
              else return null
            })
          }
        </List>
      </Drawer>
      <main className={classes.content}>
        {children}
      </main>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    accountInfo: state.accountInfo
  }
}

export default connect(mapStateToProps)(Navigation)