import {
    TableContainer,
    Link,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Typography
} from '@material-ui/core';
import { LocationCitySharp } from '@material-ui/icons';

import AttemptPopOver from '../../../components/PopOver/AttemptPopOver'
import AmountPopOver from '../../../components/PopOver/AmountPopOver'
import { useEffect, useState } from 'react';

const DEFAULT_ATTEMPTS_HEADCELLS = [
    {id: 'details', label: ''},
    {id: 'attemptDate', label: 'Attempt Date'},
    {id: 'paymentDate', label: 'Payment Date'},
    {id: 'attemptedAmount', label: 'Attempted'},
    {id: 'paidAmount', label: 'Paid'},
    {id: 'status', label: 'Status'},
];

const Attempts = props => {

    const {rows} = props;
    const [sortedRows, setSorteRows] = useState()

    useEffect(() => {
        const sorted = rows.sort((a,b) => {
            return a.attemptId > b.attemptId ? 1 : -1;
        }).map(a => a);

        setSorteRows(sorted);
    }, [rows])

    console.log(rows)
    
    return <Paper>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    DEFAULT_ATTEMPTS_HEADCELLS.map(headCell => {
                                        return <TableCell
                                            key={headCell.id}
                                            align="center"
                                            padding="none"
                                        >
                                            {headCell.label}
                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows?.map((row, index) => {
                                    return <TableRow
                                        hover
                                        key={index}
                                    >
                                        <TableCell padding="none" align="center"><AttemptPopOver data={row}></AttemptPopOver></TableCell>
                                        <TableCell padding="none" align="center">{row.attemptDate}</TableCell>
                                        <TableCell padding="none" align="center">{row.paymentDate}</TableCell>
                                        <TableCell padding="none" align="center">{row.attemptDate ? <AmountPopOver data={row}></AmountPopOver> : null}</TableCell>
                                        <TableCell padding="none" align="center">{row.paymentAmount ? row.paymentAmount : null}</TableCell>
                                        <TableCell padding="none" align="center">{
                                            row.stripeIntentId ? <strong><Link style={{ color: "purple" }} href={`https://dashboard.stripe.com/payments/${row.stripeIntentId}`} target={"_blank"}>{row.status}</Link> </strong> 
                                                : row.attemptDate 
                                                    ? row.status
                                                    : 'Pending'
                                        }
                                        </TableCell>
                                    </TableRow>
                                })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
}

export default Attempts;