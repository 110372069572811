import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const saveInvoice = (source, invoice, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).post(`${environment().functionsURL}invoice/${invoice?.paymentTypeId === 'C' ? '' : 'cash-'}payment`,
            {
                ...invoice
            },
            {
                cancelToken: source.token                
            })
            .then(response => {
                resolve(response);
            }).catch(error => reject(error))
    })
}