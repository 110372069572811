import {useEffect} from 'react';
import {
    Grid,
    makeStyles,
    List,
    ListItem,
    ListItemText,
    Divider,
    Typography
} from '@material-ui/core';
import TextField from "../../components/FormInputs/TextField/TextField";
import PaymentCardInput from "../../components/PaymentCardInput/PaymentCardInput";
import PaymentTypeSmartDropDown from "../common/PaymentTypeSmartDropDown/PaymentTypeSmartDropDown";
import {checkCreditCardValidity} from "../../helperFunctions/checkCreditCardValidity";
import {checkCVVValidity} from "../../helperFunctions/checkCVVValidity";
import CheckboxField from "../../components/FormInputs/CheckboxField/CheckboxField";
import AutocompleteDropdown from "../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import getAutoPaymentDays from "../../constants/autoPaymentDays";
import { useState } from 'react';
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import moment from 'moment';
import StripeNavegation from '../../components/StripeNavigation';
import ConfirmDialog from '../../components/Buttons/ConfirmDialog';
import Alert from '@material-ui/lab/Alert';
 
const useStyles = makeStyles(theme => ({
    lineItem: {
        padding: '0px'
    }
}))

const StepThree = (props) => {
    const {data, stepNum, errors, setErrors, hasError, getErrorMessage, onChange, onRequiredChange} = props;

    const {nameOnTheCard, cardNumber, cardExpiryDate, cardCVVNumber, enableAutomaticPayments, applicationDate,
        dayOfAutomaticPayment, paymentType, updateCardOnFile, useCardOnFile, enableForm, isActive, generateInvoices,
        currentDayOfAutomaticPayment, stripeCustomerId, stripePaymentMethodId, nextDueDate} = data[stepNum];

    const {openInvoices} = data[0];

    const {oneTimeFee, monthlyFee, monthsPay, totalPayment, calculatedTotalPayment, planDifference, totalAmountComment, toDate} = data[1];

    const [automaticPaymentDays, setAutomaticPaymentDays] = useState(getAutoPaymentDays(dayOfAutomaticPayment.value))
    const {hasElevatedPermissions} = data[1];

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

    const classes = useStyles();

    const getNextDueDate = (toDate, nextDueDate, dayOfAutomaticPayment) => {
        if(toDate){
            return moment(toDate).add(1, 'day').format("MM/DD/yyyy");
        }

        if(nextDueDate){
            return moment(nextDueDate).format("MM/DD/yyyy");
        }
        return moment(new Date(moment().year, moment().month, dayOfAutomaticPayment)).format("MM/DD/yyyy");
    }

    useEffect(() => {
        const isRequired = paymentType.value === 'C' && enableForm.value && useCardOnFile.value !== true ? true : false;
        onRequiredChange('nameOnTheCard', isRequired, 2);
        onRequiredChange('cardNumber', isRequired, 2);
        onRequiredChange('cardExpiryDate', isRequired, 2);
        onRequiredChange('cardCVVNumber', isRequired, 2);

        setErrors(prevState => {
            const newErrors = {...prevState};
            if(isRequired) {
                newErrors['nameOnTheCard'] = 'Field is required!';
                newErrors['cardNumber'] = 'Field is required!';
                newErrors['cardExpiryDate'] = 'Field is required!';
                newErrors['cardCVVNumber'] = 'Field is required!';
            }
            else {
                delete newErrors['nameOnTheCard'];
                delete newErrors['cardNumber'];
                delete newErrors['cardExpiryDate'];
                delete newErrors['cardCVVNumber'];
            }
            return newErrors;
        });

        if(!enableForm.value) onChange('updateCardOnFile', true, 2);

    }, [paymentType, enableForm])

    useEffect(() => {
        if(useCardOnFile.value) {
            setErrors(prevState => {
                const newErrors = {...prevState};
                delete newErrors['nameOnTheCard'];
                delete newErrors['cardNumber'];
                delete newErrors['cardExpiryDate'];
                delete newErrors['cardCVVNumber'];                
                return newErrors;
            });

            onChange('updateCardOnFile', false, 2);
        }
    }, [useCardOnFile])

    useEffect(() => {
        const newState = !(totalPayment.value > 0) && !checkCCNumberError() && !checkCCVError() && cardNumber.value && paymentType.value === 'C' && !hasError('nameOnTheCard');

        if(checkCCNumberError() || checkCCVError() || !cardNumber.value || paymentType.value !== 'C' || hasError('nameOnTheCard')){
            onChange('updateCardOnFile', false, 2);        
        } 

        if(updateCardOnFile.value === false && newState === true){
            onChange('updateCardOnFile', newState, 2);
            onChange('useCardOnFile', false, 2)
        }
    }, [totalPayment, cardNumber, cardCVVNumber, cardExpiryDate, nameOnTheCard])

    const checkCCNumberError = () => {
        return ((isCalculatedAmount() && useCardOnFile.value !== true)) && (!checkCreditCardValidity(cardNumber.value) || cardNumber.value?.length === 0);
    }

    const checkCCVError = () => {        
        return ((isCalculatedAmount() && useCardOnFile.value !== true)|| cardNumber.value.length !== 0) && (!checkCVVValidity(cardNumber.value, cardCVVNumber.value) || cardCVVNumber.value?.length === 0);
    }
   
    const isVisibleUseCardOnFileCheckBox = () => {
        return (isCalculatedAmount() && stripePaymentMethodId.value && stripePaymentMethodId.value !== '');
    }

    const isCalculatedAmount = () => {
        return (calculatedTotalPayment.value !== undefined && calculatedTotalPayment.value !== null && !isNaN(parseFloat(calculatedTotalPayment.value)) && calculatedTotalPayment.value > 0) 
            || (totalPayment.value && !isNaN(parseFloat(totalPayment.value)) && totalPayment.value > 0);
    }

    console.log(data)

    return <><Grid container spacing={2}>
                <Grid item xs={12}>
                    <PaymentTypeSmartDropDown
                        fullWidth={false}
                        label="Payment Type"
                        value={paymentType.value}
                        onChange={val => onChange('paymentType', val, 2)}
                        required={paymentType.required}
                        error={hasError('paymentType')}
                        helperText={getErrorMessage('paymentType')}
                        allowCourtesy={hasElevatedPermissions}
                        isCourtesyOnly={undefined}
                        disabled={!isCalculatedAmount()}
                    />
                </Grid>
                {
                    paymentType.value.toLowerCase() === 'c' ?
                    <>
                        {
                            isVisibleUseCardOnFileCheckBox() ?
                            <Grid item xs={12} style={{ display: 'flex'}}>
                                <CheckboxField 
                                    label="Use Card On File"
                                    value={useCardOnFile.value}
                                    onChange={val => onChange('useCardOnFile', val, 2)}
                                    size="medium"
                                    name="use-card-on-file"
                                />
                            </Grid> :
                            null            
                        }
                       {(!useCardOnFile.value || !isVisibleUseCardOnFileCheckBox()) && (<>
                            <Grid item xs={12}>
                                <PaymentCardInput
                                    cardDetails={{
                                        cardNumber: {
                                            value: cardNumber.value,
                                            required: (isCalculatedAmount() && useCardOnFile.value !== true),
                                            error: checkCCNumberError(),
                                            helperText: checkCCNumberError() ? "Valid Card Number is required" : ''
                                        },
                                        cardExpiryDate: {
                                            value: cardExpiryDate.value,
                                            required: ((isCalculatedAmount() && useCardOnFile.value !== true)|| cardNumber.value.length === 0),
                                            error: (cardNumber.value || (isCalculatedAmount() && useCardOnFile.value !== true)) && !cardExpiryDate.value,
                                            helperText: ((cardNumber.value || isCalculatedAmount()) && !cardExpiryDate.value ? "Expiration date is required" : '')
                                        },
                                        cardCVVNumber: {
                                            value: cardCVVNumber.value,
                                            required: ((isCalculatedAmount() && useCardOnFile.value !== true)|| cardNumber.value.length === 0),
                                            error: checkCCVError(),
                                            helperText: checkCCVError() ? "Valid CVV is required" : ''
                                        }
                                    }}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField 
                                    label="Name On The Card"
                                    value={nameOnTheCard.value}
                                    onChange={val => onChange('nameOnTheCard', val, 2)}
                                    required={nameOnTheCard.required}
                                    error={(cardNumber.value || (isCalculatedAmount() && useCardOnFile.value !== true)) && !nameOnTheCard.value}
                                    helperText={(cardNumber.value || (isCalculatedAmount() && useCardOnFile.value !== true)) && !nameOnTheCard.value ? 'Valid Name is required' : undefined}
                                />
                            </Grid>
                            {
                                !checkCCNumberError() && !checkCCVError() && cardNumber.value && paymentType.value === 'C' && !hasError('nameOnTheCard') ?
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <CheckboxField 
                                        label="Replace Card On File"
                                        value={updateCardOnFile.value}
                                        onChange={val => onChange('updateCardOnFile', val, 2)}
                                        size="medium"
                                        name="replace-card-on-file"
                                        disabled={!(totalPayment.value > 0)}
                                    />
                                </Grid> :
                                null
                            }
                        </>)}
                            <Grid item xs={6} style={{ display: 'flex' }}>
                                <CheckboxField 
                                    label="Enable Automatic Payments"
                                    value={enableAutomaticPayments.value}
                                    onChange={val => {
                                        onChange('enableAutomaticPayments', val);
                                        if(!val) onChange('dayOfAutomaticPayment', 1)
                                    }}
                                    size="medium"
                                    name="enable-auto-payments"
                                    // disabled={!enableForm.value}
                                />                                    
                            </Grid>
                            <Grid item xs={6}>
                                <AutocompleteDropdown
                                    label="Day Of Automatic Payment"
                                    options={automaticPaymentDays}
                                    value={dayOfAutomaticPayment}
                                    valueIndexes={dayOfAutomaticPayment.value ? [automaticPaymentDays.findIndex(apd => apd.value === dayOfAutomaticPayment.value)]: []}
                                    loading={false}
                                    onChange={val => {
                                        onChange('dayOfAutomaticPayment', val);
                                        setIsConfirmationOpen(val < currentDayOfAutomaticPayment.value)
                                    }}
                                    required={dayOfAutomaticPayment.required}
                                    error={hasError('dayOfAutomaticPayment')}
                                    disabled={!enableAutomaticPayments.value /*|| !enableForm.value*/}
                                    helperText={getErrorMessage('dayOfAutomaticPayment')}
                                />
                            </Grid>
                    </> :
                    null
                }
                <Grid item xs={3}>
                    <TextField 
                        label="Stripe Customer ID"
                        value={stripeCustomerId.value}
                        onChange={val => onChange('stripeCustomerId', val)}
                        required={stripeCustomerId.required}
                        error={hasError('stripeCustomerId')}
                        helperText={getErrorMessage('stripeCustomerId')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label="Stripe Payment Method ID"
                        value={stripePaymentMethodId.value}
                        onChange={val => onChange('stripePaymentMethodId', val)}
                        required={stripePaymentMethodId.required}
                        error={hasError('stripePaymentMethodId')}
                        helperText={getErrorMessage('stripePaymentMethodId')}
                    />
                </Grid>
                <Grid item xs={1}>
                    {stripeCustomerId.value && stripeCustomerId.value.length > 0 ? 
                        //<Link href={`https://dashboard.stripe.com/customers/${stripeCustomerId.value}`} target={"_blank"}>Stripe</Link> 
                        <StripeNavegation url='customers' stripeId={stripeCustomerId.value}></StripeNavegation>
                        : <Typography>{''}</Typography>}
                </Grid>
                <Grid item xs={1}>
                    <CheckboxField 
                        label="Active"
                        value={isActive.value}
                        onChange={val => {
                            onChange('isActive', val);
                            //if(!val) onChange('isActive', '')
                        }}
                        size="medium"
                        name="enable-active"                                    
                        // disabled={!enableForm.value}
                    />                                     
                </Grid>
                <Grid item xs={2}>
                    <CheckboxField 
                        label="Generate Invoices"
                        value={generateInvoices.value}
                        onChange={val => {
                            onChange('generateInvoices', val);
                            //if(!val) onChange('generateInvoices', '')
                        }}
                        size="medium"
                        name="enable-generate-inv"
                        disabled={generateInvoices.value === true || !hasElevatedPermissions}
                        // disabled={!enableForm.value}
                    />            
                </Grid>                         
                <Grid item xs={12}>
                    <List aria-label="main mailbox folders">
                        <ListItem className={classes.lineItem}>                        
                            <Grid container>
                                <Grid item xs={6}><ListItemText>{`Application Date: ${moment(applicationDate.value).format('MM/DD/YYYY')}`}</ListItemText></Grid>                                
                                <Grid item xs={6}><ListItemText>{`Change Date: ${moment().format('MM/DD/YYYY')}`}</ListItemText></Grid>                                
                            </Grid>
                        </ListItem>
                        <Divider />
                        <ListItem className={classes.lineItem}>
                            <ListItemText>{`One Time Fee: $${oneTimeFee.value}`}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className={classes.lineItem}>
                            <ListItemText>{`Monthly Amount: $${monthlyFee.value}`}</ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className={classes.lineItem}>
                            <ListItemText>{`Months: ${monthsPay.value}`}</ListItemText>
                        </ListItem>
                        <ListItem className={classes.lineItem}>
                            <ListItemText>{`Next Due Date: ${moment(getNextDueDate()).isValid() ? getNextDueDate() : ''}`}</ListItemText>
                        </ListItem>
                        <ListItem className={classes.lineItem}>
                            <Grid container spacing={2}>
                                <Grid item xs={1}>                             
                                    <ListItemText><strong>Total</strong></ListItemText>
                                </Grid>
                                <Grid item xs={1}>                                
                                    <TextField 
                                        label="Total"
                                        disabled={openInvoices.value && Array.isArray(openInvoices.value) && openInvoices.value.length > 0}
                                        value={totalPayment.value}
                                        onChange={val => {
                                            onChange('totalPayment', val, 1);
                                            if(!toDate?.value || toDate?.value === ''){
                                                debugger;
                                                onChange('toDate', moment().toDate(), 1)
                                            }
                                        }}
                                        // disabled={!calculatedTotalPayment.value || isNaN(parseFloat(calculatedTotalPayment.value)) || calculatedTotalPayment.value === 0}
                                        isNumericOnly/>
                                </Grid>
                                <Grid item xs={2}>                                
                                    <DateTimeField
                                        label="Invoice End Date"
                                        fullWidth={true}
                                        value={toDate?.value}
                                        onChange={date => {
                                            debugger;
                                            onChange('toDate', date, 1)
                                        }}
                                        required={!isCalculatedAmount()}
                                        error={hasError('toDate')}
                                        // disabled={!(calculatedTotalPayment.value && !isNaN(parseFloat(calculatedTotalPayment.value)) && calculatedTotalPayment.value !== 0)}
                                        localeFormat="MM/DD/yyyy"
                                        helperText={getErrorMessage('toDate')}
                                        disablePast={true}
                                        disabled={openInvoices.value && Array.isArray(openInvoices.value) && openInvoices.value.length > 0}
                                    />
                                </Grid>                                
                                <Grid item xs={8}>
                                    <TextField 
                                        // disabled={!calculatedTotalPayment.value || isNaN(parseFloat(calculatedTotalPayment.value)) || calculatedTotalPayment.value === 0} 
                                        disabled={openInvoices.value && Array.isArray(openInvoices.value) && openInvoices.value.length > 0}
                                        label="Comment" value={totalAmountComment.value} onChange={val => onChange('totalAmountComment', val, 1)}></TextField>
                                </Grid>
                            </Grid> 
                        </ListItem>
                    </List>
                </Grid>
            
            </Grid>
            <ConfirmDialog 
                title={'Automatic Payment day change'}
                open={isConfirmationOpen}
                setOpen={(val) => setIsConfirmationOpen(val)}
                onConfirm={() => {return}}
                onCancel={() => onChange('dayOfAutomaticPayment', currentDayOfAutomaticPayment.value)}
            >
                <Alert variant="outlined" severity="warning">
                    Changing the Automatic Payment Day will force the latest invoice to shorten the period and will delete future unpaid invoices. Are you sure you want to shorten the period?
                </Alert>
            </ConfirmDialog>  
        </>
}

export default StepThree