import React from 'react';
import {Phone, PhoneDisabled, LocalAtm, History} from '@material-ui/icons';
import {TableCell, Typography, IconButton, Tooltip} from '@material-ui/core';
import {EventOutlined, PanToolOutlined, Payment, Receipt, TouchAppOutlined} from '@material-ui/icons';
import { ReactComponent as ExcelIcon } from "../../components/ExcelExporter/excel.svg";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {ReactComponent as FamilyIcon} from '../../assets/media/family-svgrepo-com.svg';
import {ReactComponent as CoupleIcon} from '../../assets/media/couple-user-svgrepo-com.svg';
import {ReactComponent as IndividualIcon} from '../../assets/media/person-svgrepo-com.svg';
import moment from 'moment'


const BACKGROUND_COLORS = {
    'Unpaid Invoices': '#ffcccc',
    'All Paid': '#ccffcc',
    'Pending': '#fecb78',
    'Draft': '#dcdcdc'
}

const MemberRowTemplate = props => {
    const {data, columnOrder, handleManualPayment, handleGenerateInvoices, handleDownloadHistory, isElevatedPermissions} = props;

    const COLOR = data.membershipId < 0 ? BACKGROUND_COLORS['Draft'] : BACKGROUND_COLORS[data.paymentStatus]; 

    return columnOrder.map(column => {
        if(column.showColumn) {
            if (column.id === 'agentName') {
                return (
                    <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>
                        {`${data.agentFirstName} ${data.agentLastName}`}
                    </TableCell>)
            }
            if(column.id === 'flags') {
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>                    
                    {
                        data.generateInvoices ? data.automaticCharge === true 
                                ? <Tooltip title="Automatic Payment"><EventOutlined/></Tooltip>
                                : <Tooltip title="Manual Payment"><TouchAppOutlined/></Tooltip>
                            : <Tooltip title="Canceled"><CancelRoundedIcon style={{ color: "red"}}/></Tooltip>
                    }
                    <Tooltip title={data.membershipTypeId === 'F' ? "Family" : data.membershipTypeId === 'C' ? "Couple" : "Individual"}>{
                        data.membershipTypeId === 'F' ? <FamilyIcon></FamilyIcon> : 
                        data.membershipTypeId === 'C' ? <CoupleIcon></CoupleIcon> : <IndividualIcon></IndividualIcon>
                    }
                    </Tooltip>
                    {
                        data.pendingCall ? <PhoneDisabled/> : null
                    }                    
                </TableCell>
            }
            if(column.id === 'isActive') {
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>{data.isActive === true ? "Active" : "Inactive"}</TableCell>
            }
            if(column.id === 'membershipId') {
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}><Typography style={{ textDecoration : data.isActive !== true ? 'line-through' : 'none' }}>{data.membershipId > 0 ? data.membershipId : ""}</Typography></TableCell>
            }
            if(column.id === 'paymentStatus') {
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>{data.paymentStatus}</TableCell>
            }
            
            if(column.id === 'startDate'){
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>{moment(data.startDate).format('MM/DD/YYYY')}</TableCell>
            }
            if(column.id === 'lastPaymentDate'){
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>{moment(data.lastPaymentDate).format('MM/DD/YYYY')}</TableCell>
            }
            if(column.id === 'nextPaymentDate'){
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>{moment(data.nextPaymentDate).format('MM/DD/YYYY')}</TableCell>
            }
            else if(column.id === 'generateInvoices') {
                return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>                           
                        
                        {
                            isElevatedPermissions ?
                            <Tooltip title="Download History">
                                <IconButton
                                    aria-label="member-generate-invoices"
                                    {...data.membershipId > 0 && {
                                        onClick: () => handleDownloadHistory(data.membershipId)
                                    }}
                                >
                                    <History/>
                                </IconButton> 
                            </Tooltip>:
                            null
                        }      
                        {/*
                            data.membershipId && data.paymentStatus !== 'Received' ?*/
                            <Tooltip title="Manual Payment">
                                <IconButton
                                    aria-label="member-manual-payment"
                                    {...data.membershipId > 0 && {
                                        onClick: () => handleManualPayment(data)
                                    }}
                                >
                                    <Payment/>
                                </IconButton> 
                            </Tooltip>/*:
                            null*/
                        }
                        {
                            data.generateInvoices ?
                            <Tooltip title="Generate Invoices">
                                <IconButton
                                    aria-label="member-generate-invoices"
                                    {...data.membershipId > 0 && {
                                        onClick: () => handleGenerateInvoices(data.applicationId)
                                    }}
                                >
                                    <LocalAtm/>
                                </IconButton> 
                            </Tooltip>:
                            null
                        }           
                        </TableCell>
            }
            return <TableCell align="left" key={column.id} style={{backgroundColor: COLOR}}>{data[column.id]}</TableCell>
        }
        return null;
    })
}

export default MemberRowTemplate;