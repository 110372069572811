import {useState, useEffect} from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Paper,
    makeStyles
} from '@material-ui/core';
import { lightGreen } from '@material-ui/core/colors';

const DEFAULT_INVOICES_HEADCELLS = [
    {id: 'manualPayment', label: 'Pay'},
    {id: 'courtesy', label: 'Courtesy'},
    {id: 'invoiceNumber', label: 'Invoice #'},
    // {id: 'invoiceDate', label: 'Invoice Date'},
    {id: 'invoicePeriod', label: 'Invoice Period'},
    {id: 'amount', label: 'Amount'},
    {id: 'status', label: 'Status'},
];

const useStyles = makeStyles({
    tableScrollBar: {
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '2px',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.3,
            webkitBorderRadius: '2px',
            borderRadius: '2px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        }
    },
    tableRow: {
        cursor: 'pointer'
    },
    selected: {
        cursor: 'pointer',
        backgroundColor: lightGreen
    }
});

const Invoices = props => {

    const {rows, handleInvoiceSelection, onRowClick} = props;

    const [selected, setSelected] = useState(null);

    const [updatable, setUpdatable] = useState();

    const [currentRow, setCurrentRow] = useState(null);

    const classes = useStyles();

    // useEffect(() => {
    //     rows.sort((a, b) => a.invoiceDate > b.invoiceDate ? 1 : -1)
        
    //     const firstUncollected = rows.find(i => i.status !== 'Collected');
    //     setUpdatable(firstUncollected);
    // }, [rows]);

    useEffect(() => {
        rows.sort((a, b) => a.fromDate > b.fromDate ? 1 : (a.fromDate < b.fromDate ? -1 : a.invoiceNumber > b.invoiceNumber ? 1 : -1));

        findFirstSelectableInvoice();
    });

    useEffect(() => {
        handleInvoiceSelection(selected);
        
        
    }, [selected]);

    const isItemSelected = (invoiceNumber, key) => {
        if(selected) {
            if(invoiceNumber in selected) {
                return selected[invoiceNumber][key];
            }    
        }
        return false;
    }

    const handleChecboxSelection = (key, value, ifDelete) => {
        setSelected(prevSelected => {

            return {
                ...prevSelected,
                [key]: value
            }
        });
        if(ifDelete) {
            setSelected(prevSelected => {                                         
                rows.forEach(r => {
                    if(r.invoiceNumber >= key){
                        delete prevSelected[r.invoiceNumber];
                    }                    
                })
                
                return prevSelected;
            });
        }
    }

    const findFirstSelectableInvoice = () => {
        debugger;
        const nextUpdatableIndex = rows.findIndex(r => !r.status.toLowerCase().includes('back') && r.status !== 'Collected' && r.status !== 'Courtesy' && r.status !== 'Replaced' && !r.status.toLowerCase().includes('refund') && (!selected || selected.length < 1 || !(r.invoiceNumber in selected)));
        setUpdatable(nextUpdatableIndex > -1 ? rows[nextUpdatableIndex] : updatable)
    }

    return <Paper>
        <TableContainer className={classes.tableScrollBar}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {
                            DEFAULT_INVOICES_HEADCELLS.map(headCell => {
                                return <TableCell
                                    key={headCell.id}
                                    align="center"
                                    padding="none"
                                >
                                    {headCell.label}
                                </TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows.map(row => {
                            return <TableRow key={row.invoiceNumber} onClick={() => {
                                    onRowClick(row);
                                    setCurrentRow(row.invoiceNumber)
                                }} className={(row.invoiceNumber === currentRow ? 'selected-row' : 'table-row') }>
                                <TableCell padding="checkbox" align="center">                                    
                                    <Checkbox 
                                        checked={isItemSelected(row.invoiceNumber, 'manualPayment')} 
                                        size="small"
                                        onChange={event => {
                                            handleChecboxSelection(row.invoiceNumber, {
                                                manualPayment: event.target.checked,
                                                courtesy: false,
                                                ...row
                                            }, !event.target.checked);

                                            findFirstSelectableInvoice();
                                        }}
                                        disabled={row.invoiceNumber !== updatable?.invoiceNumber && !isItemSelected(row.invoiceNumber, 'manualPayment')}
                                    />
                                </TableCell>
                                <TableCell padding="checkbox" align="center">
                                    <Checkbox 
                                        checked={isItemSelected(row.invoiceNumber, 'courtesy')} 
                                        size="small"
                                        onChange={event => {
                                            handleChecboxSelection(row.invoiceNumber, {
                                                courtesy: event.target.checked,
                                                manualPayment: false,
                                                ...row
                                            }, !event.target.checked);
                                        }}
                                        disabled={row.invoiceNumber !== updatable?.invoiceNumber && !isItemSelected(row.invoiceNumber, 'courtesy')}
                                    />
                                </TableCell>
                                <TableCell padding="none" align="center">{row.invoiceNumber}</TableCell>
                                {/* <TableCell align="center">{row.invoiceDate}</TableCell> */}
                                <TableCell padding="none" align="center">{`${row.fromDate} - ${row.toDate}`}</TableCell>
                                <TableCell padding="none" align="center">{row.amount.toFixed(2)}</TableCell>
                                <TableCell padding="none" align="center">{row.status}</TableCell>
                            </TableRow>
                        })
                    }

                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
}

export default Invoices;