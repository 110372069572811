import React, {useState, useEffect} from 'react';
import AutocompleteDropdown from "../../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import {getReports} from "../../../apiCalls/getReports";
import axios from 'axios';
import PropTypes from "prop-types";
import Notification from "../../../components/Notification/Notification";
import {connect} from 'react-redux';
import environment from "../../../config/environment";
import {downloadReport} from "../../../apiCalls/downloadReport";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

const ReportDropDown = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const {label, required, disabled, error, helperText, accountInfo} = props;
    const [value, setValue] = useState('');
    const [isDisable, setIsDisabled] = useState(disabled);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChange = (report) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        if(authToken && report && report !== '') {
            setLoading(true)
            downloadReport(source, authToken, {reportName: report})
            .then(response => {            
                var blob = new Blob([Buffer.from(response, 'base64')], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                var url = URL.createObjectURL(blob);
                window.open(url);
                setLoading(false);
                handleClose();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled plan type options fetching");
                } else {
                    console.log('Failed to fetch report', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch report, please try again"
                    });
                }
            });
        }        
    }

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        if(authToken) {
            setLoading(true)
            getReports(source, authToken)
            .then(response => {            
                setIsDisabled(isDisable || (response.length < 1));
                setOptions(response);
                setLoading(false);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled plan type options fetching");
                } else {
                    console.log('Failed to fetch report options', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch report options, please try again"
                    });
                }
            });

        }

        return () => source.cancel();
    }, [authToken]);

    return options && options.length > 0 ? <> <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
            >
                Daily Reports
            </Button>
            <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                {options.map(o => <MenuItem onClick={() => onChange(o.label)}>{o.label}</MenuItem>)}  
            </Menu>                  
            </>
            : null;                      
}

ReportDropDown.propTypes = {
    label: PropTypes.string.isRequired, 
    value: PropTypes.string.isRequired, 
    required: PropTypes.bool,
    disabled: PropTypes.bool, 
    error: PropTypes.bool, 
    helperText: PropTypes.string
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(ReportDropDown);

