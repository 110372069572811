import React, {useState, useEffect, useCallback} from 'react';
import {
    Grid,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles
} from '@material-ui/core';
import {
    AddCircleOutline,
    ExpandMore
} from '@material-ui/icons';
import Table from "../Table/Table";
import NextPrevDialog from "../NextPrevDialog/NextPrevDialog";
import PageHeader from "../PageHeader/PageHeader";
import DialogBox from "../DialogBox/DialogBox";
import NativeButton from "../FormInputs/Button/Button";
import {debounce} from 'lodash';
import Notification from "../../components/Notification/Notification";
import ExcelExporter from "../../components/ExcelExporter/ExcelExporter";

const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

const SimpleGrid = props => {
    const {title, headCells, rowTemplate, identifier, createTemplate, editTemplate, allowCreate,
            nextPreviousDialogHeight, filterTemplate, onApplyFilter, onClearFilter,
            filterCount, getData, rowsPerPage, refreshGrid, resetRefreshGrid,
            exportToExcel, excelData, additionalButtons} = props;
    
    const [data, setData] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);

    const [isTraverseOpen, setIsTraverseOpen] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [currentRecordId, setCurrentRecordId] = useState(-1);

    const [isFirstItem, setIsFirstItem] = useState(false);
    const [isLastItem, setIsLastItem] = useState(false);
    const [isCreateOpen, setIsCreateOpen] = useState(false);

    const [expanded, setExpanded] = useState(false);
    const [loading , setLoading] = useState(false);

    const [totalPages, setTotalPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const debounceSetPageNumber = useCallback(debounce(() => setPageNumber(0), 1000), [])

    const [createDialogTemplate, setCreateDialogTemplate] = useState(React.cloneElement(createTemplate,
        {
            ...createTemplate.props,
            cleanup: () => {
                setIsCreateOpen(false);
                debounceSetPageNumber();
            }
        }
    ));

    const [editDialogTemplate, setEditDialogTemplate] = useState(React.cloneElement(editTemplate,
        {
            ...editTemplate.props,
            cleanup: () => debounceSetPageNumber()
        }
    ));

    const [filterAccordianTemplate, setFilterAccordianTemplate] = useState(React.cloneElement(filterTemplate,
        {
            ...filterTemplate.props,
        }
    ));

    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});

    const classes = useStyles();

    useEffect(() => {
        if(refreshGrid) {
            debounceSetPageNumber();
            resetRefreshGrid(false);
        }
    }, [refreshGrid])

    useEffect(() => {
        if(pageNumber === 0) {
            setPageNumber(1);
        }
        fetchList();
    }, [pageNumber])

    useEffect(() => {
        setCreateDialogTemplate(React.cloneElement(createTemplate,
            {
                ...createTemplate.props,
                cleanup: () => {
                    setIsCreateOpen(false);
                    debounceSetPageNumber();
                }
            }
        ));
    }, [currentRecord, createTemplate]);

    useEffect(() => {
        setEditDialogTemplate(React.cloneElement(editTemplate,
            {
                ...editTemplate.props,
                cleanup: () => debounceSetPageNumber()
            }
        ));
    }, [currentRecord, editTemplate]);

    useEffect(() => {
        setFilterAccordianTemplate(React.cloneElement(filterTemplate,
            {
                ...filterTemplate.props
            }
        ))
    }, [filterTemplate]);

    const loadMoreRecords = () => {
        setLoading(true);
        if(pageNumber < totalPages) {
            setPageNumber(prevPageNumber => prevPageNumber+1)
        }
    }

    const fetchList = () => {
        if(pageNumber > 0) {
            setLoading(true);
            getData( (pageNumber-1) * rowsPerPage ).then(response => {
                if(pageNumber === 1) {
                    setData(response.data || []);
                    const absolutePages = Number(response.count)/rowsPerPage;
                    setTotalPages(absolutePages === Math.floor(absolutePages) ? absolutePages : Math.floor(absolutePages)+1);
                }
                else {
                    setData(prevData => {
                        return [
                            ...prevData,
                            ...(response.data || [])
                        ]
                    });
                }
                setDataChanged(true);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                setNotification({
                    open: true,
                    type: "error",
                    message: `Failed to fetch ${title} records, please try again`
                });
            });
        }
    }

    const setSelectionState = index => {
        if(data && data[index]) {
            setCurrentRecord({...data[index]});
            setCurrentRecordId(data[index][identifier]);
            setIsFirstItem(index === 0);
            setIsLastItem(index === data.length-1);
        }
    }

    const onDoubleClickRow = id => {
        let currentIndex = data.findIndex(item => item[identifier] === id);
        setSelectionState(currentIndex);
        setIsTraverseOpen(true);
    }

    const traverse = direction => {
        let currentIndex = data.findIndex(item => item[identifier] === currentRecordId);
        let newIndex = currentIndex+direction;
        currentIndex = newIndex === data.length || newIndex < 0 ? currentIndex : newIndex;
        setSelectionState(currentIndex);
    }

    let customButtons = [];

    if(allowCreate) {
        customButtons.push(
            <Button 
                variant="contained"
                color="primary"
                onClick={() => setIsCreateOpen(true)}
                size="large"
                style={{padding: '5px 15px'}}
                disabled={!allowCreate}
            >
                <AddCircleOutline style={{opacity: 0.5}} fontSize="small" />&nbsp;&nbsp;Add
            </Button>
        )
    }

    if(exportToExcel) {
        customButtons.push(<ExcelExporter fileName={title} sheetName={title} {...excelData} />);
    }

    if (additionalButtons?.length > 0) {
        customButtons = customButtons.concat(additionalButtons);
    }

    return <Grid container spacing={2} style={{width: '100%', padding: '20px'}}>
                <PageHeader 
                    pageTitle={title}
                    customButtons={customButtons}
                />
                <Grid item xs={12}>
                    <Accordion expanded={expanded} onChange={() => setExpanded(prevState => !prevState)}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel-content"
                            id="panel-header"
                        >
                            <Typography className={classes.heading}><strong>Filters</strong></Typography>
                            <Typography className={classes.secondaryHeading}>{`${filterCount} filter(s) applied`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {filterAccordianTemplate}
                                <Grid item xs={12}>
                                    <NativeButton
                                        onClick={() => {
                                            onApplyFilter();
                                            setExpanded(false);
                                        }}
                                        size="small"
                                        label="Apply Filters"
                                    />
                                    &nbsp;&nbsp;
                                    <NativeButton
                                        onClick={() => {
                                            onClearFilter();
                                            setExpanded(false);
                                        }}
                                        size="small"
                                        label="Clear Filters"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Table 
                        headCells={headCells}
                        rows={data}
                        page={pageNumber}
                        totalPages={totalPages}
                        loading={loading}
                        rowTemplate={rowTemplate}
                        loadMoreRecords={loadMoreRecords}
                        identifier={identifier}
                        handleSingleClickRow={() => console.log()}
                        handleDoubleClickRow={onDoubleClickRow}
                        tableHeight={70}
                        handleRowsOrderChange={newRows => setData(newRows)}
                        setRefreshTable={setDataChanged}
                        refreshTable={dataChanged}
                    />
                </Grid>
                <DialogBox 
                    openDialog={isCreateOpen}
                    onCloseBtnClicked={() => setIsCreateOpen(false)}
                    dialogTemplate={createDialogTemplate}
                />
                {
                    currentRecord && currentRecord[identifier] ?
                    <NextPrevDialog 
                        openDialog={isTraverseOpen}
                        onCloseBtnClicked={() => setIsTraverseOpen(false)}
                        onPrevBtnClicked={() => traverse(-1)}
                        onNextBtnClicked={() => traverse(1)}
                        isLastItem={isLastItem}
                        isFirstItem={isFirstItem}
                        dialogTemplate={editDialogTemplate}
                        data={currentRecord}
                        height={nextPreviousDialogHeight || 0}
                    /> :
                    null
                }
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </Grid>
}

export default SimpleGrid;