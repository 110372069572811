import {useState, useEffect} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    makeStyles,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import {ExpandMore, Help, MonetizationOnOutlined, Money, MoneyRounded, PhoneDisabled, PhoneEnabled} from '@material-ui/icons';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import TextField from "../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import AutocompleteDropdown from "../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import memberStatus from "../../constants/memberStatus";
import Button from "../../components/FormInputs/Button/Button";
import PropTypes from 'prop-types';
import MembershipTypeSmartDropDown from "../common/MembershipTypeSmartDropDown/MembershipTypeSmartDropDown";
import CenterSmartDropDown from "../common/CenterSmartDropDown/CenterSmartDropDown";
import AgentSmartDropDown from "../common/AgentSmartDropDown/AgentSmartDropDown";
import paymentStatus from "../../constants/paymentStatus";
import PlanTypeSmartDropDown from "../common/PlanTypeSmartDropDown/PlanTypeSmartDropDown";

const DEFAULT_FORM_VALUES = {
    membershipId: '',
    memberName: '',
    memberDOB: null,
    memberAddress: '',
    agentCode: '',
    primaryCenterId: '',
    isActive: '',
    paymentStatus: '',
    membershipTypeId: '',
    planTypeId: '',
    lastPaymentDate: null,
    nextPaymentDate: null,
    effectiveDate: null,
    stripeCustomerId:'',
    pendingCall: '',
    generateInvoices: ''
}

const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    callBtn: {
        paddingLeft: theme.spacing(1)
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
}));

const MemberFilters = (props) => {

    const [expanded, setExpanded] = useState(false);
    const [formValues, setFormValues] = useState({...DEFAULT_FORM_VALUES});
    const [filterCount, setFilterCount] = useState(0);

    const {onFilterChange} = props;
    const classes = useStyles();

    useEffect(() => {
        
    }, [formValues])

    const threeStateValue = (val) => {    
        if(val === true){
            return false
        }else if (val === false){
            return ''
        }else{
            return true
        }
    }

    const handleFormChange = (key, value) => {
        if(key === 'pendingCall'){
            value = threeStateValue(formValues.pendingCall)
        }
        if(key === 'generateInvoices'){
            value = threeStateValue(formValues.generateInvoices)
        }
        
        setFormValues(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }

    const handleApplyFilters = () => {
        let count = 0;
        for(const field in formValues) {
            if(formValues[field]) count = count+1;
        }
        setFilterCount(count);
        onFilterChange(formValues);
        setExpanded(false);
    }

    const handleClearFilters = () => {
        setFormValues({...DEFAULT_FORM_VALUES});
        setFilterCount(0);
        onFilterChange({...DEFAULT_FORM_VALUES});
        setExpanded(false);
    }

    return <Accordion expanded={expanded} onChange={() => setExpanded(prevState => !prevState)}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <Typography className={classes.heading}><strong>Filters</strong></Typography>
                    <Typography className={classes.secondaryHeading}>{`${filterCount} filter(s) applied`}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField 
                                label="Membership ID"
                                value={formValues.membershipId}
                                onChange={val => handleFormChange('membershipId', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField 
                                label="Membership Name"
                                value={formValues.memberName}
                                onChange={val => handleFormChange('memberName', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeField 
                                label="Member DOB"
                                fullWidth={true}
                                value={formValues.memberDOB}
                                onChange={date => handleFormChange('memberDOB', date)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField 
                                label="Member Address"
                                value={formValues.memberAddress}
                                onChange={val => handleFormChange('memberAddress', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteDropdown
                                label="Member Status"
                                options={memberStatus}
                                value={formValues.isActive}
                                loading={false}
                                onChange={val => handleFormChange('isActive', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <MembershipTypeSmartDropDown 
                                label="Membership Type"
                                value={formValues.membershipTypeId}
                                onChange={val => handleFormChange('membershipTypeId', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AgentSmartDropDown 
                                label="Agent"
                                value={formValues.agentCode}
                                onChange={val => handleFormChange('agentCode', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CenterSmartDropDown
                                label="Center"
                                value={formValues.primaryCenterId}
                                onChange={val => handleFormChange('primaryCenterId', val)}
                            />
                        </Grid>
                        
                        <Grid item xs={4}>
                            <PlanTypeSmartDropDown
                                label="Plan Type"
                                value={formValues.planTypeId}
                                onChange={val => handleFormChange('planTypeId', val)}
                            />
                        </Grid>
                        
                        <Grid item xs={4}>
                            <AutocompleteDropdown
                                label="Payment Status"
                                options={paymentStatus}
                                value={formValues.paymentStatus}
                                loading={false}
                                onChange={val => handleFormChange('paymentStatus', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeField 
                                label="Last Payment Date"
                                fullWidth={true}
                                value={formValues.lastPaymentDate}
                                onChange={date => handleFormChange('lastPaymentDate', date)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeField 
                                label="Next Payment Date"
                                fullWidth={true}
                                value={formValues.nextPaymentDate}
                                onChange={date => handleFormChange('nextPaymentDate', date)}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField 
                                label="Stripe Customer ID"
                                value={formValues.stripeCustomerId}
                                onChange={val => handleFormChange('stripeCustomerId', val)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DateTimeField 
                                label="Effective Date"
                                fullWidth={true}
                                value={formValues.effectiveDate}
                                onChange={date => handleFormChange('effectiveDate', date)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel color="primary" control={<Checkbox indeterminate={formValues.pendingCall === '' } checked={formValues.pendingCall === true ? true : false} icon={<PhoneEnabled />} checkedIcon={<PhoneDisabled />} indeterminateIcon={<Help/>} onChange={val => handleFormChange('pendingCall')} name="pendingCall" />}
                                label="Call Status"
                            />
                            <FormControlLabel color="primary" control={<Checkbox indeterminate={formValues.generateInvoices === '' } checked={formValues.generateInvoices === true ? true : false} icon={<CancelRoundedIcon style={{ color: "red"}}/>} checkedIcon={<MonetizationOnOutlined />} indeterminateIcon={<Help/>} onChange={val => handleFormChange('generateInvoices')} name="generateInvoices" />}
                                label="Generate Invoices"
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                onClick={handleApplyFilters}
                                size="small"
                                label="Apply Filters"
                            />
                            &nbsp;&nbsp;
                            <Button
                                onClick={handleClearFilters}
                                size="small"
                                label="Clear Filters"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
}

MemberFilters.propTypes = {
    onFilterChange: PropTypes.func.isRequired
}

export default MemberFilters;