import React, {useState, useEffect} from 'react';
import {Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {generateInvoices} from "../../../apiCalls/Application/generateInvoices";
import {connect} from 'react-redux';
import axios from 'axios';
import Notification from "../../../components/Notification/Notification";

const DEFAULT_FORM_VALUES = {
    fromDate: null,
    toDate: null
}

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const GenerateInvoices = props => {

    const {applicationId, accountInfo, toggleDialogBox} = props;

    const [formValues, setFormValues] = useState({...DEFAULT_FORM_VALUES});
    const [authToken, setAuthToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo]);
    
    const handleFormValueChange = (key, value) => {
        setFormValues(prevValues => {
            return {
                ...prevValues,
                [key]: value
            }
        })
    }

    const handleGenerateInvoicesClick = () => {
        if(authToken) {
            setLoading(true);
            generateInvoices(source, {
                applicationId,
                ...formValues
            }, authToken)
            .then(response => {
                if(response.status === 200) {                    
                    setNotification({
                        open: true,
                        type: "success",
                        message: "Successfully generated invoices."
                    });
                }
            })
            .catch(error => {
                setLoading(false);
                console.log('Failed to generate invoices ', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: "Failed to generate invoices, please try again"
                });
            })
        }
    }

    return <Grid container spacing={2} style={{padding: '20px'}}>
                <Grid item xs={12}>
                    <Typography variant="h6"><strong>{`Generate Invoices for ApplicationId ${applicationId}`}</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            label="From"
                            clearable
                            value={formValues.fromDate}
                            onChange={date => {
                                if(date) {
                                    handleFormValueChange('fromDate', moment(date).clone().startOf('month').format('YYYY-MM-DD'));
                                    handleFormValueChange('toDate', null);    
                                }
                                else handleFormValueChange('fromDate', null);
                            }}
                            required={false}
                            size='small'
                            views={['year', 'month']}
                            fullWidth
                            error={formValues.toDate && !formValues.fromDate}
                            helperText={formValues.toDate && !formValues.fromDate ? 
                                    "Required when a To date is entered" : undefined}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            label="To"
                            clearable
                            value={formValues.toDate}
                            onChange={date => {
                                if(date) {
                                    handleFormValueChange('toDate', moment(date).clone().endOf('month').format('YYYY-MM-DD'))
                                }
                                else handleFormValueChange('toDate', null);
                            }}
                            required={formValues.fromDate ? true : false}
                            size='small'
                            views={['year', 'month']}
                            fullWidth
                            minDate={moment(formValues.fromDate)/*.add(1, 'months')*/}
                            error={formValues.fromDate && !formValues.toDate}
                            helperText={formValues.fromDate && !formValues.toDate ? 
                                    "Required when a From date is entered" : undefined}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        size="small"
                        onClick={handleGenerateInvoicesClick}
                        disabled={ !formValues.fromDate || !formValues.toDate || loading }
                    >
                        Generate Invoices
                        {
                            loading ?
                            <CircularProgress
                                style={{color: 'white', width: 14, height: 14, marginRight: 5}}/>
                            :
                            null
                        }
                    </Button>
                </Grid>
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        if(notification.type === "success") {
                            toggleDialogBox(false);
                            setLoading(false);
                        }
                        setNotification({...DEFAULT_NOTIFICATION});                        
                    }}
                />
            </Grid>
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(GenerateInvoices)