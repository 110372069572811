import React, {useState, useEffect} from 'react'
import IconButton from '@material-ui/core/Button';
import {ReactComponent as StripeLogo} from '../assets/media/stripe.svg'
import { UrlUtils } from 'msal';
import environment from '../config/environment';

export default function StripeNavegation (props){
    const { url, stripeId, disabled } = props
    
    const navigateToStripe = () => {
        window.open(`https://dashboard.stripe.com/${url}/${stripeId}`)
    }

    return <IconButton
                aria-label="Go to Stripe"         
                href={`${environment.stripeUrl || 'https://dashboard.stripe.com/'}${url}/${stripeId}`}
                target="_blank"         
                size="small"
                disabled={!stripeId || stripeId === '' || disabled}   
            >
            <StripeLogo fontSize="inherit" disabled={disabled}/>
        </IconButton>
}

        