import moment from "moment";
import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";
import { saveAs } from 'file-saver';

export function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export const getHistoryReport = (source, authToken, membershipId) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}membership-history?membershipId=${membershipId}`,
            {
                cancelToken: source.token                
            })
            .then((response) => {
                const buffer = _base64ToArrayBuffer(response.data?.body);
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'//'application/vnd.ms-excel'
                });
                return blob
            })
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                // const _url = window.URL.createObjectURL(blob);
                // const newTab = window.open(_url, "_blank"); // window.open + focus

                const fileName = `history-${membershipId}-${moment().format('YYYMMDDHHmm')}`;
                const file = new File([blob], fileName, {
                    type: 'application/vnd.ms-excel' 
                })

                saveAs(blob, fileName)
            }).catch(error => reject(error))
    })
}
