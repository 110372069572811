import React from 'react';
import {TableCell, Typography} from '@material-ui/core';

const ApplicationRowTemplate = props => {
    const {data, columnOrder} = props;

    return columnOrder.map(column => {
        if(column.showColumn) {
            if(column.id === 'status') {
                return <TableCell align="left" key={column.id}><Typography>{data.membershipId > 0 ? (data.isActive ? "Processed" : "Inactive") : "Draft"}</Typography></TableCell>
            }
            
            return <TableCell align="left" key={column.id}><Typography>{(column.id === 'membershipId' && data[column.id] <= 0) ? '': data[column.id]}</Typography></TableCell>
        }
        return null;
    })
}

export default ApplicationRowTemplate;