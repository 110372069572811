import {
    Grid,
    Button
} from '@material-ui/core';
import {COLOR_PALETTE} from "../../config/colorPalette";
import {Link} from 'react-router-dom';

const ConfirmationPage = props => {
    const {data} = props;

    return <Grid container style={{padding: '20px'}}
            alignItems="center"
            direction="column"
        >
            <Grid item xs={12} style={{color: COLOR_PALETTE.orange.opacity100}}>
                <h2>Congratulations you have updated the Membership!</h2>
            </Grid>
            <Grid item xs={12}><hr/></Grid>
            <Grid item xs={12}>
                <h4>Membership Updated for Member ID is "{data.membershipId}"</h4>
            </Grid>
        </Grid>
}

export default ConfirmationPage;