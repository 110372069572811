import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const submitApplication = (source, application, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).post(`${environment().functionsURL}membership/modify`,
            {
                ...application
            },
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200 && response.data) {
                    resolve(response.data);
                }
                else reject(response);
            })
            .catch(error => reject(error));
    })
}