import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table, Typography} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {formatDate} from '../../utils/DateHandling'
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    root: {
        width: '100%',
      },
      container: {
        maxHeight: 440,
        minHeight: 440,
      },
      tableContainer: {
          '&::-webkit-scrollbar': {
              width: '4px',
              height: '4px'
          },
          '&::-webkit-scrollbar-track': {
              webkitBorderRadius: '2px',
              borderRadius: '2px'
          },
          '&::-webkit-scrollbar-thumb': {
              opacity: 0.3,
              webkitBorderRadius: '2px',
              borderRadius: '2px',
              background: '#00000042',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
          },
          height: '70vh',
          spacing: 0,
          justify: 'space-around',
      },
  });

function ListSkeleton(props) {
    const classes = useStyles();    

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><Skeleton animation="wave"/></TableCell>
                            <TableCell align="center"><Skeleton animation="wave"/></TableCell>
                            <TableCell align="center"><Skeleton animation="wave"/></TableCell>
                            <TableCell align="center"><Skeleton animation="wave"/></TableCell>
                            <TableCell align="center"><Skeleton animation="wave"/></TableCell>
                            <TableCell align="center"><Skeleton animation="wave"/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key='skl-1' >
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                        </TableRow>                        
                        <TableRow key='skl-2' >
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                        </TableRow>
                        <TableRow key='skl-3' >
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                        </TableRow>
                        <TableRow key='skl-5' >
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                        </TableRow>
                        <TableRow key='skl-6' >
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                        </TableRow>
                        <TableRow key='skl-7' >
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                        </TableRow>
                        <TableRow key='skl-8' >
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                            <TableCell align="center"><Typography variant="caption"><Skeleton animation="wave"/> </Typography></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default ListSkeleton;