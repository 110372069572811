import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const getAttemptDetails = (attemptNumber, source, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}attempt-details/${attemptNumber}`,
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data) {
                        resolve(response.data);
                    }
                    else {
                        resolve([]);
                    }
                }
                reject(response);
            })
            .catch(error => reject(error))
    })
}

