import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import {TableRow, Link} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {formatDate} from '../../utils/DateHandling'
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    root: {
        width: '100%',
    },    
    tableContainer: {
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '2px',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.3,
            webkitBorderRadius: '2px',
            borderRadius: '2px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        },
        height: '70vh',
        spacing: 0,
        justify: 'space-around',
    },
  });

function AttemptList(props) {
    const classes = useStyles();
    const {attempts, invoiceAttempts, currentInvoice, currentAttemptFromParent, onDoubleClick, onClick, onClearInvoice} = props;

    const [showSelector, setShowSelector] = useState(true);

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* {currentInvoice ? <TableCell></TableCell> : null} */}
                            {/* <TableCell align="center">Attempt. Nr.</TableCell> */}
                            <TableCell align="center">Payment Date</TableCell>
                            <TableCell align="center">Billed Amount</TableCell>
                            <TableCell align="center">Paid Amount</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {attempts?.map((attempt) => (
                        <TableRow key={attempt.attemptId} 
                            className={(attempt.attemptId === currentAttemptFromParent 
                                            ? 'selected-row' 
                                            : currentInvoice 
                                                ? invoiceAttempts?.find(ia => ia.attemptId === attempt.attemptId && ia.invoiceId === currentInvoice)
                                                    ?  'linked-row'
                                                    : 'table-row'
                                                : 'table-row') }
                            onDoubleClick={() => {                            
                                if(onDoubleClick) {
                                    onDoubleClick(attempt.attemptId);
                                }
                            }}
                            onClick={() => {                                
                                if(onClick){
                                    onClick(attempt.attemptId);
                                }
                                if(onClearInvoice){
                                    onClearInvoice();
                                }
                            }}>
                            {/* <TableCell align="center">{attempt.attemptId}</TableCell> */}
                            <TableCell align="center">{formatDate(attempt.scheduleDate)}</TableCell>
                            <TableCell align="center">{attempt.attemptedAmount.toFixed(2)}</TableCell>                            
                            <TableCell align="center">{attempt.paidAmount.toFixed(2)}</TableCell>
                            <TableCell align="center">
                                {
                                    attempt.stripeIntentId ? <strong><Link style={{ color: "purple" }} href={`https://dashboard.stripe.com/payments/${attempt.stripeIntentId}`} target={"_blank"}>{attempt.status}</Link> </strong> 
                                        : attempt?.status
                                            ? attempt?.status?.toLowerCase()
                                            : 'pending'
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default AttemptList;