import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@material-ui/styles';
import {theme} from './config/materialui';
import {AzureAD, AuthenticationState} from 'react-aad-msal';
import {authProvider} from "../src/config/authConfig";
import {
    Grid,
    CircularProgress,
    Button
} from '@material-ui/core';
import {COLOR_PALETTE} from "../src/config/colorPalette";
import thunk from 'redux-thunk';
import RootReducer from "./redux/reducers/RootReducer";
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import environment from "./config/environment";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPONSE || compose;
const store = createStore(RootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <AzureAD provider={authProvider} forceLogin={true}>        
                {
                    ({login, logout, authenticationState, error, accountInfo}) => {
                        console.log(accountInfo?.account?.idToken?.groups, authenticationState, accountInfo)
                        console.log(environment().backOfficeGroupId)
                        switch (authenticationState) {
                            case AuthenticationState.Authenticated:                                
                                if(accountInfo && accountInfo.account && accountInfo.account.idToken &&
                                accountInfo.account.idToken.groups && accountInfo.account.idToken.groups.length) {
                                    if(accountInfo.account.idToken.groups.includes(environment().backOfficeGroupId)) {
                                        return <App accountInfo={{
                                            ...accountInfo,
                                            provider: authProvider
                                        }} login={login} logout={logout} provider={authProvider}/>
                                    }
                                }
                                return <Grid container alignItems="center" direction="column" style={{padding: '20px'}}>
                                        <Grid item xs={12} style={{color: COLOR_PALETTE.orange.opacity100}}>
                                            <h2>Only backoffice staff are allowed to access this app!</h2>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="secondary" onClick={logout}>Login Using Different Account</Button>                                      
                                        </Grid>
                                    </Grid>

                            case AuthenticationState.Unauthenticated:
                                return <Grid container alignItems="center" direction="column" style={{padding: '20px'}}>
                                        <Grid item xs={12} style={{color: COLOR_PALETTE.orange.opacity100}}>
                                            <h2>We couldn't find your account info, please try logging in again!</h2>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="secondary" onClick={login}>Login</Button>                                      
                                        </Grid>
                                    </Grid>

                            case AuthenticationState.InProgress:
                                return <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}} />
                            
                            default:
                                return null;
                        }
                    }
                }
            </AzureAD>
        </ThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
