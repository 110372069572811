import React from 'react';
import {
    makeStyles,
    Stepper,
    Step,
    StepLabel
} from '@material-ui/core';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center'
    },
    stepLabel:{
        cursor: "pointer",
        '&$disabled': {
            cursor: 'pointer'
        }
    }
}));

const NativeStepper = (props) => {
    const classes = useStyles();
    const {steps, activeStep, onStepClick} = props;

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} style={{backgroundColor:"transparent"}}>
                {
                    steps.map((step, index) => {
                        return (
                            <Step key={step.label} onClick={() => onStepClick(index)}>
                                <StepLabel 
                                    classes={{label: classes.stepLabel}}
                                    StepIconProps={{
                                        completed: false
                                    }}
                                >
                                    {step.label}
                                </StepLabel>
                            </Step>
                        );
                    })
                }
            </Stepper>
            <div style={{minHeight: 100}}>
                {
                    props.children
                }
            </div>
        </div>
    );
};

NativeStepper.propTypes = {
    steps: PropTypes.array.isRequired,
    activeStep: PropTypes.number.isRequired,
    onStepClick: PropTypes.func
};

export default NativeStepper;
