import React, {WrappedComponent} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import { LocationCity, MoneyOff } from '@material-ui/icons';
import PopOverContent from './PopOverContent'
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(0),
      size: 15,
      paddingTop: 5,
      justifyContent: "flex-end"
    },
    marginRed: {
      margin: theme.spacing(0),
      size: 15,
      paddingTop: 5,
      justifyContent: "flex-end",
      color: "red"
    },
    extendedIcon: {
      marginRight: theme.spacing(0),
    },
    typography: {
      padding: theme.spacing(1),
    },
    wrapper: {
      width: "auto",
      padding: 0,
      margin:0,
    },
  }));

const AmountPopOver = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const {data} = props;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return <div className={classes.wrapper}>
      {props.data.isCorporate ? props.data?.invoiceAmount.toFixed(2) : props.data?.attemptedAmount.toFixed(2)}
        {data.refundedAmount > 0 || data.chargeBackAmount > 0 
            ? <MoneyOff fontSize="small" className={classes.margin} color="error" onClick={handleClick}/>
            : props.data.isCorporate
                ? <LocationCity fontSize="small" color={data.refundedAmount > 0 || data.chargeBackAmount > 0 ? "error" : "secondary"} className={classes.margin} onClick={handleClick}/> 
                : null}
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
            transformOrigin={{vertical: 'top', horizontal: 'center',}}>
            <PopOverContent data={data}></PopOverContent>
        </Popover>
      </div>
  }

  export default AmountPopOver