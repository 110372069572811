import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Checkbox, FormControlLabel, Button, CircularProgress} from '@material-ui/core';
import axios from 'axios';
import TextField from "../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getInvoiceDetails } from '../../apiCalls/Invoices/getInvoiceDetails';
import {StripeTextBox, STRIPE_CUSTOMER} from '../../components/Stripe/StripeTextBox'
import { NumericSearchTextBox } from '../../components/Search/NumericSearchTextBox';
import Payment from './Payment'
import { saveInvoiceDetails } from '../../apiCalls/Invoices/saveInvoiceDetrails';
import { formatDate } from '../../utils/DateHandling';
import Alert from '@material-ui/lab/Alert';
import {deleteInvoice} from '../../apiCalls/Invoices/deleteInvoice'
import {deletePayment} from '../../apiCalls/Invoices/deletePayment'
import DeleteButton from '../../components/Buttons/DeleteButton';
import Notification from "../../components/Notification/Notification";
import environment from '../../config/environment';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0),
        size: 20,
    },   
    right: {
        marginLeft: 'auto',
        fullWidth: false,
        width: 20
    },
    extendedIcon: {
        marginRight: theme.spacing(0),
    },
    typography: {
        padding: theme.spacing(1),
    },
    invoiceNumber: {
        paddingTop: theme.spacing(1),
    },
  }));


  const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

const INITIAL_STATE = {
    invoiceDate: undefined,
    fromDate: undefined,
    toDate: undefined,

    invoiceAmount: undefined,
    isActive: false,
    updateStripeInfo: false,

    stripePaymentMethodId: '',
    stripeCustomerId: '',

    invoiceId: '',
    
    payment: {
        paymentId: '',
        paymentAmount: '',
        paymentTimestamp: undefined,
        confirmation: '',
        applicationId: '',
        membershipId: '',                        
        refundedAmount: '',
        refundedOn: undefined,
        chargeBackOn: undefined,
        chargeBackAmount: ''
    },

    comment: ''
}
  
function InvoiceEditor(props) {
    const {invoice, authToken, onSave} = props;
    const classes = useStyles(props);

    const [invoiceId, setInvoiceId] = useState(invoice)
    const [data, setData] = useState(INITIAL_STATE);

    const [hasNotChanged, setHasNotChanged] = useState(true)
    const [enabled, setEnabled] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [childErrorState, setChildErrorState] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState({});
    const [allowSearch, setAllowSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const { invNr } = useParams();
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});    
    const [expandedAccordion, setExpandedAccordion] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);
    
    useEffect(() => {
        setInvoiceId(invNr);
    }, [invNr])

    useEffect(() => {
        if(invoice){
            setInvoiceId(invoice)
            loadInvoiceInformation(invoice)
            setAllowSearch(false)
        }        
    }, [])

    useEffect(() => {
        if((data?.invoiceAmount || -1) < 0 || !(data?.invoiceDate) || (!data?.fromDate) || (!data?.toDate)){
            setErrorState(true);            
            return;
        }
            
        setErrorState(false);
    }, [data])

    const prepareInvoiceDataForViewing = (response) => {        
        const invDetails = {
            invoiceDate: formatDate(response?.invoiceDate),
            fromDate: formatDate(response?.fromDate),
            toDate: formatDate(response?.toDate),

            invoiceAmount: response?.invoiceAmount,
            isActive: response?.isActive,
            updateStripeInfo: false,
            replacedByInvoice: response?.replacedByInvoice || '',
            creditAmount: response?.creditAmount || '',

            payment: {
                paymentId: response?.payment?.paymentId,
                paymentAmount: response?.payment?.paymentAmount,
                paymentTimestamp: formatDate(response?.payment?.paymentTimestamp),
                confirmation: response?.payment?.confirmation,                        
                refundedAmount: response?.payment?.refundedAmount,
                refundedOn: formatDate(response?.payment?.refundedOn),
                chargeBackOn: formatDate(response?.payment?.chargeBackOn),
                chargeBackAmount: response?.payment?.chargeBackAmount,
                chargeBackFee: response?.payment?.chargeBackFee,
            },

            stripePaymentMethodId: response?.stripePaymentMethodId || '',
            stripeCustomerId: response?.stripeCustomerId || '',

            invoiceId: response?.invoiceId,
            comment: response?.comment,
            updateStripeInfo: false
        }

        setData(invDetails);
        setHasNotChanged(true);
        setEnabled(invDetails?.invoiceId > 0)
        setErrorState(false)
    }

    const loadInvoiceInformation = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if(authToken && invoiceId) {
            setLoading(true)
    
            getInvoiceDetails(invoiceId, source, authToken)
            .then(response => {        
                prepareInvoiceDataForViewing(response.data)
                setInvoiceId(response.data?.invoiceId)
                setLoading(false);
                setAllowSearch(false);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to fetch Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch Invoice details, please try again"
                    });
                }
            });
        }

        return () => source.cancel();
    }

    const handleFormDataChange = (element, val) => {
        if(element === 'updateStripeInfo'){
            val = !data.updateStripeInfo;
        }else{            
            setHasNotChanged(false)
        }

        if(element === 'isActive'){
            val = !data.isActive;
        }

        setData({...data, [element]: val});
    }

    const handleDeleteInvoice = (invId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if(authToken && invoiceId) {
            setLoading(true)
    
            deleteInvoice(invoiceId, source, authToken)
            .then(response => {        
                setInvoiceId('')
                setLoading(false);
                setEnabled(false);
                setData(INITIAL_STATE);
                setAllowSearch(true);        
                onSave();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to fetch Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch Invoice details, please try again"
                    });
                }
            });
        }

        return () => source.cancel();
    }

    const handleDeletePayment = (paymentId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if(authToken && paymentId) {
            setLoading(true)
    
            deletePayment(paymentId, source, authToken)
            .then(response => {        
                setInvoiceId('')
                setLoading(false);
                setEnabled(false);
                setData(INITIAL_STATE);
                setAllowSearch(true);       
                onSave();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to fetch Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch Invoice details, please try again"
                    });
                }
            });
        }

        return () => source.cancel();
    }

    const handleSave = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)

        if(authToken && data){
            saveInvoiceDetails(data, source, authToken)
            .then(response => {                
                setInvoiceId('')
                setLoading(false);
                setEnabled(false);
                setData(INITIAL_STATE);
                setAllowSearch(true);       
                onSave();
            })
            .catch(error => {
                const msg = error.response?.data?.error?.message;
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to update Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: msg ? msg : "Failed to update Invoice details, please try again"
                    });
                }
            });
        }
    }
    
    return <Grid container spacing={2}>            
    <Grid item xs={12} >{errorState}</Grid>
            <Grid item xs={5} >    
                <div style={{paddingTop: '8px'}}>
                    <NumericSearchTextBox label="Invoice Number"
                        value={invoiceId}
                        onChange={setInvoiceId}
                        required={true}
                        onSearchClick={loadInvoiceInformation}
                        disabled={!allowSearch}>
                    </NumericSearchTextBox>           
                </div>
            </Grid>
            <Grid item xs={5} >
                <div style={{paddingTop: '8px'}}>
                    <TextField 
                        label="Replaced By Invoice"
                        value={data?.replacedByInvoice}
                        onChange={val => handleFormDataChange('replacedByInvoice', val)}
                        isNumericOnly
                        required={false}
                        disabled={!enabled || loading}
                    />        
                </div>
            </Grid>
            <Grid item xs={1}>
                <DeleteButton   disabled={!enabled} 
                                onConfirmAction={() => handleDeleteInvoice(data?.invoiceId)} 
                                confirmationHeader={'Warning!'}>
                    <Alert variant="outlined" severity="warning">
                        Deleting an invoice will remove invoice, attempt and payment information. You will have to recreate the invoice and manually link the financial information. Are you sure you want to delete this invoice?
                    </Alert>
                </DeleteButton>
            </Grid>
            <Grid item xs={5}>
                <StripeTextBox  label="Stripe Customer Id" 
                                stripeId={data?.stripeCustomerId}
                                value={data?.stripeCustomerId}
                                type={STRIPE_CUSTOMER}
                                baseUrl={environment().stripeUrl}
                                onChange={val => handleFormDataChange('stripeCustomerId', val)}
                                disabled={!enabled || loading || data?.updateStripeInfo === false}
                                />
            </Grid>
            <Grid item xs={7}>
                <StripeTextBox  label="Stripe Payment Method Id" 
                                value={data?.stripePaymentMethodId}
                                stripeId={data?.stripeCustomerId}
                                type={STRIPE_CUSTOMER}
                                onChange={val => handleFormDataChange('stripePaymentMethodId', val)}
                                disabled={!enabled || loading || data?.updateStripeInfo === false}/>
            </Grid>
            <Grid item xs={5}>
                <FormControlLabel control={<Checkbox disabled={!enabled || loading} onChange={val => handleFormDataChange('updateStripeInfo', val)} checked={data?.updateStripeInfo === true}/>} label="Update Member's Stripe Info." />                
            </Grid>
            <Grid item xs={7}>
                <FormControlLabel control={<Checkbox disabled={!enabled || loading} onChange={val => handleFormDataChange('isActive', val)} checked={data?.isActive === true}/>} label="Collect Automatically" />                
            </Grid>
            <Grid item xs={5}>
                <DateTimeField
                    label="Invoice Date"
                    fullWidth={true}
                    value={data?.invoiceDate}
                    onChange={date => handleFormDataChange('invoiceDate', date)}
                    disablePast={false}
                    required={true}
                    disabled={!enabled || loading }
                />
            </Grid>
            <Grid item xs={7}>
                <TextField 
                    label="Invoice Amount"
                    value={data?.invoiceAmount}
                    onChange={val => handleFormDataChange('invoiceAmount', val)}
                    isNumericOnly
                    required={true}
                    disabled={!enabled || loading}
                />
            </Grid>
            <Grid item xs={5}>
                <DateTimeField
                    label="From Date"
                    fullWidth={true}
                    value={data?.fromDate}
                    onChange={date => handleFormDataChange('fromDate', date)}
                    disablePast={false}
                    required={true}
                    disabled={!enabled || loading}
                />
            </Grid>
            <Grid item xs={7}>
                <DateTimeField
                    label="To Date"
                    fullWidth={true}
                    value={data?.toDate}
                    onChange={date => handleFormDataChange('toDate', date)}
                    disablePast={false}
                    required={true}
                    disabled={!enabled || loading}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField 
                    label="Applied Balance"
                    value={data?.creditAmount}
                    onChange={val => handleFormDataChange('creditAmount', val)}
                    isNumericOnly
                    required={true}
                    disabled={!enabled || loading}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="filled-multiline-static"
                    label="Notes"
                    multiline
                    rows={4}
                    value={data?.comment || ''}
                    variant="outlined"
                    onChange={val => handleFormDataChange('comment', val)}
                    disabled={!enabled || loading}
                />
            </Grid>
            {data?.payment?.paymentId ?
                <Grid item xs={12}>
                    <Payment payment={data?.payment} 
                            onChange={val => {
                                handleFormDataChange('payment', val);
                            }}
                            expanded={expandedAccordion}
                            onAccordionChage={(event, state) => setExpandedAccordion(state || deleteClicked)}
                            onDeleteClickAction={() => {
                                setDeleteClicked(true)
                                setExpandedAccordion(true)
                            }}
                            onConfirmAction={() => handleDeletePayment(data?.payment?.paymentId)}
                            onCancelAction={() => {
                                setDeleteClicked(false)                        
                            }}
                            onValidation={(state) => {
                                setChildErrorState(state);
                            }}
                            enabled={enabled} 
                            loading={loading}>                    
                    </Payment>
                </Grid>
             : null
            }
            <Grid item xs={12}>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    size="small"
                    onClick={() => handleSave()}
                    disabled={!enabled || errorState || loading || childErrorState || hasNotChanged}
                >
                    Update Invoice
                    {
                        loading ?
                        <CircularProgress style={{color: 'white', width: 14, height: 14, marginRight: 5}}/> :
                        null
                    }
                </Button>
            </Grid>
            <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        if(notification.type === "success") {
                            setLoading(false);
                        }
                        setNotification({...DEFAULT_NOTIFICATION});                        
                    }}
                />
        </Grid>
}

export default InvoiceEditor;