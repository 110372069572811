import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { getPayments } from '../../apiCalls/Invoices/getPayments';
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import PaymentDisplay from './PaymentDisplay';
import PaymentDropDown from './PaymentDropDown';
import {swapPayment} from '../../apiCalls/Invoices/swapPayment'

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
}

const INITIAL_STATE = {
    invoiceId: undefined,
    currentPayment: undefined,
    newPayment: undefined,
    payments: []
}

export default function RelinkInvoice(props){
    const {applicationId, invoiceId, authToken, onSaved} = props;
    const [loading, setLoading] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});  
    const [data, setData] = useState(INITIAL_STATE);

    const preparePaymentDataForViewing = (response) => {
        setData({
            invoiceId: invoiceId,
            currentPayment: response?.find(p => invoiceId === p.invoiceId),
            newPayment: undefined,
            payments: response
        })
    }

    const handleFormDataChange = (element, val) => {
        setData({...data, [element]: val});
    }

    const callSwapPayment = () => {
        if(authToken && invoiceId){
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();        
            setLoading(true);
            
            swapPayment({
                invoice: invoiceId,
                payment: data?.newPayment?.paymentId
            }, source, authToken)
            .then(response => {                        
                setLoading(false);
                setNotification({
                    open: true,
                    type: "success",
                    message: "Successfully swapped the invoices"
                });
                
                if(onSaved) {
                    onSaved();
                }
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Payee fetching");
                } else {
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: error.message ? error.message : "Failed to fetch Payee details, please try again"
                    });
                }
            });
        }        
    }

    useEffect(() => {
        if(authToken && invoiceId){
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();        
            setLoading(true);

            getPayments(applicationId, source, authToken)
            .then(response => {        
                preparePaymentDataForViewing(response)                
                setLoading(false);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Payee fetching");
                } else {
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: error.message ? error.message : "Failed to fetch Payee details, please try again"
                    });
                }
            });
        }
    }, [applicationId])

    return (
        <div style={{padding: '10px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PaymentDropDown 
                        disabled={loading}
                        onPaymentSelected={(newPayment) => {
                            handleFormDataChange('newPayment', newPayment);
                            setHasChanged(true);
                        }} 
                        invoiceId={invoiceId} 
                        data={data?.payments}></PaymentDropDown>
                </Grid>
                <Grid item xs={6}>
                    <PaymentDisplay label="Current Invoice and Payment Info." data={data?.currentPayment} ></PaymentDisplay>
                </Grid>
                <Grid item xs={6}>
                    <PaymentDisplay  label="Selected Invoice and Payment Info." data={data?.newPayment}></PaymentDisplay>
                </Grid>
                <Grid item xs={12}>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        size="small"
                        onClick={() => {
                            callSwapPayment()
                        }}
                        disabled={loading || !hasChanged}
                    >
                        Apply Changes
                        {
                            loading ?
                            <CircularProgress style={{color: 'white', width: 14, height: 14, marginRight: 5}}/> :
                            null
                        }
                    </Button>
                </Grid>
            </Grid>                     
            <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        if(notification.type === "success") {
                            setLoading(false);
                        }
                        setNotification({...DEFAULT_NOTIFICATION});                        
                    }}
                />
        </div>
    )
}