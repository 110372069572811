import {Grid, Typography,
    Link} from '@material-ui/core';
import Invoices from "./Invoices";
import Attempts from "./Attempts";
import { useEffect } from 'react';
import {formatDate} from '../../../utils/DateHandling';

const InvoicesAndAttempts = props => {

    const {handleInvoiceSelection, invoicesRecords, attemptsRecords, memberDetails, onInvoiceRowClick} = props;    

    return <Grid container>
        <Grid item xs={12}>
            <Typography variant="h6"><strong>Billing Information</strong></Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="subtitle2">
                <strong>Name: </strong>&nbsp;
                {memberDetails.name}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="subtitle2">
                <strong>DOB: </strong>&nbsp;
                {formatDate(memberDetails.dob)}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="subtitle2">
                <strong>Last Payment: </strong>&nbsp;
                {memberDetails.lastPayment}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="subtitle2">
                <strong>Plan: </strong>&nbsp;
                {memberDetails.plan}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="subtitle2">
                <strong>Next Payment: </strong>&nbsp;
                {memberDetails.nextPayment}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="subtitle2">
                <strong>Payment Due Date: </strong>&nbsp;
                {memberDetails.dueDate}
            </Typography>
        </Grid>
        <Grid item xs={6}>
            <Typography variant="subtitle2">
                <strong>Type of Member: </strong>&nbsp;
                {memberDetails.memberType}
            </Typography>
        </Grid>
        <Grid item xs={6}>
                    {memberDetails.stripeCustomerId && memberDetails.stripeCustomerId.length > 0 ? 
                        <strong><Link style={{ color: "purple" }} href={`https://dashboard.stripe.com/customers/${memberDetails.stripeCustomerId}`} target={"_blank"}>Stripe</Link> </strong>:
                        <Typography>{''}</Typography>}
                </Grid>
        <hr/>
        <Grid item xs={12}>
            <Typography variant="subtitle2"><strong>Invoices</strong></Typography>
        </Grid>
        <Grid item xs={12}>
            <Invoices
                rows={invoicesRecords}
                handleInvoiceSelection={handleInvoiceSelection}
                onRowClick={onInvoiceRowClick}
            />
        </Grid>
        <hr/><hr/>
        <Grid item xs={12}>
            <Typography variant="subtitle2"><strong>Attempts {
                attemptsRecords.length ?
                ` for Invoice# ${attemptsRecords[0]?.invoiceNumber}` :
                null
            }</strong></Typography>
        </Grid>
        <Grid item xs={12}>
            <Attempts
                rows={attemptsRecords}
            />
        </Grid>
    </Grid>
}

export default InvoicesAndAttempts;