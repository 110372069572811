import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const deletePayment = (paymentId, source, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).delete(`${environment().functionsURL}payment-history/${paymentId}`,            
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200 && response.data) {
                    resolve(response.data);
                }
                else reject(response);
            })
            .catch(error => reject(error));
    })
}