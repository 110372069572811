import moment from 'moment';

export const MUI_DATE_TIME_PICKER_FORMAT = 'yyyy-MM-DDThh:mm';
export const invoiceStatusFromAttempt = (attempts) => {
    if(!Array.isArray(attempts) || attempts.length < 1){
        return 'Pending';
    }

    const payment = attempts?.find(item => !!item?.attemptWithsStatus?.payment)
    if(payment){
        return 'Collected';
    }

    const courtesy = attempts?.find(item => item?.isCourtesy === true)
    if(courtesy){
        return 'Courtesy';
    }

    let attempt = attempts[0];
    /*const lastAttempt =*/ attempts.forEach(a => {
        if(attempt.attemptId !== a.attemptId){
            if(attempt.attemptedOn < a.attemptedOn){
                attempt = a;
            }
        }
    })

    return attempt?.attemptWithsStatus?.attemptedOn ? attempt?.attemptWithsStatus?.status : `Pending for ${moment(attempt?.attemptWithsStatus?.scheduleDate).format('YYYY-MM-DD')}`;
}

export const isLimited = (accountInfo) => {
    if(isElevatedPermissions(accountInfo)){
        return false;
    }

    const groups = accountInfo?.account?.idToken?.groups;
    if(groups){
        return (groups.includes('30d62d17-d741-4f6b-8239-95dc879bcd83') || groups.includes('01b25fd5-5dbc-45d1-a9eb-a1ad043e30b9'))
    }

    return true;
}

export const isElevatedPermissions = (accountInfo) => {    
    const groups = accountInfo?.account?.idToken?.groups;    
    if(groups){
        return (groups.includes('a7244e88-0ece-4efd-ab25-5244f93e544c') || groups.includes('796cf834-ed16-4e33-8c13-cfc324523e87'))
    }
    return false;
}