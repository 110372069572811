import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const generateInvoices = (source, invoiceGeneration, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).patch(`${environment().functionsURL}invoice`,
            {
                payload: {...invoiceGeneration}
            },
            {
                cancelToken: source.token                
            })
            .then(response => {
                resolve(response);
            }).catch(error => reject(error))
    });
}