import React, {useState, Fragment, useEffect} from 'react';
import {
    makeStyles,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    IconButton
} from '@material-ui/core';

import Tooltip from '@material-ui/core/Tooltip';
import {ExpandMore, DeleteForever, VisibilityOff, Visibility, CheckCircleOutline, AddCircleOutline, RemoveCircleOutline} from '@material-ui/icons';
import Button from "../../../components/FormInputs/Button/Button";
import UpsertDependentForm from "./UpsertDependentForm";
import DialogBox from "../../../components/DialogBox/DialogBox";
import moment from 'moment';
import PropTypes from 'prop-types';
import UpgradeMemberButton from '../UpgradeMember/UpgradeMemberButton';

const TABLE_HEADER = ['First Name', 'Last Name', 'DOB', 'Relationship', 'Gender', 'Phone', 'Effective Date', 'Deactivated On', 'Actions']

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    marginLeft: '-30px'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tableRow: {
      '&:hover': {
          cursor: 'pointer'
      }
  },
  disableRow: {
    '&:hover': {
        cursor: 'pointer'
    },
    textDecoration: 'line-through'
  },
  principalRow: {
    '&:hover': {
        cursor: 'pointer'
    },
    fontWeight: 'bold'
  },
  iconButton: {
      fontSize: theme.typography.pxToRem(24),
      padding: '2px'
  }
}));

const ManageDependents = props => {

    const {dependents, onChange, isReadOnly, setPrimaryData, allowRevenueImpactingChanges} = props;

    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [showUpsertDependentDialog, setShowUpsertDependentDialog] = useState(false);
    const [updateDependentFormValues, setUpdateDependentFormValues] = useState(null);
    const [updateDependentIndex, setUpdateDependentIndex] = useState(-1);
    const [isUpdate, setIsUpdate] = useState(undefined);

    const populateUpdateForm = (index, dependentObject) => {
        setUpdateDependentFormValues(dependentObject);
        setShowUpsertDependentDialog(true);
        setUpdateDependentIndex(index);
    }

    const upsertDependent = (newDependent, isUpdateMode) => {
        if(isUpdateMode) {
            onChange([
                ...dependents.slice(0, updateDependentIndex),
                newDependent,
                ...dependents.slice(updateDependentIndex+1)
            ]);
        }
        else {
            onChange([
                ...dependents,
                {...newDependent}
            ]);
        }

        if(newDependent.relationship.relationshipTypeId === 'P'){
            setPrimaryData(newDependent);
        }

        closeAndReset();
    }

    const makePrimary = (dependentIndex, oldPrimaryRelationshipType) => {
        const newPrimary = dependents[dependentIndex];
        newPrimary.relationship = {
            membershipPriority: 1,
            relationshipTypeDesc: "Primary Member",
            relationshipTypeId: "P",
            membershipType: "I"
        }

        setPrimaryData(newPrimary);

        const oldPrimary = dependents[0];
        oldPrimary.relationship = oldPrimaryRelationshipType;

        const newArray = [];
        newArray.push(newPrimary);
        newArray.push(oldPrimary);

        dependents
            .filter(d => d.memberId !== newPrimary.memberId && d.memberId !== oldPrimary.memberId)
            .forEach(element => {
                newArray.push(element); 
            });        

        onChange(newArray);
    }

    const closeAndReset = () => {
        setShowUpsertDependentDialog(false);
        setUpdateDependentFormValues(null);
        setUpdateDependentIndex(-1);
    }

    return <Fragment>
                <DialogBox 
                    openDialog={showUpsertDependentDialog}
                    onCloseBtnClicked={closeAndReset}
                    dialogTemplate={<UpsertDependentForm />}
                    data={{
                        upsertDependent: upsertDependent,
                        dependent: updateDependentFormValues,
                        updatePrimary: makePrimary,
                        allowRevenueImpactingChanges:allowRevenueImpactingChanges
                    }}                    
                />
                <Accordion expanded={expanded} onChange={() => setExpanded(prevState => !prevState)}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel-content"
                        id="panel-header"
                    >
                        <Typography className={classes.heading}><strong>Dependent Information</strong></Typography>
                        <Typography className={classes.secondaryHeading}>{`${dependents.length} Member(s)`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {isReadOnly ? null : 
                            <Grid item xs={1}>
                                <Button 
                                    type="add"
                                    onClick={() => setShowUpsertDependentDialog(true)}
                                    size="small"
                                    label="Add"
                                    disabled={(allowRevenueImpactingChanges !== true)}
                                />
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                        <TableRow>
                                        {
                                            TABLE_HEADER.map(header => <TableCell key={header}><strong>{header}</strong></TableCell>)
                                        }
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {
                                            dependents.map((dependent, dependentIndex) => {
                                                return <TableRow 
                                                            key={dependentIndex}
                                                            className={dependent.isActive ? classes.tableRow : classes.disableRow}
                                                            onDoubleClick={() => populateUpdateForm(dependentIndex, dependent)}
                                                        >
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow} component="th" scope="row">{dependent.firstName}</TableCell>
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow}>{dependent.lastName}</TableCell>
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow}>{moment(dependent.dateOfBirth).format('MM/DD/YYYY')}</TableCell>
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow}>{dependent.relationship?.relationshipTypeDesc}</TableCell>
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow}>{dependent.gender}</TableCell>
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow}>{dependent.phone}</TableCell>
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow}>{dependent.effectiveDate}</TableCell>
                                                            <TableCell className={dependent.relationship?.relationshipTypeId === 'P' ? classes.principalRow : classes.tableRow}>{!dependent.isActive ? dependent.deactivationDate : null}</TableCell>
                                                            <TableCell >
                                                                {(isReadOnly || dependent.relationship?.relationshipTypeId === 'P') ? 
                                                                null : <>
                                                                        {allowRevenueImpactingChanges === true ? <Tooltip title={dependent.isActive ? "Terminate" : "Activate"}>
                                                                            <IconButton className={classes.iconButton}
                                                                                onClick={() => {
                                                                                    if(dependent.effectiveDate){
                                                                                        dependent.effectiveDate = dependent.isActive === false ? moment().format('MM/DD/YYYY') : dependent.effectiveDate;
                                                                                        dependent.deactivationDate = dependent.isActive === true ? moment().format('MM/DD/YYYY') : dependent.deactivationDate;
                                                                                        dependent.isActive = !dependent.isActive; 
                                                                                        onChange([
                                                                                            ...dependents.slice(0, dependentIndex),
                                                                                            dependent,
                                                                                            ...dependents.slice(dependentIndex+1)
                                                                                        ]);
                                                                                    } else {
                                                                                        onChange([
                                                                                            ...dependents.slice(0, dependentIndex),
                                                                                            ...dependents.slice(dependentIndex+1)
                                                                                        ]);
                                                                                    }
                                                                                }}
                                                                            >{dependent.isActive ? <RemoveCircleOutline className={classes.iconButton}/> : <AddCircleOutline className={classes.iconButton}/>}
                                                                            </IconButton>    
                                                                        </Tooltip> : null}
                                                                        {dependent.isActive && allowRevenueImpactingChanges === true ? <UpgradeMemberButton  upgradeDependent={makePrimary} dependentIndex={dependentIndex}/> : null}
                                                                        </>
                                                                    }
                                                            </TableCell>
                                                        </TableRow>
                                            })
                                        }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
}

ManageDependents.propTypes = {
    onChange: PropTypes.func.isRequired,
    dependents: PropTypes.arrayOf(PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        dateOfBirth: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        relationship: PropTypes.object,
        gender: PropTypes.string,
        phone: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        memberId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        applicationId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ])
    }))
}

export default ManageDependents;
