import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const saveCallMade = (source, applicationId, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).post(`${environment().functionsURL}call-made/${applicationId}`,
            {},
            {
                cancelToken: source.token                
            })
            .then(response => {
                resolve(response);
            }).catch(error => reject(error))
    })
}