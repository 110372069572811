import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const upsertCommission = (source, commission, authToken) => {
    return new Promise((resolve, reject) => {
        const url = `${environment().functionsURL}commission`;
        const type = commission.commissionId ? 'PUT' : 'POST';
        getAxiosInstance(authToken)({
            method: type,
            url,
            data: { payload: [commission]},
            cancelToken: source.token    
        })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}