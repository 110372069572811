import {useState, useEffect} from 'react';
import {
    Grid,
    makeStyles,
    CircularProgress,
    Button,
    Typography,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import TextField from "../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import SmartDropDown from "../common/SmartDropDown/SmartDropDown";
import Notification from "../../components/Notification/Notification";
import CheckboxField from "../../components/FormInputs/CheckboxField/CheckboxField"

const DEFAULT_FORM_VALUES = {
    agentTypeId: '',
    planTypeId: '',
    membershipTypeId: '',
    oneTimePayment: '',
    residual: '',
    startDate: '',
    endDate: '',
    isActive: true
}

const useStyles = makeStyles(theme => ({
    rootDiv: {
        padding: '20px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '2px',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.3,
            webkitBorderRadius: '2px',
            borderRadius: '2px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        }  
    },
    alignRight: {
        textAlign: 'right'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    }
}));

const UpsertCommissionTemplate = props => {

    const {data, onSave, agentTypes, planTypes, membershipTypes, cleanup, reset, notification} = props;

    const [loader, setLoader] = useState(false);

    const setInitialFormValues = initialState => {
        return initialState ? {
            agentTypeId: initialState.agentTypeId,
            planTypeId: initialState.planTypeId,
            membershipTypeId: initialState.membershipTypeId,
            oneTimePayment: initialState.oneTimePayment,
            residual: initialState.residual,
            startDate: initialState.startDate,
            endDate: initialState.endDate,
            isActive: initialState.isActive
        } : {...DEFAULT_FORM_VALUES}
    }

    const [formValues, setFormValues] = useState(setInitialFormValues(data));
    const [disableSave, setDisableSave] = useState(true);

    const classes = useStyles();

    useEffect(() => {
        if(data) {
            setFormValues(prevValues => {
                return {
                    ...prevValues,
                    agentTypeId: data.agentTypeId,
                    planTypeId: data.planTypeId,
                    membershipTypeId: data.membershipTypeId,
                    oneTimePayment: data.oneTimePayment,
                    residual: data.residual,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    isActive: data.isActive,
                    ...data.commissionId && { commissionId: data.commissionId }
                };
            });
        }
    }, [data]);

    useEffect(() => {
        let disable = false;
        if(!formValues.agentTypeId || !formValues.planTypeId || !formValues.membershipTypeId || !formValues.oneTimePayment
            || !formValues.residual || !formValues.startDate || !formValues.endDate) {
                disable = true;
            }
        setDisableSave(disable);
    }, [formValues])

    const handleFormChange = (key, value) => {
        setFormValues(prevValues => {
            return {
                ...prevValues,
                [key]: value
            }
        });
    }

    const onSaveButtonClick = () => {
        setLoader(true);
        onSave(formValues);
    }

    return <Grid container spacing={2} className={classes.rootDiv}>
                <Grid item xs={12}>
                    <Typography className={classes.heading}><strong>{data ? 'Edit Commission' : 'Add Commission'}</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                    <SmartDropDown
                        label="Agent Type"
                        category={'agent-type'}
                        value={formValues.agentTypeId}
                        loading={true}
                        required={true}
                        onChange={val => handleFormChange('agentTypeId', val)}
                        menuItems={agentTypes}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SmartDropDown
                        label="Plan Type"
                        category={'plan-type'}
                        value={formValues.planTypeId}
                        loading={true}
                        required={true}
                        onChange={val => handleFormChange('planTypeId', val)}
                        menuItems={planTypes}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SmartDropDown
                        label="Membership type"
                        category={'membership-type'}
                        value={formValues.membershipTypeId}
                        loading={true}
                        required={true}
                        onChange={val => handleFormChange('membershipTypeId', val)}
                        menuItems={membershipTypes}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Commission-One-Payment"
                        onChange={val => handleFormChange('oneTimePayment', val)}
                        value={formValues.oneTimePayment}
                        required={true}
                        isNumericOnly
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        label="Commission-Residual"
                        value={formValues.residual}
                        onChange={val => handleFormChange('residual', val)}
                        required={true}
                        isNumericOnly
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField 
                        label="Start Date"
                        fullWidth={true}
                        value={formValues.startDate}
                        onChange={date => handleFormChange('startDate', date)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimeField 
                        label="End Date"
                        fullWidth={true}
                        value={formValues.endDate}
                        onChange={date => handleFormChange('endDate', date)}
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                checked={formValues.isActive}
                                onChange={() => handleFormChange('isActive', !formValues.isActive)}
                                name="active" />
                        )}
                        label="Active"
                        labelPlacement="start" 
                    />
                </Grid> */}
                <Grid item xs={6}>
                    <CheckboxField 
                        label="Active"
                        value={formValues.isActive}
                        onChange={val => handleFormChange('isActive', val)}
                        size="medium"
                        name="commission-is-active"
                    />
                </Grid>
                <Grid item xs={12} className={classes.alignRight}>
                    <Button 
                        variant="contained"
                        style={{marginRight:5}}
                        color="primary"
                        onClick={onSaveButtonClick}
                        disabled={disableSave || loader}
                    >
                        {
                            loader ?
                            <CircularProgress style={{color: 'white', width: 14, height: 14, marginRight: 5}} /> :
                            null
                        }
                        Save
                    </Button>
                </Grid>
                {
                    notification && notification.open ?
                    <Notification
                        open={notification.open}
                        type={notification.type}
                        message={notification.message}
                        onClose={() => {
                            setLoader(false);
                            cleanup();
                            reset();
                        }}
                    /> :
                    null
                }
            </Grid>

}

export default UpsertCommissionTemplate;