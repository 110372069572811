import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
require('moment-timezone');

const NativeDateTimeField = props => {
        const {value, onChange, label, fullWidth, helperText, required, error, disabled, 
                showTime, disablePast, disableFuture, minDate, maxDate, views} = props;

        let localeData = moment.localeData();
        let localeFormat = showTime ? localeData.longDateFormat('L LT') : localeData.longDateFormat('L');

        const [selectedDate, setSelectedDate] = useState(null);

        useEffect(() => {
            if(selectedDate !== value){
                if(value){                    
                    // const s2 = moment(value).utc(false).format('YYYY-MM-DD')
                    // const v3 = moment(s2).toDate()
                    setSelectedDate(moment(value).toDate());
                }else{
                    setSelectedDate(null);
                }
            }
        }, [value]);

        const handleDateChange = (date) => {
            onChange(date);
        };

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                {
                    showTime ?
                        <KeyboardDateTimePicker
                            inputVariant="outlined"
                            format={localeFormat}
                            margin="none"
                            fullWidth={fullWidth}
                            label={label}
                            value={selectedDate}
                            required={required}
                            error={error}
                            helperText={helperText}
                            onChange={(date) => handleDateChange(date && moment(date).isValid() ? moment(date).toDate() : null)}
                            size='small'
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            disabled={disabled}
                            disablePast={disablePast}
                            disableFuture={disableFuture}
                            InputLabelProps={{
                                margin: 'dense'
                            }}
                            minDate={minDate || undefined}
                            maxDate={maxDate || undefined}
                            views={views || undefined}

                        />
                        :
                        <KeyboardDatePicker
                            inputVariant="outlined"
                            format={localeFormat}
                            margin="none"
                            fullWidth={fullWidth}
                            label={label}
                            value={selectedDate}
                            required={required}
                            error={error}
                            helperText={helperText}
                            onChange={(date) => handleDateChange(date && moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : null)}
                            size='small'
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            disabled={disabled}
                            disablePast={disablePast}
                            disableFuture={disableFuture}
                            InputLabelProps={{
                                margin: 'dense'
                            }}
                            minDate={minDate || undefined}
                            maxDate={maxDate || undefined}
                            views={views || undefined}
                            />
                }
            </MuiPickersUtilsProvider>
        );
    }
;

NativeDateTimeField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    showTime: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool
};

export default NativeDateTimeField;
