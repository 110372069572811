import {useState, useEffect} from 'react';
import {makeStyles, Backdrop, CircularProgress, IconButton, Typography} from '@material-ui/core';
import moment from 'moment';
import {saveInvoice} from '../../../apiCalls/Application/saveInvoice'
import InvoicesAndAttempts from "./InvoicesAndAttempts";
import ManualPayment from "./ManualPayment";
import {Edit, Save} from '@material-ui/icons';
import TextField from "../../../components/FormInputs/TextField/TextField";
import axios from 'axios';
import {connect} from 'react-redux';
import Notification from "../../../components/Notification/Notification";
import {invoiceStatusFromAttempt } from '../../../utils/MuiConstants'

const useStyles = makeStyles(theme => ({
    leftSection: {
        width: '49%',
        float: 'left',
        height: '86vh',
        padding: '15px',
        overflowY: 'auto'
    },
    rightSection: {
        width: '49%',
        float: 'right',
        height: '86vh',
        borderLeft: '1px solid #d5d5d5',
        padding: '15px',
        overflowY: 'auto'
    },
    scrollbar: {
        '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '10px',
            borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.1,
            webkitBorderRadius: '10px',
            borderRadius: '10px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer+1,
        color: '#fff'
    }
}));

const DEFAULT_MEMBER_DETAILS = {
    name: '',
    dob: '',
    lastPayment: '',
    nextPayment: '',
    plan: '',
    memberType: ''
};

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const BillingInformationTemplate = props => {

    const {paymentData, showEditMembershipIcon, getMembershipById, loading, accountInfo, onClose} = props;

    const [selectedInvoices, setSelectedInvoices] = useState(null);
    const [resetManualPaymentForm, setResetManualPaymentForm] = useState(null);

    const [membershipId, setMembershipId] = useState("");
    const [editMembershipId, setEditMembershipId] = useState(false);
    const [membershipIdTextFieldValue, setMembershipIdTextFieldValue] = useState("");
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [memberDetails, setMemberDetails] = useState({...DEFAULT_MEMBER_DETAILS});
    const [invoicesRecords, setInvoicesRecords] = useState([]);
    const [attemptsRecords, setAttemptsRecords] = useState([])
    const [authToken, setAuthToken] = useState("");
    const [allowAnotherPaymentAttempt, setAllowAnotherPaymentAttempt] = useState({value: false});

    const classes = useStyles(props);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    const getFormattedDate = (date) => {
        if (date) {
            return moment(new Date(date)).utcOffset(0).format('YYYY-MM-DD');
        }
        return '';
    }

    const getMemberDetails = (data) => {
        let oldestUnpaidInvoiceDate = undefined;
        if(data?.invoices && Array.isArray(data.invoices) && data.invoices.length > 0){
            data.invoices.forEach(d => {
                oldestUnpaidInvoiceDate = !d.payment && (!oldestUnpaidInvoiceDate || (oldestUnpaidInvoiceDate > d.invoiceDate)) ? d.invoiceDate : oldestUnpaidInvoiceDate;
            })
        }

        return { name: data.memberName,
            dob: data.memberDOB,
            lastPayment: getFormattedDate(data.lastPaymentDate),
            nextPayment: getFormattedDate(data.nextPaymentDate),
            plan: data.planTypeDesc,
            dueDate: getFormattedDate(oldestUnpaidInvoiceDate),
            stripeCustomerId: data.stripeCustomerId,
            isCorporate: data.isCorporate,
            memberType: data.membershipTypeDesc };
    }

    const getInvoiceStatus = (data) => {
        if (data.paymentId) {
            return 'Received'
        }
        if (data.invoiceAttempts?.length === 0) {
            return 'Pending'
        }
        return 'Failed'
    }

    const getInvoiceDetails = (data) => {
        console.log('this')
        console.log(data)
        return data.map((item) => ({
            invoiceNumber: item.invoiceId,
            invoiceDate: getFormattedDate(item.invoiceDate),
            amount: item.invoiceAmount,
            invoiceAttempts: item?.invoiceAttempts,
            fromDate: getFormattedDate(item?.fromDate),
            toDate: getFormattedDate(item?.toDate),
            status: item?.replacedByInvoice && Number.parseInt(item?.replacedByInvoice) !==  NaN ? 'Replaced' : item?.payment?.refundedAmount > 0 || item?.payment?.chargeBackAmount > 0 
                        ? item?.payment?.chargeBackAmount > 0 
                            ? (item?.payment?.chargeBackAmount < item?.payment?.paymentAmount ? 'Partial Charge Back' : 'Charge Back') 
                            : (item?.payment?.refundedAmount < item?.payment?.paymentAmount ? 'Partial Refund' : 'Refunded') 
                        : item?.invoiceAttempts 
                            ? invoiceStatusFromAttempt(item?.invoiceAttempts) 
                            : 'Pending Attempt creation'
        })).sort((a, b) => a.fromDate > b.fromDate ? 1 : (a.fromDate < b.fromDate ? -1 : a.invoiceNumber > b.invoiceNumber ? 1 : -1));
    }

    const getInvoiceAttempts = (data, invoiceAmount) => {
        return data.map((item) => ({
            invoiceAmount: invoiceAmount,
            invoiceNumber: item?.invoiceId,
            attemptId: item?.attemptId,
            attemptDate: getFormattedDate(item?.attemptWithsStatus?.attemptedOn),
            paymentAmount: item?.attemptWithsStatus?.payment?.paymentAmount || '',
            paymentDate: item?.attemptWithsStatus?.payment && item?.attemptWithsStatus?.scheduleDate ?  getFormattedDate(item?.attemptWithsStatus?.scheduleDate) : '',
            attemptedAmount: item?.attemptWithsStatus?.attemptedAmount,
            status: item?.isCourtesy === true 
                            ? 'Courtesy' 
                            : item?.attemptWithsStatus?.payment?.refundedAmount > 0 || item?.attemptWithsStatus?.payment?.chargeBackAmount > 0 
                                ? item?.attemptWithsStatus?.payment?.chargeBackAmount > 0 
                                    ? (item?.attemptWithsStatus?.payment?.chargeBackAmount < item?.attemptWithsStatus?.payment?.paymentAmount ? 'Partial Charge Back' : 'Charge Back') 
                                    : (item?.attemptWithsStatus?.payment?.refundedAmount < item?.attemptWithsStatus?.payment?.paymentAmount ? 'Partial Refund' : `Refunded`) 
                                : item?.attemptWithsStatus?.status 
                                    ? item?.attemptWithsStatus?.status 
                                    : '',
            comments: item?.attemptWithsStatus?.status ? item?.attemptWithsStatus?.comments : '',
            createdBy: item?.attemptWithsStatus?.status ? item?.attemptWithsStatus?.createdBy : '',
            refundedAmount: item?.attemptWithsStatus?.payment?.refundedAmount,
            chargeBackAmount: item?.attemptWithsStatus?.payment?.chargeBackAmount,
            refundedOn: item?.attemptWithsStatus?.payment?.refundedOn,
            chargeBackOn: item?.attemptWithsStatus?.payment?.chargeBackOn,
            stripeIntentId: item?.attemptWithsStatus?.stripeIntentId, 
            isCorporate: memberDetails?.isCorporate === true ? true : false,
            lastDigits: item?.attemptWithsStatus?.lastDigits,
            brand: item?.attemptWithsStatus?.brand
        }))
    }

    useEffect(() => {

        if(paymentData) {
            setMembershipIdTextFieldValue(paymentData?.membershipId);
            setMembershipId(paymentData?.membershipId);

            const details = getMemberDetails(paymentData)
            setMemberDetails(details);

            const invoiceDetails = getInvoiceDetails(paymentData?.invoices || []);
            setInvoicesRecords(invoiceDetails);
        }
    }, [paymentData]);

    const handleInvoiceRowClick = (data) => {
        const invoiceAttempts = getInvoiceAttempts(data?.invoiceAttempts, data.amount);
        setAttemptsRecords(invoiceAttempts);
    }

    const handleSaveMembershipId = () => {
        setMembershipId(membershipIdTextFieldValue);
        setEditMembershipId(false);
        getMembershipById(membershipIdTextFieldValue);
    }

    const handleApplyPayment = (billingData) => {
        const invoiceData = {
            applicationId: paymentData.applicationId,
            paymentDate: billingData.paymentDate.value,
            paymentAmount: billingData.amount.value,
            paidInvoices: Object.keys(selectedInvoices)?.filter((item) => selectedInvoices[item]?.manualPayment),
            courtesyInvoices: Object.keys(selectedInvoices)?.filter((item) => selectedInvoices[item]?.courtesy),
            // futureInvoiceFromDate: billingData.from.value ? new Date(billingData.from.value) : '',
            // futureInvoiceToDate: billingData.to.value ? new Date(billingData.to.value) : '',
            comment: billingData.comments.value,
            paymentTypeId: billingData.paymentMethod.value,
            ...billingData.paymentMethod.value === 'C' && {
            useCardOnFile: billingData.useCardOnFile.value,
            ...!billingData.useCardOnFile.value && {
            card: {
                updateCardOnFile: billingData.replaceCardOnFile.value,
                cardNumber: billingData.cardNumber.value,
                cardOwnerName: billingData.cardName.value,
                cardExpirationMonth: billingData.cardExpiryDate.value.month() + 1,
                cardExpirationYear: billingData.cardExpiryDate.value.year(),
                cvcCode: billingData.cardCVVNumber.value}
            } }
        };
        saveInvoice(source, invoiceData, authToken)
            .then(response => {
                setNotification({
                    open: true, 
                    type: 'success',
                    message: `Change completed submitted successfully` 
                });
            })
            .catch(error => {
                console.log('Failed to submit application', error)
                setAllowAnotherPaymentAttempt({value: true});
                setNotification({
                    open: true,
                    type: "error",
                    message: `Failed to save application${error?.response?.data?.error?.message ? ` with ${error.response.data.error.message}` : ''}.`
                });
            });
    }

    return <div>
        {
            <div>
                <div className={[classes.leftSection, classes.scrollbar].join(" ")}>
                    {
                        editMembershipId ?
                        <>
                        <TextField 
                            value={membershipIdTextFieldValue}
                            onChange={val => setMembershipIdTextFieldValue(val)}
                            required={true}
                            disabled={false}
                            label="Membership ID"
                            disallowFullWidth={true}
                        />
                        <IconButton 
                            aria-label="edit-membership-id" 
                            onClick={handleSaveMembershipId}
                        >
                            <Save style={{fontSize: '1rem'}}/>
                        </IconButton>                    
                        </>
                        :
                        <>
                            <Typography variant="subtitle2">
                                <strong>Membership ID: </strong>&nbsp;
                                {membershipId}
                                {showEditMembershipIcon && (
                                <IconButton 
                                    aria-label="edit-membership-id" 
                                    onClick={() => setEditMembershipId(true)}
                                >
                                    <Edit style={{fontSize: '1rem'}}/>&nbsp;&nbsp;
                                    {
                                        loading ?
                                        <CircularProgress style={{width: 14, height: 14, marginRight: 5}}/> :
                                        null
                                    }  
                                </IconButton>)}
                            </Typography>                  
                        </>
                    }
                    <InvoicesAndAttempts
                        invoicesRecords={invoicesRecords}
                        attemptsRecords={attemptsRecords}
                        memberDetails={memberDetails}
                        handleInvoiceSelection={newInvoices => setSelectedInvoices(newInvoices)}
                        onInvoiceRowClick={handleInvoiceRowClick}
                    />
                </div>
                <div className={[classes.rightSection, classes.scrollbar].join(" ")}>
                    <ManualPayment
                        invoices={selectedInvoices}
                        resetManualPaymentForm={resetManualPaymentForm}
                        onApplyPayment={handleApplyPayment}
                        isCardOnFile={paymentData?.hasCardOnFile}
                        allowAnotherPaymentAttempt={allowAnotherPaymentAttempt}
                    />
                </div>
                <Backdrop className={classes.backdrop} open={false}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </div> 
        }
            <Notification
                open={notification.open}
                type={notification.type}
                message={notification.message}
                onClose={() => {
                    setNotification({...DEFAULT_NOTIFICATION});
                    if(notification.type === 'success') onClose();
                }}
            />
    </div>
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(BillingInformationTemplate)
