import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';

export default function MembershipListItem(props) {
    const {membershipId, disabled, memberName, phone, generateInvoices, onToggle} = props
    return (
        <ListItem
            key={`item-${membershipId}`}>
            <ListItemText
                disabled={disabled}
                primary={`${memberName}`}
                secondary={`ID: ${membershipId} - Phone: ${phone}`}
            />
            <ListItemSecondaryAction>
                <Switch
                    edge="end"
                    disabled={disabled}
                    onChange={() => onToggle()}
                    checked={generateInvoices}
                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
}