import react, {useState, Fragment} from 'react';
import DialogBox from "../../../components/DialogBox/DialogBox";
import { makeStyles, IconButton } from '@material-ui/core';
import {ArrowUpward} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import UpgradeDependentForm from './UpgradeDependentForm';



const useStyles = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      marginLeft: '-30px'
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    tableRow: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    disableRow: {
      '&:hover': {
          cursor: 'pointer'
      },
      textDecoration: 'line-through'
    },
    principalRow: {
      '&:hover': {
          cursor: 'pointer'
      },
      fontWeight: 'bold'
    },
    iconButton: {
        fontSize: theme.typography.pxToRem(24),
        padding: '2px'
    }
  }));

const UpgradeMemberButton = (props) => {
    const {upgradeDependent, dependentIndex} = props;

    const classes = useStyles();

    const [showUpgradeDependentDialog, setShowUpgradeDependentDialog] = useState(false);

    const closeAndReset = () => {
        setShowUpgradeDependentDialog(false);
    }

    return <Fragment>        
        <DialogBox 
            size='xs'
            openDialog={showUpgradeDependentDialog}
            onCloseBtnClicked={closeAndReset}
            dialogTemplate={<UpgradeDependentForm />}
            data={{
                upgradeDependent: (dependentIndex, newRelationship) => {
                    setShowUpgradeDependentDialog(false);
                    upgradeDependent(dependentIndex, newRelationship);
                },
                dependent: dependentIndex
            }}
        />
        <Tooltip title="Set as Primary">
            <IconButton className={classes.iconButton} onClick={() => setShowUpgradeDependentDialog(true)}>
                <ArrowUpward className={classes.iconButton}></ArrowUpward>
            </IconButton>
        </Tooltip>
    </Fragment>
}

export default UpgradeMemberButton;