import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";
import CustomNumberFormat from "../../CustomNumberFormat/CustomNumberFormat";

const NativeTextField = props => {
    const {label, value, onChange, required, disabled, helperText, onBlur, error, isNumericOnly, 
            isIntegerOnly, multiline, rows, onFocus, isPhoneNumber, disallowFullWidth} = props;

    const handleChange = (value) => {
        if(onChange) onChange(value !== undefined ? value : '');
    }

    const handleBlur = (value) => {
        if(onBlur) onBlur(value);
    }

    return <TextField
                fullWidth={disallowFullWidth ? false : true}
                multiline={multiline}
                rows={rows}
                variant='outlined'
                size='small'
                label={label}
                value={value !== undefined ? value : ''}
                required={required}
                error={error}
                helperText={helperText}
                disabled={disabled}
                InputProps={isNumericOnly ? {
                        inputComponent: CustomNumberFormat,
                        inputProps:{
                            onlyInteger:isIntegerOnly,
                            maxLength: isPhoneNumber ? 10 : undefined
                        }
                    } : {}
                }
                onChange={(event) => handleChange(event.target.value)}
                onBlur={(event) => handleBlur(event.target.value)}
                onFocus={onFocus}
                InputLabelProps={{
                    margin: 'dense',
                    shrink: true,
                }}
                autoComplete="new-password"
            />
};

NativeTextField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
    ]),
    onChange: PropTypes.func,
    disabled:PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    isNumericOnly: PropTypes.bool,
    isIntegerOnly: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isPhoneNumber: PropTypes.bool
};

export default NativeTextField;
