import React from 'react';
import {TableCell, Typography} from '@material-ui/core';
import moment from 'moment'

const CommissionRowTemplate = props => {
    const {data, columnOrder, agentTypes, planTypes, membershipTypes} = props;

    return columnOrder.map(column => {
        if(column.showColumn) {
            if(column.id === 'agentTypeId') {
                return <TableCell align="left" key={column.id}>
                            <Typography>
                                {agentTypes.find((item) => item.value === (data[column.id])?.label || '')}
                            </Typography>
                        </TableCell>
            } else if(column.id === 'planTypeId') {
                return <TableCell align="left" key={column.id}>
                            <Typography>{planTypes.find((item) => item.value ===(data[column.id])?.label || '')}</Typography>
                        </TableCell>
            } else if(column.id === 'membershipTypeId') {
                return <TableCell align="left" key={column.id}>
                            <Typography>{membershipTypes.find((item) => item.value ===(data[column.id])?.label || '')}</Typography>
                        </TableCell>
            } else if(column.id === 'isActive') {
                return <TableCell align="left" key={column.id}>
                            <Typography>{data.isActive ? 'Active' : 'Inactive'}</Typography>
                        </TableCell>
            }

            if(column.id === 'startDate'){
                return <TableCell align="left" key={column.id} ><Typography>{moment(data.startDate).format('MM/DD/YYYY')}</Typography></TableCell>
            }
            if(column.id === 'endDate'){
                return <TableCell align="left" key={column.id} ><Typography>{moment(data.endDate).format('MM/DD/YYYY')}</Typography></TableCell>
            }
            return <TableCell align="left" key={column.id}><Typography>{data[column.id]}</Typography></TableCell>
        }
        return null;
    })
}

export default CommissionRowTemplate;