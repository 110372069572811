import {getAxiosInstance} from "../config/axios";
import environment from "../config/environment";

export const getCenters = (source, offset, limit, filters, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}centers`,
        {
            offset: offset,
            limit: limit, 
            ...filters
        },
        {
            cancelToken: source.token                
        }
        )
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data) {
                        resolve(response.data);
                    }
                    else {
                        resolve({data: [], count: 0})
                    }
                }
                reject(response)
            })
            .catch(error => reject(error))
    })
}

