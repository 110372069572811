import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";


export const getPayeeDetails = (membershipId, source, authToken, retries) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken, retries).get(`${environment().functionsURL}payee/${membershipId}`,
            {
                cancelToken: source.token
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response && response.data) {
                        
                        resolve(response.data);
                    }
                    else {
                        resolve([]);
                    }
                }
                reject(response);
            })
            .catch(error => {
                reject(error)
            })
    })
}