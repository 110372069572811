import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const getCommissionList = (source, offset, limit, filters, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}commission`,
            {
                params: {
                    offset: offset,
                    limit: limit,
                    membershipTypeId: filters && filters.membershipTypeId ? filters.membershipTypeId : undefined,
                    agentTypeId: filters && filters.agentTypeId ? filters.agentTypeId : undefined,
                    planTypeId: filters && filters.planTypeId ? filters.planTypeId : undefined
                },
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response?.data?.data?.length) {
                        resolve(response.data);
                    }
                    else {
                        resolve([])
                    }
                }
                reject(response)
            })
            .catch(error => reject(error))
    })
}