/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import {TextField, Box, Typography, Tooltip} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { formatDate } from '../../utils/DateHandling';
import { CreditCard, Loyalty, Money, OpacityOutlined } from '@material-ui/icons';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

function PaymentIcon(props){
  const {data} = props;

  if(data?.isCourtesy){
    return <Tooltip title="Courtesy"><Loyalty color="primary"></Loyalty></Tooltip>
  }

  if(data?.isCash){
    return <Tooltip title="Cash/Check/ACH/Other"><Money color="primary"></Money></Tooltip>;
  }

  return <Tooltip title="Card Payment"><CreditCard color="primary"></CreditCard></Tooltip>;
}

export default function PaymentDropDown(props) {
  const {data, invoiceId, onPaymentSelected, disabled} = props;
  const classes = useStyles();

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const loadedPayments = [];
    data?.forEach(i => {      
      if(i.payment?.paymentId && i.invoiceId !== invoiceId){
        const payment = {
          paymentDate: formatDate(i.payment?.paymentTimestamp),
          paymentAmount: i.payment?.paymentAmount,
          paymentId: i.payment?.paymentId,
          invoiceAmount: i.invoiceAmount,
          invoiceId: i.invoiceId,
          invoiceDate: i.invoiceDate,
          fromDate: i.fromDate,
          toDate: i.toDate,
          payment: {
            paymentTimestamp: i.payment?.paymentTimestamp,
            paymentAmount: i.payment?.paymentAmount,
            confirmation: i.payment?.confirmation
          },
          isCash: i.isCashPayment,
          isCourtesy: i.isCourtesy,          
          label: `Id: ${i.payment?.paymentId} Amount: ${i.payment?.paymentAmount} Paid On: (${formatDate(i.payment?.paymentTimestamp)})`
        }

        loadedPayments.push(payment);
      }
    })

    setPayments(loadedPayments);
  }, [data])

  return (
    <Autocomplete
      id="payment-select-demo"
      fullWidth={true}
      options={payments}
      classes={{
        option: classes.option,
      }}
      onChange={(event, newValue) => {
        onPaymentSelected(newValue);
      }}
      disabled={disabled}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(option) => (
        <React.Fragment>
          <Box alignItems="flex-start">
            <PaymentIcon data={option}/><Typography style={{float: 'right'}}>{option.label}</Typography> 
          </Box>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a Payment to link"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}