import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const PopOverContent = (props) => (
    <>
      {props.data.isCorporate ? <Typography>{`Corporate Paid: ${props.data.attemptedAmount}`}</Typography> : null}
      {props.data.refundedAmount > 0 ? <Typography >{`Refunded: ${(parseInt(props.data.refundedAmount)).toString()}`}</Typography> : null}
      {props.data.chargeBackAmount > 0 ? <Typography >{`Charge Back: ${(parseInt(props.data.chargeBackAmount)).toString()}`}</Typography> : null}        
    </>
)

export default PopOverContent;