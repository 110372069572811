import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";import fromEmptyStringToNull from "../../utils/DataManipulation";

export const swapPayment = (paymentSwapInfo, source, authToken) => {
    return new Promise((resolve, reject) => {
        
        getAxiosInstance(authToken).post(`${environment().functionsURL}payment-swap`,
            {
                ...paymentSwapInfo
            },
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200 && response.data) {
                    resolve(response.data);
                }
                else reject(response);
            })
            .catch(error => reject(error));
    })
}