import {useState, useEffect} from 'react';
import AutocompleteDropdown from "../../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import {getPlanInfo} from "../../../apiCalls/getPlanInfo";
import axios from 'axios';
import PropTypes from "prop-types";
import Notification from "../../../components/Notification/Notification";
import {connect} from 'react-redux';

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

const PlanInfoSmartDropDown = props => {

    const {label, value, onChange, required, disabled, error, helperText, membershipTypeId, blankAllowed, accountInfo} = props;
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newValue, setNewValue] = useState(null);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(value) setNewValue(value)
        else {
            if(options.length && !blankAllowed) {
                setNewValue(options[0].value);
                onChange(options[0].value);
            }
            else setNewValue(null);
        };
    }, [value, options])

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        if(authToken) {
            setLoading(true)
            getPlanInfo(source, {membershipTypeId}, authToken)
            .then(response => {
                let planInfo = undefined;
                setOptions(response.data.map(d => {
                    planInfo = value && value.planTypeId === d.planTypeId ? {
                        planDesc: d.planDesc,
                        planInfoId: d.planInfoId,
                        planTypeId: d.planTypeId,
                        membershipTypeId: d.membershipTypeId,
                        fee: d.fee,
                        monthsPay: d.monthsPay,
                        monthlyPayment: d.monthlyPayment,
                        stripeProductId: d.stripeProductId,
                        basePlanTypeId: d.basePlanTypeId,
                        addOnPlanTypeId: d.addOnPlanTypeId,
                        addOnFee: d.addOnFee,
                        addOnMonthsPaid: d.addOnMonthsPaid,
                        addOnMonthlyPayment: d.addOnMonthlyPayment,
                        includeHospital: d.includeHospital
                    } : planInfo; 
                    
                    return {
                        label: d.planDesc,
                        value: {
                            planDesc: d.planDesc,
                            planInfoId: d.planInfoId,
                            planTypeId: d.planTypeId,
                            membershipTypeId: d.membershipTypeId,
                            fee: d.fee,
                            monthsPay: d.monthsPay,
                            monthlyPayment: d.monthlyPayment,
                            stripeProductId: d.stripeProductId,
                            basePlanTypeId: d.basePlanTypeId,
                            addOnPlanTypeId: d.addOnPlanTypeId,
                            addOnFee: d.addOnFee,
                            addOnMonthsPaid: d.addOnMonthsPaid,
                            addOnMonthlyPayment: d.addOnMonthlyPayment,
                            includeHospital: d.includeHospital
                        }
                    }
                }));
                if(planInfo){
                    onChange(planInfo);
                }
                
                setLoading(false);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled plan info options fetching");
                } else {
                    console.log('Failed to fetch plan info options', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch plan info options"
                    });
                }
            });
        }

        return () => source.cancel();
    }, [membershipTypeId, authToken]);

    return <>
                <AutocompleteDropdown
                    label={label}
                    options={options}
                    value={newValue}
                    valueIndexes={
                        newValue ? [options.findIndex(opt => opt.value.planTypeId === newValue.planTypeId)] : []
                    }
                    loading={loading}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    error={error}
                    helperText={helperText}
                />
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </>
}

PlanInfoSmartDropDown.propTypes = {
    label: PropTypes.string.isRequired, 
    value: PropTypes.object, 
    onChange: PropTypes.func.isRequired, 
    required: PropTypes.bool,
    disabled: PropTypes.bool, 
    error: PropTypes.bool, 
    helperText: PropTypes.string,
    membershipTypeId: PropTypes.string,
    blankAllowed: PropTypes.bool
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(PlanInfoSmartDropDown);

