import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";
import fromEmptyStringToNull from "../../utils/DataManipulation";

export const saveInvoiceDetails = (invoiceDetails, source, authToken) => {
    return new Promise((resolve, reject) => {
        const data = {...invoiceDetails};
        data.payment = fromEmptyStringToNull(invoiceDetails.payment || '');
        data.replacedByInvoice = invoiceDetails.replacedByInvoice === '' ? null : invoiceDetails.replacedByInvoice;
        data.creditAmount = invoiceDetails.creditAmount === '' ? null : invoiceDetails.creditAmount; 
        
        getAxiosInstance(authToken).post(`${environment().functionsURL}invoice-detail`, data,
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200 && response.data) {
                    resolve(response.data);
                }
                else reject(response);
            })
            .catch(error => reject(error));
    })
}