import axios from 'axios';
import axiosRetry from 'axios-retry';

export const getAxiosInstance = (authToken, axioRetries) => {
    const axiosInstance = axios.create({
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    });
    
    axiosRetry(axiosInstance, { 
        retries: (axioRetries ? axioRetries : 10),
        retryDelay: axiosRetry.exponentialDelay 
    });

    return axiosInstance;
}