import React from 'react';
import {
    ExitToApp,
    Home,
    Store,
    MonetizationOn,AccountBalanceOutlined,
    ListAlt,
    People,
    Receipt,
    CreditCard
} from '@material-ui/icons';
import Visits from '../features/Visits/Visits';
import Commission from "../features/Commission/Commission";
import MemberList from "./../features/Member/MemberList";
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as CenterIcon } from "../assets/media/stethoscope.svg";
import ApplicationList from "./../features/Application/ApplicationList";
import ManualBilling from "./../features/ManualBilling/ManualBilling";
import InvoiceManager from "./../features/Invoice/InvoiceManager"

export const restrictedRoutes = [{
    linkName: 'Home',
    path: '/',
    component: <MemberList />,
    showInNavigation: false,
    icon: <Home />
},
{
    linkName: 'Members',
    path: '/members',
    component: <MemberList />,
    showInNavigation: true,
    icon: <People />
},
{
    linkName: 'Signout',
    path: '/signout',
    component: <h1>Signout</h1>,
    showInNavigation: true,
    icon: <ExitToApp />
}]

export const expandedRoutes = [
    {
        linkName: 'Home',
        path: '/',
        component: <MemberList />,
        showInNavigation: false,
        icon: <Home />
    },
    {
        linkName: 'Members',
        path: '/members',
        component: <MemberList />,
        showInNavigation: true,
        icon: <People />
    },
    {
        linkName: 'Invoice Management',
        path: '/invoice/',
        component: <InvoiceManager />,
        showInNavigation: true,
        icon: <AccountBalanceOutlined />
    },
    {
        linkName: 'Invoice Management',
        path: '/invoice/:invNr/',
        component: <InvoiceManager />,
        icon: <AccountBalanceOutlined />
    },
    {
        linkName: 'Billing and Payments',
        path: '/billing',
        component: <ManualBilling />,
        showInNavigation: true,
        icon: <CreditCard/>
    },
    {
        linkName: 'Commissions',
        path: '/commissions',
        component: <Commission />,
        showInNavigation: true,
        icon: <MonetizationOn />
    },
    {
        linkName: 'Member Visits',
        path: '/visits',
        component: <Visits />,
        showInNavigation: true,
        icon: <CenterIcon />
    },
    {
        linkName: 'Signout',
        path: '/signout',
        component: <h1>Signout</h1>,
        showInNavigation: true,
        icon: <ExitToApp />
    }
]

export const routes = [
    {
        linkName: 'Home',
        path: '/',
        component: <MemberList />,
        showInNavigation: false,
        icon: <Home />
    },
    {
        linkName: 'Members',
        path: '/members',
        component: <MemberList />,
        showInNavigation: true,
        icon: <People />
    },
    {
        linkName: 'Billing and Payments',
        path: '/billing',
        component: <ManualBilling />,
        showInNavigation: true,
        icon: <CreditCard/>
    },
    {
        linkName: 'Commissions',
        path: '/commissions',
        component: <Commission />,
        showInNavigation: true,
        icon: <MonetizationOn />
    },
    {
        linkName: 'Member Visits',
        path: '/visits',
        component: <Visits />,
        showInNavigation: true,
        icon: <CenterIcon />
    },
    {
        linkName: 'Signout',
        path: '/signout',
        component: <h1>Signout</h1>,
        showInNavigation: true,
        icon: <ExitToApp />
    }
]