import React from 'react';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import {ArrowBackIos, ArrowForwardIos, Close} from "@material-ui/icons";
import PropTypes from "prop-types";

const useStyles = makeStyles({
    dialogContent:{
        padding:'0px',
        height: props => props.height || '500px',
        overflowY: props => props.width === 'xl' ? 'auto' : 'hidden',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    paper: {
        overflowY: 'inherit'
    },
    closeBtn: {
        position: 'absolute',
        right: '-40px',
        color: '#C7C9C7',
        fontSize: '36px',
        opacity: 0.8,
        '&:hover' : {
            opacity: 1
        }
    },
    prevBtn: {
        position: 'absolute',
        minWidth: '45px',
        minHeight: '45px',
        padding: '0px',
        left: '-50px',
        top: '45%',
        opacity: 0.8,
        color:'#989090'
    },
    nextBtn: {
        position: 'absolute',
        minWidth: '45px',
        minHeight: '45px',
        padding: '0px',
        right: '-60px',
        top: '45%',
        opacity: 0.8,
        color:'#989090'
    }
});

const NextPrevDialog = props => {
    const classes = useStyles(props);
    const selectedRowId = props.data ? props.data.id : null;

    const renderTemplate = (child) => {
        return React.cloneElement(child,
            {
                data: props.data,
                height: props.height
            }
        );
    }

    return <div>
        <Dialog open={props.openDialog} maxWidth={props.width || 'md'} fullWidth={true} classes={{paper: classes.paper}} disableBackdropClick={true}>
            <Close classes={{root: classes.closeBtn}} onClick={props.onCloseBtnClicked}/>

            <Button variant="contained" disabled={props.isFirstItem} classes={{root: classes.prevBtn}} onClick={() => props.onPrevBtnClicked(selectedRowId)}>
                <ArrowBackIos/>
            </Button>

            <DialogContent classes={{root: classes.dialogContent}}>
                {renderTemplate(props.dialogTemplate)}
            </DialogContent>

            <Button variant="contained" disabled={props.isLastItem} classes={{root: classes.nextBtn}} onClick={() => props.onNextBtnClicked(selectedRowId)}>
                <ArrowForwardIos/>
            </Button>
        </Dialog>
    </div>;
}

NextPrevDialog.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    onCloseBtnClicked: PropTypes.func.isRequired,
    onPrevBtnClicked: PropTypes.func.isRequired,
    onNextBtnClicked: PropTypes.func.isRequired,
    dialogTemplate: PropTypes.element.isRequired,
    height: PropTypes.number,
    width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    data: PropTypes.object
}

export default NextPrevDialog;

