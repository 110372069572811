import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table, Box} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {formatDate} from '../../utils/DateHandling'
import Tooltip from '@material-ui/core/Tooltip';
import { AttachMoney, CreditCard, Error, ErrorOutline, HelpOutline, LinkSharp, Loyalty, Money, MoneyOff, QuestionAnswer, Schedule } from '@material-ui/icons';
import RelinkInvoice from './RelinkInvoice';
import NativeDialogBox from '../../components/DialogBox/DialogBox';
import { IconButton } from '@material-ui/core';
import {ReactComponent as Refund} from '../../assets/media/refund.svg';
import {ReactComponent as ChargeBack} from '../../assets/media/chargeback.svg';
import { SettingsBackupRestore } from '@material-ui/icons';

export default function InvoiceIcon(props) {
    const {invoice} = props;

    if(invoice.refundedAmount > 0){
        return  (
            <Tooltip title={`Refund: ${invoice.refundedAmount}`}>                
                <SettingsBackupRestore color="primary"/>
            </Tooltip>)
    }

    if(invoice.chargeBackAmount > 0){
        return  (
            <Tooltip title={` Charge Back: ${invoice.chargeBackAmount}`}>
                <MoneyOff color="error"/>
            </Tooltip>)
    }
    
    if(invoice.isCourtesy){
        return <Tooltip title="Courtesy"><Loyalty color="primary"></Loyalty></Tooltip>
    }

    if(invoice.paymentId > 0){
        //debugger;
        if(invoice.isCashPayment === true){
            return <Tooltip title="Cash/Check/ACH/Other"><Money color="primary"></Money></Tooltip>
        }

        return <Tooltip title="Card Payment"><CreditCard color="primary"></CreditCard></Tooltip>
    }

    if(invoice.isAttempted === false){
        return <Tooltip title="Pending"><Schedule color="primary"></Schedule></Tooltip>;
    }

    if(invoice.isAttempted === true){
        return <Tooltip title="Failure to collect"><ErrorOutline color="primary"></ErrorOutline></Tooltip>
    }

    return <Tooltip title="Contact suppport"><HelpOutline color="primary"></HelpOutline></Tooltip>
}