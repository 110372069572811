import {useState, useEffect} from 'react';
import {
    Grid, 
    Typography, 
    FormControl, 
    Select, 
    MenuItem, 
    InputLabel, 
    Button,
    CircularProgress
} from '@material-ui/core';
import PaymentCardInput from "../../../components/PaymentCardInput/PaymentCardInput";
import TextField from "../../../components/FormInputs/TextField/TextField"
import DateTimeField from "../../../components/FormInputs/DateTimeField/DateTimeField";
import CheckboxField from "../../../components/FormInputs/CheckboxField/CheckboxField";
import moment from 'moment';
import {checkCreditCardValidity} from "../../../helperFunctions/checkCreditCardValidity";
import {checkCVVValidity} from "../../../helperFunctions/checkCVVValidity";
import PaymentTypeSmartDropDown from "../../common/PaymentTypeSmartDropDown/PaymentTypeSmartDropDown";
import { FormatBoldTwoTone } from '@material-ui/icons';

const DEFAULT_FORM_DATA = {
    paymentMethod: {
        value: "C"
    },
    cardNumber: {
        value: ""
    },
    cardName: {
        value: ""
    },
    cardExpiryDate: {
        value: null
    },
    cardCVVNumber: {
        value: ""
    },
    useCardOnFile: {
        value: false
    },
    replaceCardOnFile: {
        value: false
    },
    paymentDate: {
        value: null,
    },
    amount: {
        value: 0,
        actualAmount: 0
    },
    comments: {
        value: ""
    },
    from: {
        value: null,
        minimum: null
    },
    to: {
        value: null,
        minimum: null
    }
}

const ManualPayment = props => {

    const {invoices, resetManualPaymentForm, onApplyPayment, isCardOnFile, allowAnotherPaymentAttempt} = props;

    const [formData, setFormData] = useState({...DEFAULT_FORM_DATA});
    const [errorState, setErrorState] = useState(true);
    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    //const [allowCourtesy, setAllowCourtesy] = useState(false);
    const [isAllCourtesySel, setIsAllCourtesySel] = useState(false);

    useEffect(() => {
        if(allowAnotherPaymentAttempt && allowAnotherPaymentAttempt.value) {
            setLoading(false);
        }
    }, [allowAnotherPaymentAttempt])

    useEffect(() => {
        let total = 0;
        let minDate = '1900-01-01';
        let isAnyPayment = false;
        let isAnyCourtesy = false;
        let courtesyCount = 0;
        let invoiceCount = 0;
        let isAllCourtesy = false;
        if(invoices) {            
            for(let key in invoices) {
                if(invoices[key].manualPayment) {
                    total = total + Number(invoices[key].amount);
                    if (!isAnyPayment) {
                        isAnyPayment = true;
                    }
                }
                minDate = moment(invoices[key].invoiceDate, 'YYYY-MM-DD').isAfter(moment(minDate, 'YYYY-MM-DD')) ?
                            moment(invoices[key].invoiceDate, 'YYYY-MM-DD') : moment(minDate, 'YYYY-MM-DD');
                if (!isAnyCourtesy && invoices[key].courtesy) {
                    isAnyCourtesy = true;
                }                   
                
                courtesyCount += (invoices[key].courtesy ? 1 : 0);
                invoiceCount++;
            }
            isAllCourtesy = (courtesyCount === invoiceCount && invoiceCount > 0 && courtesyCount > 0)
        }else{
            isAllCourtesy = false;
        }
        handleFormDataChange('from', 'value', null);
        handleFormDataChange('from', 'minimum', moment(minDate).add(1, 'months'));
        handleFormDataChange('to', 'value', null);
        handleFormDataChange('to', 'minimum', moment(minDate).add(2, 'months'));
        handleFormDataChange('amount', 'value', total);
        handleFormDataChange('amount', 'actualAmount', total);
        handleFormDataChange('amount', 'isAnyCourtesy', isAnyCourtesy);

        if(isAllCourtesy){
            handleFormDataChange('amount', 'value', 0)
            handleFormDataChange('paymentMethod', 'value', 'Y')
        }else {
            handleFormDataChange('paymentMethod', 'value', 'C')
        }

        setIsAllCourtesySel(isAllCourtesy);
        setEnabled(isAnyPayment || isAllCourtesy);
    }, [invoices]);

    useEffect(() => {
        if(resetManualPaymentForm && resetManualPaymentForm.value) {
            setFormData({...DEFAULT_FORM_DATA});
            setErrorState(true);
        }
    }, [resetManualPaymentForm]);

    useEffect(() => {
        let flag = false;

        if(formData.paymentMethod.value.toLowerCase() === 'c') {
            if( !formData.useCardOnFile.value && 
                ( !formData.cardNumber.value || !formData.cardName.value 
                    || !formData.cardExpiryDate.value || !formData.cardCVVNumber.value 
                    || !checkCreditCardValidity(formData.cardNumber.value) 
                    || !checkCVVValidity(formData.cardNumber.value, formData.cardCVVNumber.value)
                )
            ) {
                flag = true;
            }
        }
        else{ /* Force a comment when payment is not a card */
            if(((Number(formData.amount.value) !== Number(formData.amount.actualAmount)) || formData.amount.isAnyCourtesy || formData.paymentMethod.value.toLowerCase() !== 'c')
                && (!formData.comments.value || formData.comments.value.length === 0)){
                    flag = true;
                }
        }
        
        if(!formData.paymentDate.value || formData.amount.value==='' || formData.amount.value=== undefined) flag = true;
        if( (Number(formData.amount.value) !== Number(formData.amount.actualAmount) || formData.amount.isAnyCourtesy) && !formData.comments.value ) {
            flag = true;
        }
        if( (formData.from.value && !formData.to.value) || (formData.to.value && !formData.from.value) ) {
            flag = true;
        }
        setErrorState(flag);

    }, [formData]);

    const handleFormDataChange = (key, subkey, value) => {
        if(key === 'amount' && value === ''){
            value = 0;
        }
        debugger;
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [key]: {
                    ...prevFormData[key],
                    [subkey]: value
                }
            }
        })
    }

    const checkCCNumberError = () => {
        const isValid = ( !formData.cardNumber.value || !checkCreditCardValidity(formData.cardNumber.value));
        return isValid;
    }

    const checkCCVError = () => {
        return ( !formData.useCardOnFile.value && 
            ( !formData.cardCVVNumber.value || !checkCVVValidity(formData.cardNumber.value, formData.cardCVVNumber.value) ) );
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography variant="h6"><strong>Manual Payment</strong></Typography>
        </Grid>
        <Grid item xs={12}>
            <FormControl variant="outlined" size="small" fullWidth>
                {/* <InputLabel>Payment Method *</InputLabel> */}
                {/* <Select
                    value={formData.paymentMethod.value}
                    onChange={event => handleFormDataChange('paymentMethod', 'value', event.target.value)}
                    label="Payment Method"
                    style={{height: '35px'}}
                    disabled={!enabled}
                >
                    <MenuItem value="card">Card</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                </Select> */}
                <PaymentTypeSmartDropDown
                        label="Payment Method *"
                        value={formData.paymentMethod.value}
                        onChange={val => handleFormDataChange('paymentMethod', 'value', val)}
                        required={true}
                        isCourtesyOnly={isAllCourtesySel}
                        disabled={!enabled}
                    />
            </FormControl>
        </Grid>
        <Grid item xs={6}>
            <TextField 
                label="Amount"
                value={formData.amount.value}
                onChange={val => handleFormDataChange('amount', 'value', val)}
                isNumericOnly
                required={true}
                disabled={!enabled}
            />
        </Grid>
        <Grid item xs={6}>
            <DateTimeField
                label="Payment Date"
                fullWidth={true}
                value={formData.paymentDate.value}
                onChange={date => handleFormDataChange('paymentDate', 'value', date)}
                disablePast={formData.paymentMethod.value.toLowerCase() === 'c'}
                required={true}
                disabled={!enabled}
                error={(formData.paymentDate.value || '') === ''}
                helperText={(formData.paymentDate.value || '') === '' ? 'Payment Date is required' : ''}
            />
        </Grid>
        {/* <Grid item xs={12}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="future-invoices-content"
                    id="future-invoices-header"
                    disabled={!enabled}
                >
                    <Typography>Future Invoices</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                            <KeyboardDatePicker
                                inputVariant="outlined"
                                label="From"
                                clearable
                                value={formData.from.value}
                                onChange={date => {
                                    handleFormDataChange('from', 'value', date);
                                    handleFormDataChange('to', 'value', null);
                                    handleFormDataChange('to', 'minimum', moment(date).add(1, 'months'));
                                }}
                                required={false}
                                size='small'
                                views={['year', 'month']}
                                minDate={formData.from.minimum}
                                fullWidth
                                error={formData.to.value && !formData.from.value}
                                helperText={formData.to.value && !formData.from.value ? 
                                        "Required when a To date is entered" : undefined}
                                disabled={!enabled}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={moment.locale()}>
                            <KeyboardDatePicker
                                inputVariant="outlined"
                                label="To"
                                clearable
                                value={formData.to.value}
                                onChange={date => handleFormDataChange('to', 'value', date)}
                                required={formData.from.value ? true : false}
                                size='small'
                                views={['year', 'month']}
                                minDate={formData.to.minimum}
                                fullWidth
                                error={formData.from.value && !formData.to.value}
                                helperText={formData.from.value && !formData.to.value ? 
                                        "Required when a From date is entered" : undefined}
                                disabled={!enabled}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid> */}
        <Grid item xs={12}>
            <TextField
                value={formData.comments.value}
                onChange={val => handleFormDataChange('comments', 'value', val)}
                label="Comments"
                rows={4}
                multiline={true}
                disabled={!enabled}
                error={((Number(formData.amount.value) !== Number(formData.amount.actualAmount)) || formData.amount.isAnyCourtesy || formData.paymentMethod.value.toLowerCase() !== 'c')
                    && (!formData.comments.value || formData.comments.value.length === 0) && enabled
                }
                helperText={(Number(formData.amount.value) !== Number(formData.amount.actualAmount)
                    || formData.amount.isAnyCourtesy) && !formData.comments.value && enabled ? 
                            "Required when courtesy is added or Amount has changed" : undefined}
            />
        </Grid>
        {
            formData.paymentMethod.value.toLowerCase() === 'c' ?
            (<>
                {
                    isCardOnFile ?
                    <Grid item xs={12}>
                        <CheckboxField 
                            label="Use Card On File"
                            value={formData.useCardOnFile.value}
                            onChange={val => handleFormDataChange('useCardOnFile', 'value', val)}
                            size="medium"
                            name="use-card-on-file"
                            disabled={!enabled}
                        />
                    </Grid> :
                    null
                }
                {
                    !formData.useCardOnFile.value ?
                    <>
                        <Grid item xs={12}>
                            <PaymentCardInput
                                cardDetails={{
                                    cardNumber: {
                                        value: formData.cardNumber.value,
                                        disabled: !enabled,
                                        required: true,
                                        error: checkCCNumberError(),
                                        helperText: checkCCNumberError() ? "Valid Card Number is required" : undefined
                                    },
                                    cardName: {
                                        value: formData.cardName.value,
                                        disabled: !enabled
                                    },
                                    cardExpiryDate: {
                                        value: formData.cardExpiryDate.value,
                                        disabled: !enabled,
                                        required: true,
                                        error: !formData.useCardOnFile.value && !formData.cardExpiryDate.value,
                                        helperText: !formData.useCardOnFile.value && !formData.cardExpiryDate.value ? "Valid expiry is required" : undefined
                                    },
                                    cardCVVNumber: {
                                        value: formData.cardCVVNumber.value,
                                        disabled: !enabled,
                                        required: true,
                                        error: checkCCVError(),
                                        helperText: checkCCVError() ? "Valid CVV is required" : undefined
                                    }
                                }}
                                onChange={(key, val) => handleFormDataChange(key, 'value', val)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField 
                                label="Name On The Card"
                                value={formData.cardName.value}
                                onChange={val => handleFormDataChange('cardName', 'value', val)}
                                disabled={!enabled}
                                required={true}
                                error={!formData.useCardOnFile.value && !formData.cardName.value}
                                helperText={!formData.useCardOnFile.value && !formData.cardName.value ? "Name on Card is required" : undefined}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CheckboxField 
                                label="Replace Card On File"
                                value={formData.replaceCardOnFile.value}
                                onChange={val => handleFormDataChange('replaceCardOnFile', 'value', val)}
                                size="medium"
                                name="replace-card-on-file"
                                disabled={!enabled}
                            />
                        </Grid> 
                    </>:
                    null
                }
            </>) :
            null
        }
        <Grid item xs={12}>
            <Button 
                variant="contained" 
                color="secondary" 
                size="small"
                onClick={() => {
                    setLoading(true);
                    onApplyPayment(formData);
                }}
                disabled={!enabled || errorState || loading}
            >
                Apply Payment
                {
                    loading ?
                    <CircularProgress style={{color: 'white', width: 14, height: 14, marginRight: 5}}/> :
                    null
                }
            </Button>
        </Grid>
    </Grid>
}

export default ManualPayment;