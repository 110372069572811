import React from 'react';
import {TableCell, Typography} from '@material-ui/core';
import moment from 'moment'

const VisitRowTemplate = props => {
    const {data, columnOrder, visitTypes} = props;

    return columnOrder.map(column => {
        if(column.showColumn) {
            if(column.id === 'visitTypeId') {
                return <TableCell align="left" key={column.id}>
                            <Typography>
                                {visitTypes.find((item) => item.value === (data[column.id])?.label || '')}
                            </Typography>
                        </TableCell>
            } else if(column.id === 'primaryMember') {
                return <TableCell align="left" key={column.id}>
                            <Typography>
                                {`${data['memberFirstName']} ${data['memberLastName']}`}
                            </Typography>
                        </TableCell>
            }
            if(column.id === 'visitDate'){
                return <TableCell align="left" key={column.id} ><Typography>{moment(data.visitDate).format('MM/DD/YYYY')}</Typography></TableCell>
            }
            return <TableCell align="left" key={column.id}><Typography>{data[column.id]}</Typography></TableCell>
        }
        return null;
    })
}

export default VisitRowTemplate;