import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const cancelDeleteApplication = (source, applicationId, authToken) => {
    return new Promise((resolve, reject) => {
        if(applicationId) {
            getAxiosInstance(authToken).delete(`${environment().functionsURL}membership/cancel-delete/${applicationId}`,
            {
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    resolve(response.data);
                }
                else reject(response);
            })
            .catch(error => reject(error))
        }
        else {
            reject({
                errorMessage: "Please pass applciationId as second parameter to the API function call."
            });
        }

    })
}