import {useState, useEffect, useCallback} from 'react';
import Table from "../../components/Table/Table";
import MemberRowTemplate from "./MemberRowTemplate";
import {
    Grid
} from '@material-ui/core';
import MemberFilters from "./MemberFilters";
import {getApplicationList} from "../../apiCalls/Application/getApplicataionList";
import {debounce} from 'lodash';
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import {getApplicationById} from "../../apiCalls/Application/getApplicationById";
import DialogBox from "../../components/DialogBox/DialogBox";
import NewApplication from "./../NewApplication/NewApplication";
import BillingInformationTemplate from "./Billing/BillingInformationTemplate";
import PageHeader from "../../components/PageHeader/PageHeader";
import ExcelExporter from "../../components/ExcelExporter/ExcelExporter";
import {connect} from 'react-redux';
import GenerateInvoices from "./Billing/GenerateInvoices";
import { isLimited, isElevatedPermissions } from '../../utils/MuiConstants';
import { formatDate } from '../../utils/DateHandling';
import environment from "../../config/environment";
import { getHistoryReport } from '../../apiCalls/Application/getHistoryReport';
import moment from 'moment';

const DEFAULT_MEMBER_LIST_HEADCELLS = [
    //{id: 'manualPayment', label: 'Manual Payment', showColumn: true, sortable: 'true'},
    {id: 'generateInvoices', label: 'Invoices/Payments', showColumn: true, sortable: 'true'},
    {id: 'flags', label: 'Flag/Statuses', showColumn: true, sortable: 'true'},
    {id: 'membershipId', label: 'Membership ID', showColumn: true, sortable: 'true'},
    {id: 'memberName', label: 'Member Name', showColumn: true, sortable: 'true'},
    {id: 'isActive', label: 'Member Status', showColumn: true, sortable: 'true'},
    {id: 'startDate', label: 'Effective Date', showColumn: true, sortable: 'true'},    
    {id: 'planTypeDesc', label: 'Type of Plan', showColumn: true, sortable: 'true'},
    {id: 'agentName', label: 'Agent Name', showColumn: true, sortable: 'true'},
    {id: 'eMail', label: 'Email', showColumn: true, sortable: 'true'},
    {id: 'primaryCenterId', label: 'Center', showColumn: true, sortable: 'true'},
    {id: 'lastPaymentDate', label: 'Last Payment Date', showColumn: true, sortable: 'true'},
    {id: 'nextPaymentDate', label: 'Next Payment Date', showColumn: true, sortable: 'true'},
    {id: 'paymentStatus', label: 'Payment Status', showColumn: true, sortable: 'true'},
];

const LIMITED_MEMBER_LIST_HEADCELLS = [
    {id: 'flags', label: 'Flag/Statuses', showColumn: true, sortable: 'true'},
    {id: 'membershipId', label: 'Membership ID', showColumn: true, sortable: 'true'},
    {id: 'memberName', label: 'Member Name', showColumn: true, sortable: 'true'},
    {id: 'isActive', label: 'Member Status', showColumn: true, sortable: 'true'},
    {id: 'startDate', label: 'Effective Date', showColumn: true, sortable: 'true'},    
    {id: 'planTypeDesc', label: 'Type of Plan', showColumn: true, sortable: 'true'},
    {id: 'agentName', label: 'Agent Name', showColumn: true, sortable: 'true'},
    {id: 'eMail', label: 'Email', showColumn: true, sortable: 'true'},
    {id: 'primaryCenterId', label: 'Center', showColumn: true, sortable: 'true'},
    {id: 'lastPaymentDate', label: 'Last Payment Date', showColumn: true, sortable: 'true'},
    {id: 'nextPaymentDate', label: 'Next Payment Date', showColumn: true, sortable: 'true'},
    {id: 'paymentStatus', label: 'Payment Status', showColumn: true, sortable: 'true'},
];

const DEFAULT_APPLICATIONS_PER_PAGE = 50;

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const MemberList = (props) => {

    const {accountInfo} = props;

    const [refreshList, setRefreshList] = useState(false);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filterValues, setFilerValues] = useState({});
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [isApplicationDialogOpen, setIsApplicationDialogOpen] = useState(false);
    const [applicationDialogData, setApplicationDialogData] = useState([]);
    const [isApplicationProcessed, setIsApplicationProcessed] = useState(false);
    const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
    const [manualPaymentData, setManualPaymentData] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [totalRecords, setTotalRecords] = useState([]);
    const [isGenerateInvoiceDialogOpen, setIsGenerateInvoiceDialogOpen] = useState(false);
    const [generateInvoiceData, setGenerateInvoiceData] = useState("");
    
    const [shouldRefresh, setShouldRefresh] = useState(false);

    const debounceSetPageNumber = useCallback(debounce(() => setPageNumber(0), 1000), []);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(isPaymentDialogOpen){
            setIsGenerateInvoiceDialogOpen(false); 
            setIsApplicationDialogOpen(false);
            return;
        }

        if(isApplicationDialogOpen){
            setIsGenerateInvoiceDialogOpen(false); 
            setIsPaymentDialogOpen(false);
            return;
        }

        setIsApplicationDialogOpen(false);
        setIsPaymentDialogOpen(false);
    }, [isPaymentDialogOpen, isGenerateInvoiceDialogOpen, isApplicationDialogOpen])

    useEffect(() => {
        if(pageNumber === 0) {
            return setPageNumber(1);
        }
        if(authToken)
            fetchApplicationList(authToken);

        //getApplicationList(source, 0, 10000, {}, authToken).then((response) => setTotalRecords(response?.data || []));

        return () => source.cancel();
    }, [pageNumber, authToken]);

    const loadMoreRecords = () => {
        setLoading(true);
        if(pageNumber < totalPages) {
            setPageNumber(prevPageNumber => prevPageNumber+1);
        }
    }

    const fetchApplicationList = (aT) => {
        setLoading(true);
        getApplicationList(source, ((pageNumber-1) * DEFAULT_APPLICATIONS_PER_PAGE),  DEFAULT_APPLICATIONS_PER_PAGE, filterValues, aT)
        .then(response => {
            if(pageNumber === 1) {
                setData(response.data);
                const absolutePages = Number(response.count) / DEFAULT_APPLICATIONS_PER_PAGE;
                setTotalPages( absolutePages === Math.floor(absolutePages) ? absolutePages : Math.floor(absolutePages) + 1 );
            }
            else {
                setData(prevData => {
                    return [
                        ...data,
                        ...response.data
                    ]
                })
            };
            setLoading(false);
            setRefreshList(true);
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log("Cancelled applications list fetching");
            } else {
                console.log('Error fetching application list', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: "Failed to fetch applications, please try again"
                });
            }
        });
    }

    const handleDoubleClickRow = identifier => {
        getApplicationById(source, identifier, authToken)
        .then(app => {
            const dependents = [];
            const activeMembers = [];
            const primaryMember = app.members.find(m => m.relationshipTypeId === 'P');
            if(app.members.length > 0) {
                debugger;
                dependents.push({
                    firstName: primaryMember.firstName,
                    middleName: primaryMember.middleName,
                    lastName: primaryMember.lastName,
                    dateOfBirth: primaryMember.dateOfBirth,
                    gender: primaryMember.genderId,
                    phone: primaryMember.phoneNumber,
                    applicationId: app.applicationId,
                    memberId: primaryMember.memberId,
                    relationship: primaryMember.relationshipType,
                    effectiveDate: formatDate(primaryMember.createdOn),
                    isActive: primaryMember.isActive
                });

                activeMembers.push(primaryMember);
                
                // Members deactivated in the last 30 days do not pay reactivation fee
                app.members.filter(m => m.relationshipTypeId !== 'P').forEach(d => {
                    dependents.push({
                        firstName: d.firstName,
                        middleName: d.middleName,
                        lastName: d.lastName,
                        dateOfBirth: d.dateOfBirth,
                        gender: d.genderId,
                        phone: d.phoneNumber,
                        applicationId: app.applicationId,
                        memberId: d.memberId,
                        relationship: d.relationshipType,
                        reverseRelationship: d.reverseRelationshipType,
                        deactivationDate: formatDate(d.deactivationDate),
                        effectiveDate: formatDate(d.effectiveDate),
                        isActive: d.isActive
                    });

                    if((d.isActive === true || (d.deactivationDate && moment(d.deactivationDate).diff(moment(), 'days') <= 30))){
                        activeMembers.push(d);
                    }
                });
            }
            
            const formData = [{
                address: {
                    isValid: true,
                    value: {
                        addressLine1: app.addressLine1,
                        addressLine2: app.addressLine2 || "",
                        city: app.addressCity,
                        state: app.addressState,
                        zipCode: app.addressZip.toString()
                    }
                },
                primaryPhone: app.primaryPhoneNumber,
                emergencyPhone: app.emergencyPhoneNumber || "",
                email: app.eMail,
                center: app.primaryCenterId.toString(),
                firstName: primaryMember.firstName,
                lastName: primaryMember.lastName,
                middleName: primaryMember.middleName,
                dateOfBirth: primaryMember.dateOfBirth,
                gender: primaryMember.genderId,
                applicationId: app.applicationId,
                memberId: primaryMember.memberId,
                dayOfAutomaticPayment: app.automaticPaymentDate,
                currentDayOfAutomaticPayment: app.automaticPaymentDate,
                enableAutomaticPayments: app.automaticCharge,
                dependents: dependents,
                pendingCall: app.pendingCall,
                comments: app.comments,
                activeDependents: activeMembers,
                membershipId: app.membershipId,
                openInvoices: app.openInvoices,
                lastFeeChargedOn: app.lastFeeChargedOn
            }, 
            {
                planType: {
                    fee: app.planInfo.fee,
                    membershipTypeId: app.planInfo.membershipTypeId,
                    monthlyPayment: app.planInfo.monthlyPayment,
                    monthsPay: app.planInfo.monthsPay,
                    planDesc: app.planInfo.planDesc,
                    planInfoId: app.planInfo.planInfoId,
                    planTypeId: app.planInfo.planTypeId,
                    stripeProductId: app.planInfo.stripeProductId,
                    includesHospital: app.planInfo.includeHospital
                },
                membershipType: app.planInfo.membershipTypeId,
                originalMembershipType: app.planInfo.membershipTypeId,
                currentMembershipType: app.planInfo.membershipTypeId,
                currentPlanTypeId: app.planInfo.planTypeId,
                currentPlanType: {
                    fee: app.planInfo.fee,
                    membershipTypeId: app.planInfo.membershipTypeId,
                    monthlyPayment: app.planInfo.monthlyPayment,
                    monthsPay: app.planInfo.monthsPay,
                    planDesc: app.planInfo.planDesc,
                    planInfoId: app.planInfo.planInfoId,
                    planTypeId: app.planInfo.planTypeId,
                    stripeProductId: app.planInfo.stripeProductId,
                    basePlanTypeId: app.planInfo.basePlanTypeId,
                    addOnPlanTypeId: app.planInfo.addOnPlanTypeId,
                    addOnFee: app.planInfo.addOnFee,
                    addOnMonthsPaid: app.planInfo.addOnMonthsPaid,
                    addOnMonthlyPayment: app.planInfo.addOnMonthlyPayment,
                    includeHospital: app.planInfo.includeHospital
                },
                agentCode: app.agent.agentCode,
                oneTimeFee: app.planInfo.fee,
                monthlyFee: app.planInfo.monthlyPayment,
                currentMonthlyPayment: app.planInfo.monthlyPayment,
                totalPayment: 0,
                membershipEffectiveDate: app.startDate
            }, {
                enableAutomaticPayments: app.automaticCharge,
                isActive: app.isActive,
                generateInvoices: app.generateInvoices,
                currentDayOfAutomaticPayment: app.automaticPaymentDate,
                dayOfAutomaticPayment: app.automaticPaymentDate,//app.dateOfAutomaticPayment ? (app.dateOfAutomaticPayment < 10 ? '0' + app.dateOfAutomaticPayment : app.dateOfAutomaticPayment) : undefined,
                stripeCustomerId: app.stripeCustomerId,
                stripePaymentMethodId: app.stripePaymentMethodId,
                hasCardOnFile: app.stripeCustomerId && app.stripePaymentMethodId && app.stripePaymentMethodId.length > 0 && app.stripeCustomerId.length > 0,
                useCardOnFile: app.stripeCustomerId && app.stripePaymentMethodId && app.stripePaymentMethodId.length > 0 && app.stripeCustomerId.length > 0
            }];
            setIsApplicationDialogOpen(true);
            setApplicationDialogData(formData);
            setShouldRefresh(false);
            setIsApplicationProcessed(app.membershipId > 0 ? true : false);
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log("Cancelled fetching plications by id");
            } else {
                console.log('Error fetching application by id', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: error.message || "Failed to fetch membership information, please try again"
                });
            }
            setApplicationDialogData([]);
        })
    }

    const handleFilterChange = newFilterValues => {
        setFilerValues(prevValues => {
            return {
                ...prevValues,
                memberName: newFilterValues.memberName || undefined,
                applicationId: newFilterValues.applicationNumber || undefined,
                applicationDate: newFilterValues.applicationDate || undefined,
                planTypeId: newFilterValues.planTypeId ? newFilterValues.planTypeId : undefined,
                status: newFilterValues.status || undefined,
                membershipTypeId: newFilterValues.membershipTypeId || undefined,
                agentCode: newFilterValues.agentCode || undefined,
                isActive: newFilterValues.isActive || undefined,
                lastPaymentDate: newFilterValues.lastPaymentDate || undefined,
                memberAddress: newFilterValues.memberAddress || undefined,
                memberDOB: newFilterValues.memberDOB || undefined,
                membershipId: newFilterValues.membershipId || undefined,
                nextPaymentDate: newFilterValues.nextPaymentDate || undefined,
                paymentStatus: newFilterValues.paymentStatus || undefined,
                primaryCenterId: newFilterValues.primaryCenterId || undefined,
                stripeCustomerId: newFilterValues.stripeCustomerId || undefined,
                effectiveDate: newFilterValues.effectiveDate || undefined,
                pendingCall: newFilterValues.pendingCall === '' ? undefined : newFilterValues.pendingCall,
                generateInvoices: newFilterValues.generateInvoices === '' ? undefined : newFilterValues.generateInvoices
            }
        });
        debounceSetPageNumber();
    }    

    const closeAndSearch = () => {
        setIsApplicationDialogOpen(false);
        handleFilterChange(filterValues);
    }

    const handleManualPayment = (data) => {
        setIsPaymentDialogOpen(true);
        setManualPaymentData(data);
    }

    const handleGenerateInvoices = applicationId => {
        setIsGenerateInvoiceDialogOpen(true);
        setGenerateInvoiceData(applicationId);
    }

    const handleDownloadHistory = membershipId => {
        getHistoryReport(source, authToken, membershipId)
    }

    return <Grid container spacing={2} style={{width: '100%', padding: '20px'}}>
                <PageHeader 
                    pageTitle={"Members"}
                    customButtons={[<ExcelExporter fileName={'Members'} sheetName={'Members'}
                    columns={DEFAULT_MEMBER_LIST_HEADCELLS}
                    data={totalRecords} />]}
                />
                <Grid item xs={12}>
                    <MemberFilters
                        onFilterChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Table 
                        headCells={(isLimited(accountInfo) ? [...LIMITED_MEMBER_LIST_HEADCELLS] : [...DEFAULT_MEMBER_LIST_HEADCELLS])}
                        rows={data}
                        page={pageNumber}
                        totalPages={totalPages}
                        loading={loading}
                        rowTemplate={
                            <MemberRowTemplate 
                                handleDownloadHistory={handleDownloadHistory}
                                isElevatedPermissions={isElevatedPermissions(accountInfo)}
                                handleManualPayment={handleManualPayment}
                                handleGenerateInvoices={handleGenerateInvoices}
                            />
                        }
                        loadMoreRecords={loadMoreRecords}
                        identifier="applicationId"
                        handleSingleClickRow={() => console.log()}
                        handleDoubleClickRow={handleDoubleClickRow}
                        tableHeight={70}
                        handleRowsOrderChange={() => console.log()}
                        setRefreshTable={setRefreshList}
                        refreshTable={refreshList}
                        sortBy="applicationId"
                        sortOrder="asc"
                    />
                </Grid>
                <DialogBox 
                    openDialog={isApplicationDialogOpen}
                    onCloseBtnClicked={() => {
                        setApplicationDialogData([]);
                        setIsApplicationDialogOpen(false);

                        if(shouldRefresh){
                            closeAndSearch();
                        }                        
                    }}
                    dialogTemplate={<NewApplication setShouldRefresh={setShouldRefresh} formData={applicationDialogData} isApplicationProcessed={isApplicationProcessed} closeAndSearch={closeAndSearch}/>}
                    size="lg"
                />
                <DialogBox 
                    openDialog={isPaymentDialogOpen}
                    onCloseBtnClicked={() => {
                        setIsPaymentDialogOpen(false);
                        setManualPaymentData("")
                    }}
                    dialogTemplate={
                        <BillingInformationTemplate 
                            paymentData={manualPaymentData}
                            onClose={() => {
                                setIsPaymentDialogOpen(false);
                                debounceSetPageNumber();
                            }}
                        />
                    }
                    size="lg"
                />
                <DialogBox 
                    openDialog={isGenerateInvoiceDialogOpen}
                    onCloseBtnClicked={() => {
                        setIsGenerateInvoiceDialogOpen(false);
                        setGenerateInvoiceData("")
                    }}
                    dialogTemplate={
                        <GenerateInvoices 
                            applicationId={generateInvoiceData}
                            toggleDialogBox={() => setIsGenerateInvoiceDialogOpen(false)} 
                        />
                    }
                    size="sm"
                />
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </Grid>
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(MemberList)