const paymentStatus = [
    {
        label: 'Unpaid Invoices',
        value: 'Unpaid Invoices'
    },
    {
        label: 'All Paid',
        value: 'All Paid'
    },
    {
        label: 'Draft',
        value: 'Draft'
    }
]

export default paymentStatus;