import {useState, useCallback, useEffect} from 'react';
import SimpleGrid from "../../components/SimpleGrid/SimpleGrid";
import CommissionRowTemplate from "./CommissionRowTemplate";
import UpsertCommissionTemplate from "./UpsertCommissionTemplate";
import CommissionFilterTemplate from "./CommissionFilterTemplate";
import {getCommissionList} from "../../apiCalls/Commission/getCommissionList";
import {upsertCommission} from "../../apiCalls/Commission/upsertCommission";
import {getOptions} from "../../apiCalls/getOptions";
import axios from 'axios';
import {connect} from 'react-redux';

const DEFAULT_COMMISSION_LIST_HEADCELLS = [
    {id: 'agentTypeId', label: 'Agent Type', showColumn: true, sortable: 'true'},
    {id: 'planTypeId', label: 'Plan Type', showColumn: true, sortable: 'true'},
    {id: 'membershipTypeId', label: 'Membership Type', showColumn: true, sortable: 'true'},
    {id: 'oneTimePayment', label: 'One Payment', showColumn: true, sortable: 'true'},
    {id: 'residual', label: 'Residual', showColumn: true, sortable: 'true'},
    {id: 'isActive', label: 'Status', showColumn: true, sortable: 'true'},
    {id: 'startDate', label: 'Start Date', showColumn: true, sortable: 'true'},
    {id: 'endDate', label: 'End Date', showColumn: true, sortable: 'true'}
];

const DEFAULT_COMMISSION_FILTER_VALUES = {
    agentTypeId: '',
    planTypeId: '',
    membershipTypeId: ''
};

const DEFAULT_COMMISSIONS_PER_PAGE = 50;

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

const Commission = props => {

    const {accountInfo} = props;

    const [filterValues, setFilterValues] = useState({...DEFAULT_COMMISSION_FILTER_VALUES});
    const [filterCount, setFilterCount] = useState(0);
    const [refreshGrid, setRefreshGrid] = useState(false);
    const [upsertStatus, setUpsertStatus] = useState({});
    const [agentTypes, setAgentTypes] = useState([]);
    const [planTypes, setPlanTypes] = useState([]);
    const [membershipTypes, setMembershipTypes] = useState([]);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");
    const [totalRecords, setTotalRecords] = useState([]);
    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])


    const source = axios.CancelToken.source();

    useEffect(() => {
        if(authToken) {
            getOptions(axios.CancelToken.source(), 'agent-type', authToken)
            .then(response => {
                setAgentTypes(response.map(d => {
                    return {
                        label: d.label,
                        value: d.code
                    }
                }));
            });

            getOptions(axios.CancelToken.source(), 'plan-type', authToken)
            .then(response => {
                setPlanTypes(response.map(d => {
                    return {
                        label: d.label,
                        value: d.code
                    }
                }));
            });

            getOptions(axios.CancelToken.source(), 'membership-type', authToken)
            .then(response => {
                setMembershipTypes(response.map(d => {
                    return {
                        label: d.label,
                        value: d.code
                    }
                }));
            });

            getCommissionList(source, 0, 100000, {}, authToken).then((response) => setTotalRecords(response?.data || []));

        }
    }, [authToken]);

    const handleFilterChange = (key, value) => {
        setFilterValues(prevValues => {
            return {
                ...prevValues,
                [key]: value
            }
        });
    };

    const handleApplyFilter = () => {
        let count = 0;
        for(const field in filterValues) {
            if(filterValues[field]) count = count+1;
        }
        setFilterCount(count);
        setRefreshGrid(true)
    }

    const handleClearFilter = () => {
        setFilterValues({...DEFAULT_COMMISSION_FILTER_VALUES});
        setFilterCount(0);
        setRefreshGrid(true)
    }

    const handleGetData = useCallback(index => {
        return getCommissionList(source, index, DEFAULT_COMMISSIONS_PER_PAGE, filterValues, authToken);
    }, [filterValues, authToken])

    const handleUpsertData = (commissionRecord) => {
        upsertCommission(source, commissionRecord, authToken).then(response => {
            setNotification({
                open: true, 
                type: 'success',
                message: `Commission record ${commissionRecord.commissionId ? 'updated' : 'created'} successfully` 
            });
            setRefreshGrid(true);
        }).catch(error => {
            setNotification({
                open: true, 
                type: 'error',
                message: error?.response?.data?.error?.message
                    || `Failed to ${commissionRecord.commissionId ? 'update' : 'create'} commission record, please try again` 
                });
        });
    }

    return authToken && <SimpleGrid
                title="Commission"
                rowsPerPage={DEFAULT_COMMISSIONS_PER_PAGE}
                headCells={[...DEFAULT_COMMISSION_LIST_HEADCELLS]}
                getData={handleGetData}
                identifier="commissionId"
                rowTemplate={
                    <CommissionRowTemplate
                        agentTypes={agentTypes}
                        planTypes={planTypes}
                        membershipTypes={membershipTypes}
                    />
                }
                createTemplate={
                    <UpsertCommissionTemplate
                        data={null} 
                        onSave={centerRecord => handleUpsertData(centerRecord)}
                        upsertStatus={upsertStatus}
                        resetUpsertStatus={setUpsertStatus}
                        agentTypes={agentTypes}
                        planTypes={planTypes}
                        membershipTypes={membershipTypes}
                        notification={notification}
                        reset={() => setNotification({...DEFAULT_NOTIFICATION})}
                    />
                }
                editTemplate={
                    <UpsertCommissionTemplate
                        onSave={centerRecord => handleUpsertData(centerRecord)}
                        upsertStatus={upsertStatus}
                        resetUpsertStatus={setUpsertStatus}
                        agentTypes={agentTypes}
                        planTypes={planTypes}
                        membershipTypes={membershipTypes}
                        notification={notification}
                        reset={() => setNotification({...DEFAULT_NOTIFICATION})}
                    />
                }
                filterTemplate={
                    <CommissionFilterTemplate
                        filterValues={filterValues}
                        onFilterChange={handleFilterChange}
                        agentTypes={agentTypes}
                        planTypes={planTypes}
                        membershipTypes={membershipTypes}
                    />
                }
                onApplyFilter={handleApplyFilter}
                onClearFilter={handleClearFilter}
                filterCount={filterCount}
                refreshGrid={refreshGrid}
                resetRefreshGrid={() => setRefreshGrid(false)}
                upsertStatus={upsertStatus}
                resetUpsertStatus={setUpsertStatus}
                nextPreviousDialogHeight={320}
                allowCreate={true}
                exportToExcel={true}
                excelData= {{ columns: DEFAULT_COMMISSION_LIST_HEADCELLS, data: totalRecords }}
            />
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(Commission);