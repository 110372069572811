import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    Button,
    Typography,
    CircularProgress,
    AppBar,
    Grid,
    Paper
} from "@material-ui/core";
import Stepper from "../Stepper/Stepper";
import {checkEmailValidity} from "../../helperFunctions/checkEmailValidity";
import Notification from "../../components/Notification/Notification";
import {Delete, Cancel, Phone} from '@material-ui/icons';
import {checkCreditCardValidity} from "../../helperFunctions/checkCreditCardValidity";
import {checkCVVValidity} from "../../helperFunctions/checkCVVValidity";
import {saveCallMade} from "../../apiCalls/Application/saveCallMade"
import axios from 'axios';

const useStyles = makeStyles({
    appBar: {
        backgroundColor: "white",
        bottom: 0,
        padding:10,
        top:"auto"
    },
    appBarContent: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between'
    },
    stepContainer: {
        padding: '20px',
        height: 'auto'
    }
});

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const FormWizard = props => {
    const {steps, onSubmit, handleNextClick, allowAnotherSubmit, resetAllowAnotherSubmit, closeAndSearch,
            isApplicationProcessed, handleCancelDeleteApplication, cancelDeleteApplicationLoading, isReadOnly, canCancel, authToken} = props;    
    const classes = useStyles();

    const [data, setData] = useState(steps.map(i => i.data));
    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [clonedComponents, setClonedComponents] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [nextLoading, setNextLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [disableAllButtons, setDisableAllButtons] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [changes, setChanges]=useState([]);

    
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        setData(steps.map(i => i.data));
    }, [steps])

    useEffect(() => {
        if(allowAnotherSubmit) {
            setDisableSubmit(false);
            setSubmitLoading(false);
            setDisableAllButtons(false);
            if(resetAllowAnotherSubmit) resetAllowAnotherSubmit();    
        }
    }, [allowAnotherSubmit])

    useEffect(() => {
        const newErrors = {}
        for(let i = 0; i < steps.length; i++) {
            const activeStepData = data[i];
            for (let key in errors) {
                if (activeStepData[key]) {
                    newErrors[key] = errors[key];
                }
            }

            for (let key in activeStepData) {
                if (activeStepData[key].required) {
                    if (Array.isArray(activeStepData[key].value)) {
                        if (activeStepData[key].value.length < 1) {
                            newErrors[key] = 'Field is required!';
                        } else {
                            delete newErrors[key];
                        }
                    } else if (activeStepData[key].value === null || 
                                activeStepData[key].value.toString().trim().length === 0 ||
                                (key === 'address' && typeof activeStepData[key].value === 'object' && !activeStepData[key].value.isValid) ||
                                (key === 'email' && !checkEmailValidity(activeStepData[key].value))
                                ) {
                        newErrors[key] = 'Field is required!';
                    }
                    else if ( key === 'cardNumber' && !checkCreditCardValidity(activeStepData[key].value) ) {
                        newErrors[key] = 'Invalid CC number';
                    } else if ( key === 'cardCVVNumber' && !checkCVVValidity(activeStepData['cardNumber'].value, activeStepData[key].value) ) {
                        newErrors[key] = 'Invalid CVV';
                    } 
                    else {
                        delete newErrors[key];
                    }
                }
            }
        }
        if(Object.keys(newErrors).length > 0) setDisableSubmit(true);
        else setDisableSubmit(false);

    }, [data, steps, errors]);

    useEffect(() => {
        const activeStepData = data[activeStep];
        const newErrors = {};

        for (let key in errors) {
            if (activeStepData[key]) {
                newErrors[key] = errors[key];
            }
        }

        for (let key in activeStepData) {
            if (activeStepData[key].required) {
                if (Array.isArray(activeStepData[key].value)) {
                    if (activeStepData[key].value.length < 1) {
                        newErrors[key] = 'Field is required!';
                    } else {
                        delete newErrors[key];
                    }
                } else if (activeStepData[key].value === null || 
                        activeStepData[key].value.toString().trim().length === 0 ||
                        (key === 'address' && typeof activeStepData[key].value === 'object' && !activeStepData[key].value.isValid) ||
                        (key === 'email' && !checkEmailValidity(activeStepData[key].value))
                    ) {
                        newErrors[key] = 'Field is required!';
                }
                else if ( key === 'cardNumber' && !checkCreditCardValidity(activeStepData[key].value) ) {
                    newErrors[key] = 'Invalid CC number';
                } else if ( key === 'cardCVVNumber' && !checkCVVValidity(activeStepData['cardNumber'].value, activeStepData[key].value) ) {
                    newErrors[key] = 'Invalid CVV';
                } 
                else {
                    delete newErrors[key];
                }
            }
        }

        setErrors(newErrors);

    }, [data, activeStep]);

    useEffect(() => {
        console.log(steps) 
        console.log(errors) 
        console.log(cancelDeleteApplicationLoading) 
        console.log(nextLoading) 
        console.log(submitLoading)
        console.log(disableAllButtons)

        const arr = steps.map((step, i) => {
            return React.cloneElement(step.component,
                {
                    isReadOnly: isReadOnly,
                    data: data,
                    stepNum: i,
                    activeStep: activeStep,
                    errors: errors,
                    setErrors: setErrors,
                    hasError: hasError,
                    getErrorMessage: getErrorMessage,
                    onChange: handleFieldChange,
                    onRequiredChange: handleRequiredChange
                });
        });
        setClonedComponents(arr);
    }, [data, steps, errors]);

    const handleStepBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleFieldChange = (field, value, stepIndex) => {
        setData(prevState => {
            const changeIdx = changes.findIndex(c => c.stepIndex === (stepIndex || activeStep) && c.fieldName === field);
            return prevState.map((step, i) => {
                if (((stepIndex && stepIndex === i) || activeStep === i) && step[field]) {   
                    const newChanges = [
                        ...changes.slice(0, changeIdx > -1 ? changeIdx : changes.length)
                    ];
                    
                    if(step[field].originalValue && JSON.stringify(step[field].originalValue) !== JSON.stringify(value)){
                        newChanges.push({
                            stepIndex : i,
                            fieldName : field
                        })
                    }

                    newChanges.concat([
                        ...changes.slice(changeIdx > -1 ? changeIdx + 1 : 0),
                    ]);

                    setChanges(newChanges);

                    return {
                        ...step,
                        [field]: {
                            ...step[field],
                            value: value,
                            hasChanged: step[field].originalValue && step[field].originalValue !== value
                        }
                    }
                }
                return step;
            });
        });
    };

    useEffect(() => {
        setHasChanged(changes.length > 0)
    }, [changes])

    const handleCallMade = () => {
        let applicationId = data[0].applicationId.value;
        saveCallMade(source, applicationId, authToken)
            .then(response => {
                setNotification({
                    open: true, 
                    type: 'success',
                    message: `Change submitted successfully` 
                });
            })
            .catch(error => {
                console.log('Failed to submit change', error)                
                setNotification({
                    open: true,
                    type: "error",
                    message: `Failed to save application update call made flag`
                });
            });
    }

    const handleRequiredChange = (field, value, stepIndex) => {
        setData(prevState => {
            return prevState.map((step, i) => {
                if (((stepIndex && stepIndex === i) || activeStep === i) && step[field]) {
                    return {
                        ...step,
                        [field]: {
                            ...step[field],
                            required: value
                        }
                    }
                }
                return step;
            });
        });
    }

    const handleStepClick = (index) => {
        if (!disableSubmit && !Object.keys(errors).length > 0) {
            setActiveStep(index)
        }
    }

    const handleSubmit = () => {
        setDisableAllButtons(true);
        const result = {};
        data.forEach(arr => {
            for (let key in arr) {
                        result[key] = arr[key].value;
                    } 
        });

        setDisableSubmit(true);
        setSubmitLoading(true);
        onSubmit(result);
    };

    const hasError = (key) => {
        if (errors[key]) {
            return true;
        }
        return false;
    };

    const getErrorMessage = (key) => {
        if (errors[key]) {
            return errors[key];
        }
        return null;
    };

    const onCancelDeleteClick = () => {
        if(data && data.length) {
            
            handleCancelDeleteApplication(data[0].applicationId.value)
            
        }
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <form autoComplete="off">
                    <Stepper
                        steps={steps}
                        activeStep={activeStep}
                        onStepClick={handleStepClick}
                    >
                        {
                            clonedComponents.map((component, index) => {
                                return <Typography component='div'
                                                   key={'stepComponent-' + index}
                                                   hidden={index !== activeStep}
                                                   style={{padding: '20px'}}
                                >
                                    <Paper elevation={3} className={classes.stepContainer}>{component}</Paper>
                                </Typography>
                            })
                        }
                    </Stepper>
                </form>
            </Grid>
            <Grid item xs={12}>
                <AppBar position="static" color="primary" className={classes.appBar}>
                    <div className={classes.appBarContent}>
                        <span>                            
                            {isReadOnly || !canCancel ? null : <Button variant="outlined"
                                style={{marginLeft: 5}}
                                onClick={onCancelDeleteClick}
                                startIcon={isApplicationProcessed ? <Cancel/> : <Delete/>}
                                disabled={cancelDeleteApplicationLoading || nextLoading || submitLoading || (!data[2]?.generateInvoices.value)}
                            >
                                {isApplicationProcessed ? 'Deactivate' : 'Delete'}
                                {
                                    cancelDeleteApplicationLoading ?
                                    <CircularProgress style={{color: 'black', width: 14, height: 14, marginRight: 5}}/> :
                                    null
                                }
                            </Button>}
                            {data[0] && data[0].pendingCall?.value === true ?
                                <Button variant="outlined"
                                    style={{marginRight: 5}}                                    
                                    onClick={handleCallMade}
                                    startIcon={<Phone/>}
                                >
                                    Call Completed
                                </Button>
                                : null
                            } 
                        </span>
                        <span>                            
                            <Button variant="outlined"
                                style={{marginRight: 5}}
                                disabled={activeStep === 0 || cancelDeleteApplicationLoading || nextLoading || submitLoading || disableAllButtons}
                                onClick={handleStepBack}                                
                            >
                                Back
                            </Button>
                            <Button variant="contained"
                                style={{marginRight: 5}}
                                color="primary"
                                onClick={handleStepNext}
                                disabled={activeStep === steps.length - 1 || Object.keys(errors).length > 0 || 
                                            cancelDeleteApplicationLoading || nextLoading || submitLoading || disableAllButtons}
                            >
                                {
                                    nextLoading ?
                                        <CircularProgress
                                            style={{color: 'white', width: 14, height: 14, marginRight: 5}}/>
                                        :
                                        null
                                }
                                Next
                            </Button>

                            {isReadOnly === true ? null : <Button variant="contained"
                                style={{marginRight: 5, color: 'white'}}
                                color="secondary"
                                onClick={handleSubmit}
                                disabled={disableSubmit || Object.keys(errors).length > 0 || cancelDeleteApplicationLoading 
                                            || nextLoading || submitLoading || disableAllButtons || (data[1].requiresThirdStep.value === true && activeStep !== 2)}
                            >
                                {
                                    submitLoading ?
                                        <CircularProgress
                                            style={{color: 'white', width: 14, height: 14, marginRight: 5}}/>
                                        :
                                        null
                                }
                                Submit
                            </Button>}
                        </span>
                    </div>
                </AppBar>
            </Grid>
            <Notification
                open={notification.open}
                type={notification.type}
                message={notification.message}
                onClose={() => setNotification({...DEFAULT_NOTIFICATION})}
            />
        </Grid>
    );
};

FormWizard.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
    handleNextClick: PropTypes.func,
    allowAnotherSubmit: PropTypes.bool,
    resetAllowAnotherSubmit: PropTypes.func,
    cancelDeleteApplicationLoading: PropTypes.bool.isRequired
};

export default FormWizard;
