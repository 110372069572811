import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { useState } from 'react';

import { PayeeEditor } from './PayeeEditor';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(0),
      size: 20,
    },   
    right: {
        marginLeft: 'auto',
        fullWidth: false,
        width: 20
    },
    extendedIcon: {
      marginRight: theme.spacing(0),
    },
    typography: {
      padding: theme.spacing(1),
    },
  }));


  const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};
  
function InvoiceManager(props) {
    const {invoice, accountInfo} = props;
    const classes = useStyles(props);
    const [authToken, setAuthToken] = useState('');

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])
    
    return <div width={'90%'}>
        <PayeeEditor accountInfo={accountInfo} authToken={authToken}></PayeeEditor>        
    </div>
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(InvoiceManager)