import {useState, useEffect, useCallback} from 'react';
import Table from "../../components/Table/Table";
import ApplicationRowTemplate from "./ApplicationRowTemplate";
import { formatDate } from '../../utils/DateHandling';
import {
    Grid,
    Typography
} from '@material-ui/core';
import ApplicationFilters from "./ApplicationFilters";
import {getApplicationList} from "../../apiCalls/Application/getApplicataionList";
import {debounce} from 'lodash';
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import {getApplicationById} from "../../apiCalls/Application/getApplicationById";
import DialogBox from "../../components/DialogBox/DialogBox";
import NewApplication from "../NewApplication/NewApplication";
import {connect} from 'react-redux';

const DEFAULT_MEMBER_LIST_HEADCELLS = [
    {id: 'applicationId', label: 'Application ID', showColumn: true, sortable: 'true'},
    {id: 'startDate', label: 'Start Date', showColumn: true, sortable: 'true'},
    {id: 'memberName', label: 'Member Name', showColumn: true, sortable: 'true'},    
    {id: 'planTypeDesc', label: 'Type of Plan', showColumn: true, sortable: 'true'},
    {id: 'membershipTypeDesc', label: 'Type of Member', showColumn: true, sortable: 'true'},
    {id: 'primaryPhoneNumber', label: 'Phone', showColumn: true, sortable: 'true'},
    {id: 'eMail', label: 'Email', showColumn: true, sortable: 'true'},
    {id: 'status', label: 'Status', showColumn: true, sortable: 'true'},
    {id: 'primaryCenterId', label: 'Center', showColumn: true, sortable: 'true'}
];

const DEFAULT_APPLICATIONS_PER_PAGE = 50;

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
}

const MemberList = (props) => {

    const {accountInfo} = props;

    const [refreshList, setRefreshList] = useState(false);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filterValues, setFilerValues] = useState({});
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
    const [dialogData, setDialogData] = useState([]);
    const [isApplicationProcessed, setIsApplicationProcessed] = useState(false);
    const [authToken, setAuthToken] = useState("");

    const debounceSetPageNumber = useCallback(debounce(() => setPageNumber(0), 1000), []);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if(pageNumber === 0) {
            return setPageNumber(1);
        }
        if(authToken) fetchApplicationList(authToken);

        return () => source.cancel();
    }, [pageNumber, authToken]);

    const loadMoreRecords = () => {
        setLoading(true);
        if(pageNumber < totalPages) {
            setPageNumber(prevPageNumber => prevPageNumber+1);
        }
    }

    const fetchApplicationList = (aT) => {
        setLoading(true);
        getApplicationList(source, ((pageNumber-1) * DEFAULT_APPLICATIONS_PER_PAGE),  DEFAULT_APPLICATIONS_PER_PAGE, filterValues, aT)
        .then(response => {
            if(pageNumber === 1) {
                setData(response.data);
                const absolutePages = Number(response.count) / DEFAULT_APPLICATIONS_PER_PAGE;
                setTotalPages( absolutePages === Math.floor(absolutePages) ? absolutePages : Math.floor(absolutePages) + 1 );
            }
            else {
                setData(prevData => {
                    return [
                        ...data,
                        ...response.data
                    ]
                })
            };
            setLoading(false);
            setRefreshList(true);
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log("Cancelled applications list fetching");
            } else {
                console.log('Error fetching application list', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: "Failed to fetch applications, please try again"
                });
            }
        });
    }

    const closeAndSearch = () => {
        setIsDialogBoxOpen(false);
        debounceSetPageNumber();
    }

    const handleDoubleClickRow = identifier => {
        getApplicationById(source, identifier, authToken)
        .then(app => {
            const dependents = [];
            const activeMembers = [];
            if(app.members.length > 1) {
                for(let i = 0; i < app.members.length; i++) {
                    //if(app.members[i].relationshipTypeId !== 'P') {
                        dependents.push({
                            firstName: app.members[i].firstName,
                            lastName: app.members[i].lastName,
                            dateOfBirth: app.members[i].dateOfBirth,
                            gender: app.members[i].genderId,
                            phone: app.members[i].phoneNumber,
                            applicationId: app.applicationId,
                            memberId: app.members[i].memberId,
                            relationship: app.members[i].relationshipType,
                            effectiveDate: formatDate(app.members[i].createdOn),
                            isActive: app.members[i].isActive
                        });
                    //}

                    if(app.members[i].isActive === true){
                        activeMembers.push(app.members[i]);
                    }
                }
            }

            const primaryMember = app.members.filter(m => m.relationshipTypeId === 'P');
            const formData = [{
                address: {
                    isValid: true,
                    value: {
                        addressLine1: app.addressLine1,
                        addressLine2: app.addressLine2 || "",
                        city: app.addressCity,
                        state: app.addressState,
                        zipCode: app.addressZip.toString()
                    }
                },
                primaryPhone: app.primaryPhoneNumber,
                emergencyPhone: app.emergencyPhoneNumber || "",
                email: app.eMail,
                center: app.primaryCenterId.toString(),
                firstName: primaryMember[0].firstName,
                lastName: primaryMember[0].lastName,
                dateOfBirth: primaryMember[0].dateOfBirth,
                gender: primaryMember[0].genderId,
                applicationId: app.applicationId,
                memberId: primaryMember[0].memberId,
                dependents: dependents,
                comments: app.comments,
                activeDependents: activeMembers,
                membershipId: app.membershipId
            }, 
            {
                planType: {
                    fee: app.planInfo.fee,
                    membershipTypeId: app.planInfo.membershipTypeId,
                    monthlyPayment: app.planInfo.monthlyPayment,
                    monthsPay: app.planInfo.monthsPay,
                    planDesc: app.planInfo.planDesc,
                    planInfoId: app.planInfo.planInfoId,
                    planTypeId: app.planInfo.planTypeId,
                    stripeProductId: app.planInfo.stripeProductId
                },
                membershipType: app.planInfo.membershipTypeId,
                originalMembershipType: app.planInfo.membershipTypeId,
                oneTimeFee: app.planInfo.fee,
                monthlyFee: app.planInfo.monthlyPayment,
                totalPayment: 0, //( app.planInfo.fee + (app.planInfo.monthlyPayment * app.planInfo.monthsPay) ).toFixed(2),
                membershipEffectiveDate: app.startDate
            }
        ];
            setIsDialogBoxOpen(true);
            setDialogData(formData);
            setIsApplicationProcessed(app.membershipId > 0 ? true : false);
        })
        .catch(error => {
            if (axios.isCancel(error)) {
                console.log("Cancelled fetching plications by id");
            } else {
                console.log('Error fetching application by id', error);
                setNotification({
                    open: true,
                    type: "error",
                    message: "Failed to fetch application in draft, please try again"
                });
            }
            setDialogData([]);
        })
    }

    const handleFilterChange = newFilterValues => {
        setFilerValues(prevValues => {
            return {
                ...prevValues,
                memberName: newFilterValues.memberName || undefined,
                applicationId: newFilterValues.applicationNumber || undefined,
                applicationDate: newFilterValues.applicationDate || undefined,
                planTypeId: newFilterValues.planType && newFilterValues.planType.planTypeId ? newFilterValues.planType.planTypeId : undefined,
                status: newFilterValues.status || undefined,
                membershipTypeId: newFilterValues.membershipType || undefined
            }
        });
        debounceSetPageNumber();
    }

    return <Grid container spacing={2} style={{width: '100%', padding: '20px'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">Applications</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ApplicationFilters
                        onFilterChange={handleFilterChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Table 
                        headCells={[...DEFAULT_MEMBER_LIST_HEADCELLS]}
                        rows={data}
                        page={pageNumber}
                        totalPages={totalPages}
                        loading={loading}
                        rowTemplate={<ApplicationRowTemplate/>}
                        loadMoreRecords={loadMoreRecords}
                        identifier="applicationId"
                        handleSingleClickRow={() => console.log()}
                        handleDoubleClickRow={handleDoubleClickRow}
                        tableHeight={70}
                        handleRowsOrderChange={() => console.log()}
                        setRefreshTable={setRefreshList}
                        refreshTable={refreshList}
                        sortBy="applicationId"
                        sortOrder="asc"
                    />
                </Grid>
                <DialogBox 
                    openDialog={isDialogBoxOpen}                    
                    onCloseBtnClicked={() => {
                        setDialogData([]);
                        setIsDialogBoxOpen(false);
                    }}
                    dialogTemplate={<NewApplication formData={dialogData} closeAndSearch={closeAndSearch}/>}
                    size="lg"
                />
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </Grid>
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(MemberList)