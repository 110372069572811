import React, { useEffect } from 'react';
import axios from 'axios';
import {List, IconButton} from '@material-ui/core';
import { useState } from 'react';
import { LocalAtm } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Checkbox, FormControlLabel, Button, CircularProgress} from '@material-ui/core';
import { string } from 'prop-types';
import {getPayeeDetails} from '../../apiCalls/Invoices/getPayeeDetails'
import { NumericSearchTextBox } from '../../components/Search/NumericSearchTextBox'
import TextField from "../../components/FormInputs/TextField/TextField"
import {connect} from 'react-redux';

import AttemptList from './AttemptList';
import InvoiceList from './InvoiceList';
import MembershipTable from './MembershipTable';
import NativeDialogBox from '../../components/DialogBox/DialogBox';
import InvoiceEditor from './InvoiceEditor';
import AttemptEditor from './AttemptEditor';
import ListSkeleton from './ListSkeleton'
import Notification from "../../components/Notification/Notification";
import {COLOR_PALETTE} from "../../config/colorPalette";
import { isLimited, isElevatedPermissions } from '../../utils/MuiConstants';
import NewInvoice from '../../features/Invoice/NewInvoice';

const DEFAULT_NOTIFICATION = {
    open: false
}

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0),
        size: 20,
    },   
    right: {
        marginLeft: 'auto',
        fullWidth: false,
        width: 20
    },
    extendedIcon: {
        marginRight: theme.spacing(0),
    },
    typography: {
        padding: theme.spacing(1),
    },
    container:{        
        width: '100%',                
        padding: '10px',
    },
}))

const INITIAL_STATE = {
    membershipId : undefined,
    isCorporate: false,
    deleted: false,
    stripeCustomerId : undefined,
    stripePaymentMethodId : undefined,
    name: undefined,
    email: undefined,
    primaryPhoneNumber: undefined,
    automaticCharge: false,
    automaticPaymentDay: undefined,
    generateInvoices: false,
    comments: undefined,    
    memberships: [],
    invoices: [],
    invoiceAttempts: [],
    attempts: []
}

export function PayeeEditor(props) {
    const {accountInfo, attempt, authToken} = props;
    const classes = useStyles(props);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});  

    const [hasNotChanged, setHasNotChanged] = useState(true)
    const [enabled, setEnabled] = useState(false);
    const [errorState, setErrorState] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(INITIAL_STATE);    
    const [allowSearch, setAllowSearch] = useState(true);

    const [currentInvoice, setCurrentInvoice] = useState(undefined);
    const [isInvoiceEditorOpen, setIsInvoiceEditorOpen] = useState(false);

    const [currentAttempt, setCurrentAttempt] = useState(undefined);
    const [isAttemptEditorOpen, setIsAttemptEditorOpen] = useState(false);
    const [loadRelated, setLoadRelated] = useState(false);

    const [isNewInvoiceOpen, setIsNewInvoiceOpen] = useState(false);

    const handleFormDataChange = (element, val) => {
        if(element === 'loadRelated'){
            setLoadRelated(!loadRelated);
            return;            
        }

        setData({...data, [element]: val});
        setHasNotChanged(false);
    }

    const setPayeeId = (val) => {
        handleFormDataChange('membershipId', val)
    }

    const preparePayeeDataForViewing = (response) => {
        setData(response);
        setHasNotChanged(true);
        setEnabled(response?.membershipId > 0)
        setErrorState(false)
    }

    const loadPayeeInformation = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();        

        if(authToken && data?.membershipId) {
            setLoading(true);
            
            setIsAttemptEditorOpen(false);
            setIsInvoiceEditorOpen(false);
            setIsNewInvoiceOpen(false);
            
            setData(INITIAL_STATE);
            handleFormDataChange('memberships', [])

            getPayeeDetails((loadRelated ? data?.membershipId : -data?.membershipId), source, authToken, 1)
            .then(response => {        
                preparePayeeDataForViewing(response.data)                
                setLoading(false);
                setAllowSearch(true);                
            })
            .catch(error => {
                setPayeeId(undefined)
                if (axios.isCancel(error)) {
                    console.log("Cancelled Payee fetching");
                } else {
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: error.message ? error.message : "Failed to fetch Payee details, please try again"
                    });
                }
            });
        }

        return () => source.cancel();
    }

    if(isElevatedPermissions(accountInfo)){
        return (<Grid container spacing={2} className={classes.container}> 
            <Grid item xs={6}>
                <Grid container spacing={2}>
                    <Grid item xs={5} >               
                        <NumericSearchTextBox label="Membership Id"
                            value={data?.membershipId}
                            onChange={setPayeeId}
                            required={true}
                            onSearchClick={() => loadPayeeInformation()}
                            disabled={!allowSearch}>
                        </NumericSearchTextBox>                
                    </Grid> 
                    <Grid item xs={7}>
                        <FormControlLabel 
                            control={<Checkbox disabled={!allowSearch} 
                            onChange={val => handleFormDataChange('loadRelated', val)} 
                            checked={loadRelated === true}/>} 
                            label="Load related memberships (corporate)" />
                    </Grid>
                    <Grid item xs={7} style={{paddingLeft: '8px'}}>
                        <TextField label="Name"
                            value={data?.name}
                            disallowFullWidth={false}
                            onChange={val => handleFormDataChange('name', val)}
                            required={true}                  
                            disabled={true}>                
                        </TextField>
                    </Grid>
                    <Grid item xs={5} >
                        <TextField label="Phone Number"
                            value={data?.primaryPhoneNumber}
                            disallowFullWidth={false}
                            onChange={val => handleFormDataChange('name', val)}
                            required={true}                  
                            disabled={true}>                
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>                  
                <Grid item xs={1}>
                    <IconButton disabled={loading || !data?.membershipId} color="primary" 
                        aria-label="delete" id="memberships-state-save-btn"
                        onClick={(event) => {
                            setIsNewInvoiceOpen(true)
                        }}>
                        <LocalAtm/>
                    </IconButton>
                </Grid>              
            </Grid>
            <Grid item xs={12}>
                <MembershipTable onNotify={setNotification} authToken={authToken} parentLoadingState={loading} dataFromParent={data?.memberships}></MembershipTable>
            </Grid>
            <Grid item xs={6} >                    
                {loading ? <ListSkeleton></ListSkeleton> : <InvoiceList 
                    isCorporate={data?.isCorporate}
                    authToken={authToken}
                    invoices={data?.invoices}      
                    invoiceAttempts={data?.invoiceAttempts}
                    currentInvoiceFromParent={currentInvoice}
                    currentAttempt={currentAttempt}
                    onClearAttempt={() => setCurrentAttempt(undefined)}              
                    onClick={(invoiceId) => setCurrentInvoice(invoiceId)}
                    onDoubleClick={(invoiceId) => {                        
                        setIsInvoiceEditorOpen(true);
                        setCurrentInvoice(invoiceId);
                    }}
                    onSave={() => loadPayeeInformation()}
                />}
            </Grid>
            <Grid item xs={6}>
                {loading ? <ListSkeleton></ListSkeleton> :<AttemptList 
                        authToken={authToken}
                        attempts={data?.attempts}
                        invoiceAttempts={data?.invoiceAttempts}
                        currentInvoice={currentInvoice}
                        currentAttemptFromParent={currentAttempt}
                        onClearInvoice={() => setCurrentInvoice(undefined)}
                        onClick={(attemptId) => setCurrentAttempt(attemptId)}
                        onDoubleClick={(attemptId) => {                        
                            setIsAttemptEditorOpen(true);
                            setCurrentAttempt(attemptId);
                        }}></AttemptList>}
            </Grid>
            <NativeDialogBox
                openDialog={isInvoiceEditorOpen}
                onCloseBtnClicked={() => {
                    setIsInvoiceEditorOpen(false);
                }}
                dialogTemplate={
                    <div style={{padding: '10px'}}>
                        <InvoiceEditor onSave={() => loadPayeeInformation()} invoice={currentInvoice} authToken={authToken}/>
                    </div>
                }
                size="sm">            
            </NativeDialogBox>
            <NativeDialogBox
                openDialog={isNewInvoiceOpen}                
                onCloseBtnClicked={() => {
                    setIsNewInvoiceOpen(false);
                }}
                dialogTemplate={
                    <div style={{padding: '10px'}}>
                        <NewInvoice onSave={() => loadPayeeInformation()}                                     
                                    membershipId={data?.membershipId}
                                    authToken={authToken}/>
                    </div>
                }
                size="sm">            
            </NativeDialogBox> 
            <NativeDialogBox
                openDialog={isAttemptEditorOpen}
                onCloseBtnClicked={() => {
                    setIsAttemptEditorOpen(false);
                }}
                dialogTemplate={
                    <div style={{padding: '10px'}}>
                        <AttemptEditor onSave={() => loadPayeeInformation()} attempt={currentAttempt} authToken={authToken}/>
                    </div>
                }
                size="sm">            
            </NativeDialogBox>                     
            <Notification
                        open={notification.open}
                        type={notification.type}
                        message={notification.message}
                        onClose={() => {
                            setLoading(false);
                            setNotification({...DEFAULT_NOTIFICATION});   
                            loadPayeeInformation()                     
                        }}
                    />
        </Grid>) 
    }
    else { 
    return (<Grid container alignItems="center" direction="column" style={{padding: '20px'}}>
        <Grid item xs={12} style={{color: COLOR_PALETTE.orange.opacity100}}>
            <h2>Only Backoffice Management staff are allowed to modify Financial Information!</h2>
        </Grid>
    </Grid>)}
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(PayeeEditor)