import {useState, useEffect} from 'react';
import {Grid, Typography} from '@material-ui/core';
import axios from 'axios';
import {connect} from 'react-redux';
import BillingInformationTemplate from "../Member/Billing/BillingInformationTemplate";
import {getApplicationList} from "../../apiCalls/Application/getApplicataionList";

const ManualBilling = props => {
    const {accountInfo} = props;
    const [authToken, setAuthToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [paymentData, setPaymentData] = useState(null);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    const handleGetMembershipById = (val) => {
        if (val) {
            setLoading(true);
            getApplicationList(source, 0, 1, { membershipId: Number(val) }, authToken)
            .then(response => {
               if (response?.data?.[0]) {
                   setPaymentData(response?.data?.[0])
               } else {
                   setPaymentData(null)
               }
               setLoading(false);
            })
            .catch(error => {
                setPaymentData(null);
                setLoading(false);
            });
        }
    }

    return <Grid container spacing={2} style={{width: '100%', padding: '20px'}}>
                <Grid item xs={12}>
                    <Typography variant="h5">Manual Billing</Typography>
                </Grid>
                <Grid item xs={12}>
                    <BillingInformationTemplate
                        showEditMembershipIcon={true}
                        getMembershipById={handleGetMembershipById}
                        loading={loading}
                        paymentData={paymentData}
                    />
                </Grid>
            </Grid>
}

const mapStateToProps = state => {
    return {
        accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(ManualBilling)
