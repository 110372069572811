import {useState, useEffect} from 'react';
import AutocompleteDropdown from "../../../components/FormInputs/AutocompleteDropdown/AutocompleteDropdown";
import {getOptions} from "../../../apiCalls/getOptions";
import axios from 'axios';
import PropTypes from "prop-types";
import Notification from "../../../components/Notification/Notification";
import {connect} from 'react-redux';

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
}

const SmartDropDown = props => {

    const {label, value, onChange, required, disabled, error, helperText, category, menuItems, accountInfo} = props;
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [authToken, setAuthToken] = useState("");

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }
    }, [accountInfo])

    useEffect(() => {
        if (menuItems) {
            setOptions(menuItems);
        } else {
            if(authToken) {
                setLoading(true)
                getOptions(axios.CancelToken.source(), category, authToken)
                .then(response => {
                    setOptions(response.map(d => {
                        return {
                            label: d.label,
                            value: d.code
                        }
                    }));
                    
                    setLoading(false);
                })
                .catch(error => {
                    console.log(`Failed to fetch ${category} type options`, error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: `Failed to fetch ${category} type options, please try again`
                    });
                });
            }
        }
    }, [authToken]);

    useEffect(() => {
        if (menuItems?.length > 0) {
            setOptions(menuItems);
        }
    }, [menuItems])

    return <>
                <AutocompleteDropdown
                    label={label}
                    options={options}
                    value={value}
                    loading={loading}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    error={error}
                    helperText={helperText} 
                />
                <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        setNotification({...DEFAULT_NOTIFICATION})
                    }}
                />
            </>
}

SmartDropDown.propTypes = {
    label: PropTypes.string.isRequired, 
    value: PropTypes.string, 
    onChange: PropTypes.func.isRequired, 
    required: PropTypes.bool,
    disabled: PropTypes.bool, 
    error: PropTypes.bool, 
    helperText: PropTypes.string
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(SmartDropDown);

