export default function fromEmptyStringToNull(obj, exclude){
    exclude = exclude && Array.isArray(exclude) ? exclude : [];
    const keys = Object.keys(obj);
    keys.forEach(k => {
        if(obj[k] === '' && !exclude.includes(k)){
            obj[k] = null;
        }
    });

    return obj;
}

export const ONE_TIME_FEE = 30;