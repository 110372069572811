import * as React from 'react';
import {IconButton, Collapse} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from './ConfirmDialog';

export default function DeleteButton(props) {
    const {disabled, confirmationMessage, confirmationHeader, onConfirmAction, onCancelAction, children, onDeleteClickAction} = props;

    const [open, setOpen] = React.useState(false);

    const handleClick = (newState) => () => {
        setOpen(true);
    };

    const handleConfirm = () => {
        setOpen(false);
        if(onConfirmAction){
            onConfirmAction();
        }
    };

    return (
        <div>
            <IconButton disabled={disabled} aria-label="delete" 
                onFocus={(event) => event.stopPropagation()}
                onClick={(event) => {
                    setOpen(true);
                    if(onDeleteClickAction) {
                        onDeleteClickAction()
                    }
                    event.stopPropagation()
                }}>
                <DeleteIcon />
            </IconButton>
            <ConfirmDialog
                title={confirmationHeader}
                message={confirmationMessage}
                open={open}
                setOpen={() => setOpen(false)}
                onConfirm={() => handleConfirm()}
                onCancel={onCancelAction}
            >
                {children}
            </ConfirmDialog>
        </div>)
}