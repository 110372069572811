import {useEffect, useState} from 'react';
import {
    Grid
} from '@material-ui/core';
import TextField from "../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import PlanInfoSmartDropDown from "../common/PlanInfoSmartDropDown/PlanInfoSmartDropDown";
import MembershipTypeSmartDropDown from "../common/MembershipTypeSmartDropDown/MembershipTypeSmartDropDown";
import AgentSmartDropDown from "../common/AgentSmartDropDown/AgentSmartDropDown";
import moment from 'moment'
import { ONE_TIME_FEE } from '../../utils/DataManipulation';
import Notification from "../../components/Notification/Notification";
import {HOSPITAL_UPGRADE_FEE} from '../../constants/default'

const StepTwo = (props) => {
    const {data, stepNum, hasError, getErrorMessage, onChange, onRequiredChange} = props;
    const {planType, membershipType, currentMembershipType, currentPlanTypeId, currentPlanType, 
            oneTimeFee, monthlyFee, totalPayment, membershipEffectiveDate, agentCode, currentMonthlyPayment, lastFeeChargedOn} = data[stepNum];
    const {dependents, activeDependents, openInvoices} = data[0];
    const {dayOfAutomaticPayment, generateInvoices} = data[2];
    const {hasElevatedPermissions} = data[1];

    const [isMessageOpen, setIsMessageOpen] = useState(false)

    useEffect(() => {
        if(data[2].enableForm.value) {
            onRequiredChange('nameOnTheCard', !data[2].useCardOnFile.value, 2);
            onRequiredChange('cardNumber', !data[2].useCardOnFile.value, 2);
            onRequiredChange('cardExpiryDate', !data[2].useCardOnFile.value, 2);
            onRequiredChange('cardCVVNumber', !data[2].useCardOnFile.value, 2);
        } else {
            onRequiredChange('nameOnTheCard', false, 2);
            onRequiredChange('cardNumber', false, 2);
            onRequiredChange('cardExpiryDate', false, 2);
            onRequiredChange('cardCVVNumber', false, 2);
        }
    }, [data[2].enableForm.value, data[2].useCardOnFile.value ]);

    useEffect(() => {
        debugger;
        let priority = 0;
        let memType = 'I'; 
        const length = dependents.value.length;
        let hasNewDependent = false
        for(let i = 0; i < length; i++) {
            const p = dependents.value[i];
            if(p.relationship.membershipPriority > priority && p.isActive === true) {
                priority = p.relationship.membershipPriority;
                memType = p.relationship.membershipType;
            }

            hasNewDependent = isNaN(parseInt(p.memberId));
        }

        onChange('membershipType', memType, 1);

        /// If the membership type changed just leave the functions since it will be handled at the membership type component level
        if (currentMembershipType.value !== memType) {
            onChange('enableForm', true, 2);
            return
        } 

        /// Well the membership type did not change, this is probably and addition or removal of a dependent like adding a child to a family plan which does not affect the memberhsip  
        /// Checking if we are adding a new dependent
        if (hasNewDependent){
            const effective = moment(membershipEffectiveDate.value).utcOffset(0)
            const diff = effective.add(30, 'days').toDate();
            const today = moment().toDate();
            const val = currentMembershipType.value;

            const fee = today > diff ? ONE_TIME_FEE : 0;
            onChange('oneTimeFee', ONE_TIME_FEE, 1);
            onChange('monthlyFee', val && val.monthlyPayment !== undefined ? val.monthlyPayment : '', 1);
            onChange('monthsPay', 0, 1);
            onChange('planDifference', (val.monthlyPayment - currentMonthlyPayment.value).toFixed(2), 1)

            const total = val.monthlyPayment > currentMonthlyPayment.value ? val.monthlyPayment - currentMonthlyPayment.value + fee : fee;
            const datePeriod = moment().day() === dayOfAutomaticPayment.value ? moment().subtract(1, 'day').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
            onChange('fromDate', datePeriod);       
            debugger;                     
            onChange('toDate', datePeriod);
            onChange('totalPayment', total, 1);
            onChange('calculatedTotalPayment', total, 1);

            onChange('enableForm', true, 2);

            return;
        }

        /// Otherwise just move on
        onChange('enableForm', false, 2);
    }, [dependents]);

    const getTotal = (val, includeFee) => {
        const fee = includeFee && includeFee === true ? val.fee : 0;

        if(val) {
            const newPayment = (val.monthlyPayment * val.monthsPay) - (currentPlanType.value?.monthlyPayment || 0)
            + fee;
           if (newPayment < 0) {
               return 0;
           }
           return newPayment.toFixed(2);
        }
        return 0;
    }

    const getAddOnChangeTotal = (amount, months, fee) => {
        return parseFloat(((amount * months) + fee).toFixed(2));
    }

    const getGracePeriodTotal = (val) => {
        if(val) {
            const newPayment = (val.monthlyPayment * val.monthsPay) - ((currentPlanType.value?.monthlyPayment * currentPlanType.value?.monthsPay) || 0);
           if (newPayment < 0) {
               return 0;
           }
           return newPayment.toFixed(2);
        }
        return 0;
    }

    const dateDiff = (firstDate, secondDate) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.round(Math.abs((firstDate - secondDate) / oneDay));
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <PlanInfoSmartDropDown 
                    label="Plan"
                    value={planType.value}
                    onChange={val => {
                        //Deactivating button before submitting
                        onChange('requiresThirdStep', true, 1);

                        let hasNewDependent = false;                        
                        dependents.value.filter(d => d.isActive === true).forEach(newDependent => {
                            const activeDependent = activeDependents.value.find(oldDependent => oldDependent.memberId === newDependent.memberId);
                            hasNewDependent = hasNewDependent || (newDependent.isActive && (!newDependent.memberId || !activeDependent));
                        });
                        
                        const effective = moment(membershipEffectiveDate.value).utcOffset(0);
                        const diff = effective.add(30, 'days').toDate();
                        const lastChargeDiff = moment(lastFeeChargedOn.value).utcOffset(0).add(30, 'days').toDate();
                        const today = moment().toDate();

                        const isHospitalUpgrade = (currentPlanType?.value?.basePlanTypeId === val?.basePlanTypeId) && ((currentPlanType?.value?.addOnPlanTypeId || '') === '' && (val?.addOnPlanTypeId || '') !== '')
                        if (val && (currentPlanTypeId?.value !== val?.planTypeId) && (val.monthlyPayment > currentMonthlyPayment.value /*|| isHospitalUpgrade*/)) {
                            if(today > diff){                                
                                onChange('oneTimeFee', today > lastChargeDiff ? (isHospitalUpgrade ? val.addOnFee : ONE_TIME_FEE) : 0, 1); /// If there are more than 30 days after the last paid upgrade
                                onChange('isHospitalUpgrade', isHospitalUpgrade, 1);
                                onChange('monthsPay', isHospitalUpgrade ? val.addOnMonthsPaid : val.monthsPay, 1);

                                onChange('monthlyFee', val.monthlyPayment, 1);                                
                                onChange('planDifference', (parseFloat(val.monthlyPayment).toFixed(2) - parseFloat(currentMonthlyPayment.value).toFixed(2)), 1)

                                const total = isHospitalUpgrade ? getAddOnChangeTotal(val.addOnMonthlyPayment, val.addOnMonthsPaid, val.addOnFee) : getTotal(val, true);
                                const endDate = isHospitalUpgrade ? moment().format('YYYY-MM-DD') : moment().startOf('month').add(val.monthsPay, 'months').subtract(1, 'day').format('YYYY-MM-DD');
                                debugger;
                                onChange('toDate', endDate, 1);
                                onChange('totalPayment', total, 1);
                                onChange('calculatedTotalPayment', total, 1);

                                setIsMessageOpen(true);
                                onChange('requiresThirdStep', true, 1);
                            }
                            else {
                                onChange('oneTimeFee', 0, 1);
                                onChange('monthlyFee', val.monthlyPayment, 1);
                                onChange('monthsPay', val.monthsPay, 1);
                                onChange('planDifference', (val.monthlyPayment - currentMonthlyPayment.value).toFixed(2), 1)

                                const total = getGracePeriodTotal(val);
                                onChange('totalPayment', total, 1);
                                debugger;
                                onChange('toDate', moment().toDate(), 1);
                                onChange('calculatedTotalPayment', total, 1);

                                setIsMessageOpen(true)
                                onChange('requiresThirdStep', true, 1)
                            }
                        }
                        else if (hasNewDependent === true || (currentMembershipType.value !== membershipType.value && val.monthlyPayment > currentMonthlyPayment.value && hasNewDependent === true)){
                            const fee = today > diff ? ONE_TIME_FEE : 0;
                            onChange('oneTimeFee', ONE_TIME_FEE, 1);
                            onChange('monthlyFee', val && val.monthlyPayment !== undefined ? val.monthlyPayment : '', 1);
                            onChange('monthsPay', 0, 1);
                            onChange('planDifference', (val.monthlyPayment - currentMonthlyPayment.value).toFixed(2), 1)

                            const total = val.monthlyPayment > currentMonthlyPayment.value ? val.monthlyPayment - currentMonthlyPayment.value + fee : fee;
                            const datePeriod = moment().day() === dayOfAutomaticPayment.value ? moment().subtract(1, 'day').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
                            onChange('fromDate', datePeriod);  
                            debugger;                         
                            onChange('toDate', datePeriod);
                            onChange('totalPayment', total, 1);
                            onChange('calculatedTotalPayment', total, 1);
                            onChange('requiresThirdStep', true, 1)

                            setIsMessageOpen(true)
                        }
                        else {                            
                            onChange('oneTimeFee', 0, 1);
                            onChange('monthlyFee', val && val.monthlyPayment !== undefined ? val.monthlyPayment : '', 1);
                            onChange('monthsPay', 0, 1);
                            onChange('totalPayment', 0, 1);
                            onChange('calculatedTotalPayment', 0, 1);
                            debugger;
                            onChange('toDate', undefined, 1);

                            onChange('requiresThirdStep', false, 1)
                        }                        

                        if (val && (currentPlanTypeId.value !== val.planTypeId) && (val.monthlyPayment > currentMonthlyPayment.value) ) {
                            onChange('enableForm', true, 2);
                        } else {
                            onChange('enableForm', false, 2);
                        }

                        onChange('planType', val, 1)
                    }}
                    required={planType.required}
                    error={hasError('planType')}
                    disabled={(openInvoices.value && Array.isArray(openInvoices.value) && openInvoices.value.length > 0) || generateInvoices.value === false}
                    helperText={getErrorMessage('planType')}
                    membershipTypeId={membershipType.value} 
                />
            </Grid>
            <Grid item xs={6}>
                <MembershipTypeSmartDropDown 
                    label="Membership Type"
                    value={membershipType.value}
                    onChange={val => {
                        onChange('membershipType', val)}
                    }
                    required={membershipType.required}
                    disabled={true}
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    label="Membership Effective Date"
                    fullWidth={true}
                    value={membershipEffectiveDate.value}
                    onChange={date => onChange('membershipEffectiveDate', date)}
                    required={membershipEffectiveDate.required}
                    error={hasError('membershipEffectiveDate')}
                    disabled={!hasElevatedPermissions}
                    localeFormat="MM/DD/yyyy"
                    helperText={getErrorMessage('membershipEffectiveDate')}
                />
            </Grid>
            <Grid item xs={6}>
                <AgentSmartDropDown 
                    label="Agent"
                    value={agentCode.value}
                    onChange={val => onChange('agentCode', val)}
                    required={agentCode.required}
                    error={hasError('agentCode')}
                    disabled={!hasElevatedPermissions}
                    helperText={getErrorMessage('agentCode')}
                />
            </Grid>
            <Notification
                open={isMessageOpen}
                type={'warning'}
                message={'The Plan information has changed, you are about to change how much the member is charged.'}
                duration={8000}
                onClose={() => {
                    setIsMessageOpen(false);
                }}
            />
        </Grid>
    )
}

export default StepTwo