import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {AccordionSummary, Accordion, Grid, Tooltip } from '@material-ui/core';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField"
import TextField from "../../components/FormInputs/TextField/TextField"
import DeleteButton from '../../components/Buttons/DeleteButton';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    alert: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      fontWeight: 'bold',
      paddingTop: theme.spacing(2),
    },
    margin: {
        margin: theme.spacing(0),
        size: 20,
    },   
    right: {
        marginLeft: 'auto',
        fullWidth: false,
        width: 20
    },
    extendedIcon: {
        marginRight: theme.spacing(0),
    },
    typography: {
        padding: theme.spacing(1),
    },
    typography_font: {
        fontFamily: ['muli','Helvetica','Arial','Lucida','sans-serif'].join(',')
      },
  }));

  const INITIAL_ERROR_STATE = {
    chargeBackAmount: undefined,
    refundedAmount: undefined,
    refundedOn: undefined,
    chargeBackOn: undefined,
    paymentTimestamp: undefined,
    paymentAmount: undefined,
    chargeBackFee: undefined
  }

export default function Payment(props) {
    const {payment, enabled, loading, onChange, onValidation, expanded, onConfirmAction, onCancelAction, onAccordionChage, onDeleteClickAction} = props;
    const [errors, setErrors] = useState(INITIAL_ERROR_STATE);
    const [data, setData] = useState(payment);
    
    const classes = useStyles();

    

    const handleErrorChange = (element, val) => {
        setErrors({...errors, [element]: val});
    }

    const validate = (data) => {
        let hasError = false;
        const errorMsg = {...INITIAL_ERROR_STATE};
        if(data){
            if(((data?.refundedAmount || 0) + (data?.chargeBackAmount || 0)) > data?.paymentAmount){
                errorMsg.chargeBackAmount = 'Charge back + refunded Amount cannot be greater than payment amount';
                errorMsg.refundedAmount = 'Charge back + refunded Amount cannot be greater than payment amount'
                hasError = true;
            }else{
                errorMsg.chargeBackAmount = undefined;
                errorMsg.refundedAmount = undefined;
            }

            if((data?.refundedAmount || 0) > 0 && !(data?.refundedOn)){
                errorMsg.refundedOn= 'Refunded On date is required when entering a refund';
                hasError = true;
            }else{
                errorMsg.refundedOn= undefined;
            }

            if((data?.chargeBackAmount || 0) > 0 && !(data?.chargeBackOn)){
                errorMsg.chargeBackOn= 'Charge Back On date is required when entering a charge back amount';
                hasError = true;
            }else{
                errorMsg.chargeBackOn= undefined;
            }

            if((data?.paymentAmount || 0) > 0 && !(data?.paymentTimestamp)){
                errorMsg.paymentTimestamp= 'Payment date is required when entering a payment amount';
                hasError = true;
            }else{
                errorMsg.paymentTimestamp= undefined;
            }

            if((data?.paymentAmount || -1) < 0 || data?.paymentAmount === ''){
                errorMsg.paymentAmount= 'Payment amount is required';
                hasError = true;
            }else{
                errorMsg.paymentAmount= undefined;
            }
        }

        setErrors(errorMsg)
        if(onValidation){
            onValidation(hasError);            
        }
    }

    const handleFormDataChange = (element, val) => {
        const newValue = {...payment, [element]: val};
        onChange(newValue);
        setData(newValue)
        validate(newValue)
    }

    return (
      <div className={classes.root}>
        <Accordion expanded={expanded} onChange={onAccordionChage}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}                
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Typography className={classes.heading}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                        >{`Payment Details (Payment Id: ${payment?.paymentId})`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <DeleteButton disabled={!enabled} 
                            onConfirmAction={onConfirmAction} 
                            onCancelAction={onCancelAction} 
                            confirmationHeader={'Warning!'} 
                            onDeleteClickAction={onDeleteClickAction}>
                            <Alert variant="outlined" severity="warning">
                                Deleting a payment will remove payment information and change related attempts' status(es). Are you sure you want to delete the payment?
                            </Alert>
                        </DeleteButton>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>                                   
                    <Grid item xs={6}>
                        <TextField 
                            label="Payment Amount"
                            value={payment?.paymentAmount}
                            onChange={val => handleFormDataChange('paymentAmount', val)}
                            isNumericOnly
                            required={true}
                            error={errors?.paymentAmount !== undefined}
                            helperText={errors?.paymentAmount}
                            InputLabelProps={{ shrink: true }}  
                            disabled={!enabled || loading }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimeField
                            label="Payment Date"
                            fullWidth={true}
                            value={payment?.paymentTimestamp}
                            onChange={date => handleFormDataChange('paymentTimestamp', date)}
                            disableFuture={true}
                            required={true}
                            error={errors?.paymentTimestamp !== undefined}
                            helperText={errors?.paymentTimestamp}
                            disabled={!enabled || loading}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            label="Refunded"
                            value={payment?.refundedAmount ? payment?.refundedAmount : ''}
                            onChange={val => handleFormDataChange('refundedAmount', val)}
                            isNumericOnly
                            error={errors?.refundedAmount !== undefined}
                            helperText={errors?.refundedAmount}
                            required={false}                  
                            disabled={!enabled || loading }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DateTimeField
                            label="Refunded On"
                            fullWidth={true}
                            value={payment?.refundedOn}
                            onChange={date => handleFormDataChange('refundedOn', date)}
                            error={errors?.refundedOn !== undefined}
                            helperText={errors?.refundedOn}                            
                            minDate={payment?.paymentTimestamp}
                            required={false}
                            disabled={!enabled || loading }
                            disableFuture={true}
                        />
                    </Grid>          
                    <Grid item xs={6}>
                        <TextField 
                            label="Charged Back"
                            value={payment?.chargeBackAmount || ''}
                            onChange={val => handleFormDataChange('chargeBackAmount', val)}
                            isNumericOnly                            
                            error={errors?.chargeBackAmount !== undefined}  
                            helperText={errors?.chargeBackAmount}  
                            required={false}                  
                            disabled={!enabled || loading }
                        />
                    </Grid>                      
                    <Grid item xs={6}>
                        <DateTimeField
                            label="Charge Back On"
                            fullWidth={true}
                            value={payment?.chargeBackOn}
                            onChange={date => handleFormDataChange('chargeBackOn', date)}
                            minDate={payment?.paymentTimestamp}
                            error={errors?.chargeBackOn !== undefined}  
                            helperText={errors?.chargeBackOn}  
                            required={false}
                            disabled={!enabled || loading }
                            disableFuture={true}
                        />
                    </Grid>     
                    <Grid item xs={6}>
                        <TextField 
                            label="Charged Fee"
                            value={payment?.chargeBackFee || ''}
                            onChange={val => handleFormDataChange('chargeBackFee', val)}
                            isNumericOnly                            
                            error={errors?.chargeBackFee !== undefined}  
                            helperText={errors?.chargeBackFee}  
                            required={false}                  
                            disabled={!enabled || loading }
                        />
                    </Grid>                  
                    <Grid item xs={12}>
                        <TextField 
                            label="Notes"
                            value={payment?.confirmation || ''}
                            onChange={val => handleFormDataChange('confirmation', val)}
                            required={false} 
                            disabled={!enabled || loading}
                            multiline={true}
                            rows={3}
                            maxRows={10}
                        />                        
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </div>)
}
