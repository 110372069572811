import react from 'react'
import {TextField, IconButton, SvgIcon} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as StripeIcon } from "./Stripe.svg";
import { ReactComponent as StripeDisabledIcon } from "./StripeDisabled.svg";
import environment from '../../config/environment';

export const StripeLogo = (props) => {
  return <SvgIcon>
    <StripeIcon/>
</SvgIcon>
}

export const STRIPE_CUSTOMER = 'customers'
export const STRIPE_PAYMENT_INTENT = 'payments'

export const StripeTextBox = (props) => {
    const {baseURL, type, stripeId, onChange, value, label, disabled, isNumericOnly} = props;

    const getUrlLink = (baseURL, type, id) => {
      const url = baseURL && baseURL !== '' ? baseURL : `${environment().stripeUrl}`;
      return `${url}${type}/${id}`
    }

    const handleChange = (prop) => (event) => {
      onChange(event.target.value);
    };

    return <TextField
    disabled={disabled}
    label={label}
    InputLabelProps={{shrink: true}}
    // sx={{ m: 1, width: '25ch' }}
    size="small"
    onChange={handleChange('val')}
    variant="outlined"
    value={value !== undefined ? value : ''}
    fullWidth={true}
    InputProps={{
      endAdornment: <InputAdornment position="end" >
        <IconButton
            aria-label="Go to Stripe"
            onClick={() => console.log('clicked')}            
            href={getUrlLink(baseURL, type, stripeId)}
            target="_blank"         
            disabled={!stripeId || stripeId === ''}   
        >
            <StripeLogo disabled={disabled}/>
        </IconButton>
      </InputAdornment>,
    }}
    // variant="filled"
  />
}