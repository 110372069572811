import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(0),
    size: 20,
  },
  extendedIcon: {
    marginRight: theme.spacing(0),
  },
  typography: {
    padding: theme.spacing(1),
  },
}));

export default function AttemptPopOver(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {data} = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div  >
      <ContactSupportIcon fontSize="small" color="secondary" className={classes.margin} onClick={handleClick}/>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.typography}
      >
        {(data?.brand && data?.brand.length > 0) || (data?.lastDigits && data?.lastDigits.length > 0) ? 
            <Typography>{`Paid with: ${data?.brand && data?.brand.length > 0 ? data.brand : 'Missing Brand'} (${data?.lastDigits && data?.lastDigits.length > 0 ? data.lastDigits : 'Missing Last Digits'})`}</Typography> : 
            null }
        <Typography>{`Attempt ID: ${data.attemptId}`}</Typography>
        <Typography>{`Created By: ${data.createdBy}`}</Typography>
        <Typography>{`Comments: ${data.comments}`}</Typography>
      </Popover>
    </div>
  );
}