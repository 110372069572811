import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {List, IconButton} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import WifiIcon from '@material-ui/icons/Wifi';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import Grid from '@material-ui/core/Grid'
import MembershipListItem from './MembershipListItem';
import { Save } from '@material-ui/icons';
import axios from 'axios';
import {saveMembershipStatus} from '../../apiCalls/Invoices/saveMembershipStatus';
import Notification from "../../components/Notification/Notification";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    float: 'right',
    color: 'primary'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
    float: 'left'
  },
  accHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

export default function MembershipTable(props) {
    const {dataFromParent, authToken, parentLoadingState, onNotify} = props;  

    const [data, setData] = useState(dataFromParent);

    const buildCheckedList = () => {
        const generateInvoiceList = [];
        dataFromParent?.forEach(m => {
            if(m.generateInvoices){
                generateInvoiceList.push(m.membershipId);
            }
        })

        return generateInvoiceList;
    }

    const [checked, setChecked] = useState(buildCheckedList());
    const [hasNotChanged, setHasNotChanged] = useState(true);
    const [isLoading, setIsLoading] = useState(parentLoadingState);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});    
    const classes = useStyles();  

    useEffect(()=>{
        if(dataFromParent && Array.isArray(dataFromParent) && dataFromParent.length > 0){
            setHasNotChanged(true);
            setData(dataFromParent);
            setChecked(buildCheckedList());
        }
    }, [dataFromParent])

    const savePayeeInformation = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();        

        if(authToken && data && Array.isArray(data)) {
            const updates = [];
            data?.forEach(m => {
                if(m.generateInvoices === true && !checked.includes(m.membershipId)){
                    updates.push({                        
                        applicationId: m.applicationId,
                        generateInvoices: false
                    });
                }

                if(m.generateInvoices === false && checked.includes(m.membershipId)){
                    updates.push({
                        applicationId: m.applicationId,
                        generateInvoices: true
                    });
                }
            });            

            if(updates.length>0){
                setIsLoading(true);

                saveMembershipStatus(updates, source, authToken)
                .then(response => {
                    setIsLoading(false);      
                    setHasNotChanged(true);
                    
                    if(onNotify){
                        onNotify({
                            open: true,
                            type: "success",
                            message: "Successfully changed the Active/Inactive settings"
                        });
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log("Cancelled Payee fetching");
                    } else {
                        setIsLoading(false);
                        if(onNotify){
                            onNotify({
                                open: true,
                                type: "error",
                                message: error.message ? error.message : "Failed to fetch Payee details, please try again"
                            });
                        }
                    }
                });
            }
        }

        return () => source.cancel();
    }

    return (data && Array.isArray(data) && data.length > 0 ?   
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Grid container>
                    <Grid item xs={10}>
                        <Typography className={classes.heading}>Membership(s)</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton disabled={isLoading || hasNotChanged} color="primary" className={classes.button} aria-label="delete" id="memberships-state-save-btn"
                            onClick={(event) => {
                                savePayeeInformation();
                            }}>
                            <Save></Save>
                        </IconButton>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <List dense={true}
                    subheader={<ListSubheader></ListSubheader>} 
                    className={classes.root}>
                    {data?.map(d => <MembershipListItem     
                                        key={`mbr-lst-itm-${d.membershipId}`}
                                        disabled={isLoading} 
                                        membershipId={d.membershipId} 
                                        memberName={`${d.lastName}, ${d.firstName}`} 
                                        phone={d.primaryPhoneNumber} 
                                        generateInvoices={checked.indexOf(d.membershipId) !== -1} 
                                        onToggle={() => {
                                            const currentIndex = checked.indexOf(d.membershipId);
                                            const newChecked = [...checked];
                                    
                                            if (currentIndex === -1) {
                                                newChecked.push(d.membershipId);
                                            } else {
                                                newChecked.splice(currentIndex, 1);
                                            }
                                    
                                            setChecked(newChecked);
                                            setHasNotChanged(false);
                                        }}
                                        ></MembershipListItem>)}        
                </List>                           
                <Notification
                        open={notification.open}
                        type={notification.type}
                        message={notification.message}
                        onClose={() => {
                            if(notification.type === "success") {
                                setIsLoading(false);
                            }
                            setNotification({...DEFAULT_NOTIFICATION});                        
                        }}
                    />
            </AccordionDetails>
        </Accordion>
    : null);
}