import {getAxiosInstance} from "../config/axios";
import environment from "../config/environment";

export const getPaymentPerformance = (source, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}payment-performance`,
            {
                params: {
                    offset: 0,
                    limit: 100
                },
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response?.data?.data?.length) {
                        resolve(response.data);
                    }
                    else {
                        resolve([])
                    }
                }
                reject(response)
            })
            .catch(error => reject(error))
    })
}