import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Checkbox, FormControlLabel, Button, CircularProgress} from '@material-ui/core'
import TextField from "../../components/FormInputs/TextField/TextField"
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField"
import { useState } from 'react'
import {StripeTextBox, STRIPE_CUSTOMER, STRIPE_PAYMENT_INTENT} from '../../components/Stripe/StripeTextBox'
import { NumericSearchTextBox } from '../../components/Search/NumericSearchTextBox'
import Payment from './Payment'
import { formatDate } from '../../utils/DateHandling';
import Alert from '@material-ui/lab/Alert';
import DeleteButton from '../../components/Buttons/DeleteButton';
import { getAttemptDetails } from '../../apiCalls/Invoices/getAttemptDetails';
import axios from 'axios';
import Notification from "../../components/Notification/Notification";
import {saveAttemptDetails} from "../../apiCalls/Invoices/saveAttemptDetails"
import {deleteAttempt}  from "../../apiCalls/Invoices/deleteAttempt"
import {deletePayment} from '../../apiCalls/Invoices/deletePayment'
import PaymentTypeSmartDropDown from "../common/PaymentTypeSmartDropDown/PaymentTypeSmartDropDown";

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0),
        size: 20,
    },   
    right: {
        marginLeft: 'auto',
        fullWidth: false,
        width: 20
    },
    extendedIcon: {
        marginRight: theme.spacing(0),
    },
    typography: {
        padding: theme.spacing(1),
    },
}))

const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
}

const INITIAL_STATE = {
    attemptId: '',
    attemptedOn: undefined,
    attemptedAmount: '',
    attemptedBy: '',
    scheduleDate: undefined,
    scheduleBy: '',
    comments: '',  
    isReadyForProcessing: false,
    isCashPayment: false,
    payment: {
        paymentId: '',
        paymentAmount: '',
        paymentTimestamp: undefined,
        confirmation: '',
        applicationId: '',
        membershipId: '',                        
        refundedAmount: '',
        refundedOn: undefined,
        chargeBackOn: undefined,
        chargeBackAmount: ''
    },
    stripeCustomerId: '',
    stripePaymentMethodId: '',
    stripeIntentId: '',
    status: '',
    paymentTypeId: 'C',
};
  
function AttemptEditor(props) {
    const {attempt, authToken, onSave} = props;
    const classes = useStyles(props);

    const [hasNotChanged, setHasNotChanged] = useState(true)
    const [enabled, setEnabled] = useState(false);
    const [errorState, setErrorState] = useState(false);   
    const [childErrorState, setChildErrorState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});  
    const [expandedAccordion, setExpandedAccordion] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [data, setData] = useState(INITIAL_STATE);    

    const handleFormDataChange = (element, val) => {
        if(element === 'paymentTypeId'){
            setData({...data, ['isCashPayment']: data?.paymentTypeId !== 'C'});
        }

        if(element === 'isReadyForProcessing'){
            val = !data.isReadyForProcessing;
        }

        setData({...data, [element]: val});
        setHasNotChanged(false);
    }

    const setAttemptId = (val) => {
        handleFormDataChange('attemptId', val)
    }

    const [allowSearch, setAllowSearch] = useState(true);

    const prepareAttemptDataForViewing = (response) => {
        setData(response)
        setHasNotChanged(true);
        setEnabled(response?.attemptId > 0)
        setErrorState(false)
    }

    useEffect(() => {
        if(attempt){
            setAttemptId(attempt)
            loadAttemptInformation(attempt)
            setAllowSearch(false)
        }        
    }, [])

    const loadAttemptInformation = (attemptId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const attemptNr = data?.attemptId ? data.attemptId : attemptId;        

        if(authToken && (attemptNr)) {
            setLoading(true);

            getAttemptDetails(attemptNr, source, authToken)
            .then(response => {        
                prepareAttemptDataForViewing(response.data)                
                setLoading(false);
                setAllowSearch(false);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Attempt fetching");
                } else {
                    console.log('Failed to fetch Attempt', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch Attempt details, please try again"
                    });
                }
            });
        }

        return () => source.cancel();
    }

    const handleDeleteAttempt = (attemptId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)

        if(authToken && data){
            deleteAttempt(data?.attemptId, source, authToken)
            .then(response => {
                setAttemptId('')
                setLoading(false);
                setEnabled(false);
                setData(INITIAL_STATE);
                setAllowSearch(true);     
                onSave();              
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to update Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to update Invoice details, please try again"
                    });
                }
            });
        }
    }

    const handleSave = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)

        if(authToken && data){
            saveAttemptDetails(data, source, authToken)
            .then(response => {                
                setAttemptId('')
                setLoading(false);
                setEnabled(false);
                setData(INITIAL_STATE);
                setAllowSearch(true);     
                onSave();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to update Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to update Invoice details, please try again"
                    });
                }
            });
        }
    }

    const handleDeletePayment = (paymentId) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        if(authToken && paymentId) {
            setLoading(true)
    
            deletePayment(paymentId, source, authToken)
            .then(response => {        
                setAttemptId('')
                setLoading(false);
                setEnabled(false);
                setData(INITIAL_STATE);
                setAllowSearch(true);     
                onSave();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to fetch Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to fetch Invoice details, please try again"
                    });
                }
            });
        }

        return () => source.cancel();
    }

    return <Grid container spacing={2}> 
        <Grid item xs={6} >   
            <div style={{paddingTop: '8px'}}>                 
                <NumericSearchTextBox label="Attempt Number"
                    value={data?.attemptId}
                    onChange={setAttemptId}
                    required={true}
                    onSearchClick={loadAttemptInformation}
                    disabled={!allowSearch}>
                </NumericSearchTextBox>               
            </div>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={1}>
            <DeleteButton disabled={!enabled} onConfirmAction={() => handleDeleteAttempt(data?.attemptId)} confirmationHeader={'Warning!'}>
                <Alert variant="outlined" severity="warning">
                    Deleting an attempt will remove attempt and payment information. You will have to recreate the attempt and manually link the financial information. Are you sure you want to delete this attempt?
                </Alert>
            </DeleteButton>
        </Grid>
        <Grid item xs={6}>
            <StripeTextBox  label="Stripe Customer Id" 
                            stripeId={data?.stripeCustomerId}
                            value={data?.stripeCustomerId}
                            type={STRIPE_CUSTOMER}
                            onChange={val => handleFormDataChange('stripeCustomerId', val)}
                            disabled={!enabled || loading || data?.updateStripeInfo === false}
                            />
        </Grid>
        <Grid item xs={6}>
            <StripeTextBox  label="Stripe Payment Method Id" 
                            value={data?.stripePaymentMethodId}
                            stripeId={data?.stripeCustomerId}
                            type={STRIPE_CUSTOMER}
                            onChange={val => handleFormDataChange('stripePaymentMethodId', val)}
                            disabled={!enabled || loading || data?.updateStripeInfo === false}/>
        </Grid>
        <Grid item xs={6}>
            <StripeTextBox  label="Stripe Payment Intent Id" 
                            value={data?.stripeIntentId}
                            stripeId={data?.stripeIntentId}
                            type={STRIPE_PAYMENT_INTENT}
                            onChange={val => handleFormDataChange('stripeIntentId', val)}
                            disabled={!enabled || loading || data?.updateStripeInfo === false}/>
        </Grid>        
        <Grid item xs={6} ></Grid>  
        <Grid item xs={6}>
            <PaymentTypeSmartDropDown
                label="Payment Type"
                value={data?.paymentTypeId}
                onChange={val => handleFormDataChange('paymentTypeId', val)}
                required={false}
                error={!data?.paymentTypeId || data?.paymentTypeId === ''}
                helperText={!data?.paymentTypeId || data?.paymentTypeId === '' ? 'Payment Type is required' : undefined}
                allowCourtesy={false}
                isCourtesyOnly={undefined}
                disabled={!enabled || loading || data?.updateStripeInfo === false}
            />
        </Grid>      
        {/* <Grid item xs={6}>
            <FormControlLabel control={<Checkbox disabled={!enabled || loading} onChange={val => handleFormDataChange('isCashPayment', val)} checked={data?.isCashPayment === true}/>} label="Is Cash Payment" />                
        </Grid>             */}
        <Grid item xs={12}>
            <FormControlLabel control={<Checkbox disabled={!enabled || loading} onChange={val => handleFormDataChange('isReadyForProcessing', val)} checked={data?.isReadyForProcessing === true}/>} label="Ready for processing" />                
        </Grid>
        <Grid item xs={6}>
            <DateTimeField
                label="Scheduled For"
                fullWidth={true}
                value={data?.scheduleDate}
                onChange={date => handleFormDataChange('scheduleDate', date)}
                disablePast={false}
                required={true}
                disabled={!enabled || loading}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField 
                label="Scheduled By"
                value={data?.scheduleBy}
                onChange={val => handleFormDataChange('scheduleBy', val)}
                required={false}                  
                disabled={!enabled || loading}
            />
        </Grid>
        <Grid item xs={6}>
            <DateTimeField
                label="Attempted On"
                fullWidth={true}
                value={data?.attemptedOn}
                onChange={date => handleFormDataChange('attemptedOn', date)}
                disablePast={false}
                required={false}
                disabled={!enabled || loading}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField 
                label="Attempted By"
                value={data?.attemptedBy}
                onChange={val => handleFormDataChange('attemptedBy', val)}
                required={false}                  
                disabled={!enabled || loading}
            />
        </Grid>  
        <Grid item xs={6}>
            <TextField 
                label="Attempted Amount"
                value={data?.attemptedAmount}
                onChange={val => handleFormDataChange('attemptedAmount', val)}
                isNumericOnly
                required={true}                  
                disabled={!enabled || loading}
            />
        </Grid>
        <Grid item xs={6}>
            <TextField 
                label="Status"
                value={data?.status}
                onChange={val => handleFormDataChange('status', val)}                    
                required={true}                  
                disabled={!enabled || loading}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField
                id="filled-multiline-static"
                label="Notes"
                multiline
                rows={4}
                value={data?.comments ? data?.comments : ''}
                variant="outlined"
                onChange={val => handleFormDataChange('comments', val)}
                disabled={!enabled || loading}
            />
        </Grid>
        {data?.payment?.paymentId ?
            <Grid item xs={12}>
                <Payment payment={data?.payment} 
                        onChange={val => handleFormDataChange('payment', val)}
                        expanded={expandedAccordion}
                        onAccordionChage={(event, state) => setExpandedAccordion(state || deleteClicked)}
                        onDeleteClickAction={() => {
                            setDeleteClicked(true)
                            setExpandedAccordion(true)
                        }}
                        onConfirmAction={() => {
                            handleDeletePayment(data?.payment?.paymentId);
                            setDeleteClicked(false);
                            onSave();                     
                        }}
                        onCancelAction={() => {
                            setDeleteClicked(false)                        
                        }}
                        onValidation={(state) => {
                            setChildErrorState(state);
                        }}
                        enabled={enabled} 
                        loading={loading}>                    
                </Payment>
            </Grid>
            : null}
        <Grid item xs={12}>
            <Button 
                variant="contained" 
                color="secondary" 
                size="small"
                onClick={() => handleSave()}
                disabled={!enabled || errorState || childErrorState || loading || hasNotChanged}
            >
                Update Attempt
                {
                    loading ?
                    <CircularProgress style={{color: 'white', width: 14, height: 14, marginRight: 5}}/> :
                    null
                }
            </Button>
        </Grid>             
        <Notification
                open={notification.open}
                type={notification.type}
                message={notification.message}
                onClose={() => {
                    if(notification.type === "success") {
                        setLoading(false);
                    }
                    setNotification({...DEFAULT_NOTIFICATION});                        
                }}
            />
    </Grid>
}

export default AttemptEditor;