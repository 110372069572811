import React, {useState, useEffect} from 'react'
import FormWizard from "../../components/FormWizard/FormWizard";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import ConfirmationPage from "./ConfirmationPage";
import {
    Grid
} from '@material-ui/core';
import {saveApplication} from "../../apiCalls/Application/saveApplication";
import axios from 'axios';
import moment from 'moment';
import Notification from "../../components/Notification/Notification";
import {connect} from 'react-redux';
import {cancelDeleteApplication} from "../../apiCalls/Application/cancelDeleteApplication";
import {submitApplication} from "../../apiCalls/Application/submitApplication";
import { isLimited, isElevatedPermissions } from '../../utils/MuiConstants';

const DEFAULT_NOTIFICATION = {
    type: "success",
    message: "",
    open: false
};

const NewApplication = (props) => {
    const {accountInfo, formData, isApplicationProcessed, closeAndSearch, setShouldRefresh} = props;
    
    const DEFAULT_STEPS = [
        {
            label: 'Personal Information',
            component: <StepOne/>,            
            data: {
                hasElevatedPermissions: isElevatedPermissions(accountInfo),
                membershipId: {
                    required: true,
                    value: ''
                },
                applicationId: {
                    required: false,
                    value: ''
                },
                memberId: {
                    required: false,
                    value: ''
                },
                firstName: {
                    required: true,
                    value: ''
                },
                middleName: {
                    required: false,
                    value: ''
                },
                lastName: {
                    required: true,
                    value: ''
                },
                dateOfBirth: {
                    required: true,
                    value: null
                },
                gender: {
                    required: true,
                    value: ''
                },
                address: {
                    required: true,
                    value: null
                },
                primaryPhone: {
                    required: true,
                    value: ''
                },
                emergencyPhone: {
                    required: true,
                    value: ''
                },
                email: {
                    required: true,
                    value: ''
                },
                center: {
                    required: true,
                    value: ''
                },
                activeDependents: {
                    required: false,
                    value: []
                },
                dependents: {
                    required: false,
                    value: []
                },
                notes:{
                    required: false,
                    value: ''
                },
                enableAutomaticPayments: {
                    required: false,
                    value: false
                },
                comments: {
                    required: false,
                    value: ''
                },
                openInvoices: {
                    required: false,
                    value: []
                }
            }
        },
        {
            label: 'Plan Information',
            component: <StepTwo/>,
            hasElevatedPermissions: isElevatedPermissions(accountInfo),
            data: {
                hasElevatedPermissions: isElevatedPermissions(accountInfo),
                lastFeeChargedOn: {
                    required: false,
                    value: undefined
                },
                planType: {
                    required: true,
                    value: ''
                },
                membershipType: {
                    required: true,
                    value: ''
                },
                originalMembershipType: {
                    required: false,
                    value: ''
                },
                oneTimeFee: {
                    required: false,
                    value: ''
                },
                monthlyFee: {
                    required: false,
                    value: ''
                },
                monthsPay: {
                    required: false,
                    value: 0
                },
                totalPayment: {
                    required: false,
                    value: 0
                },
                fromDate: {
                    required: false,
                    value: moment().toDate()
                },
                toDate: {
                    required: false,
                    value: ''
                },
                totalAmountComment: {
                    required: false,
                    value: ''
                },
                calculatedTotalPayment: {
                    required: false,
                    value: 0
                },
                planDifference: {
                    required: false,
                    value: ''
                },
                membershipEffectiveDate: {
                    required: true,
                    value: moment().format('YYYY-MM-DD')
                },
                agentCode: {
                    required: true,
                    value: ''
                },
                currentMembershipType: {
                    required: false,
                    value: ''
                },
                currentPlanTypeId: {
                    required: false,
                    value: ''
                },
                currentPlanType: {
                    required: false,
                    value: null
                },
                requiresThirdStep: {
                    required: false,
                    value: false
                },
                isHospitalUpgrade: {
                    required: false,
                    value: false
                },
                monthsCovered: {
                    required: false,
                    value: 0
                }
            }
        },
        {
            label: 'Payment',
            component: <StepThree/>,
            hasElevatedPermissions: isElevatedPermissions(accountInfo),
            data: {
                hasElevatedPermissions: isElevatedPermissions(accountInfo),
                paymentType: {
                    required: true,
                    value: 'C'
                },
                nameOnTheCard: {
                    required: false,
                    value: ''
                },
                cardNumber: {
                    required: false,
                    value: ''
                },
                cardExpiryDate: {
                    required: false,
                    value: ''
                },
                cardCVVNumber: {
                    required: false,
                    value: ''
                },
                enableAutomaticPayments: {
                    required: false,
                    value: false
                },
                dayOfAutomaticPayment: {
                    required: false,
                    value: ''
                },
                useCardOnFile: {
                    required: false,
                    value: true
                },
                updateCardOnFile: {
                    required: false,
                    value: false
                },
                enableForm: {
                    required: false,
                    value: true
                },
                stripeCustomerId: {
                    required: false,
                    value: ""
                },
                stripePaymentMethodId: {
                    required: false,
                    value: ""
                },
                isActive: {
                    required: false,
                    value: true
                },
                generateInvoices: {
                    required: false,
                    value: true
                },
                applicationDate: {
                    required: false,
                    value: ''
                }
            }
        }
    ];

    //const [isCancelationBtnVisible, setIsCancelationBtnVisible] = useState(false);
    const [steps, setSteps] = useState([...DEFAULT_STEPS]);
    const [confirmationData, setConfirmationData] = useState(null);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});
    const [allowAnotherSubmit, setAllowAnotherSubmit] = useState(false);
    const [authToken, setAuthToken] = useState("");
    const [cancelDeleteApplicationLoading, setCancelDeleteApplicationLoading] = useState(false);

    useEffect(() => {
        if(accountInfo && accountInfo.jwtIdToken) {
            setAuthToken(accountInfo.jwtIdToken);
        }

        //setIsCancelationBtnVisible(!isCashManagementGroup(accountInfo))
    }, [accountInfo])

    useEffect(() => {
        if(formData && formData.length) {
            setSteps(prevSteps => {
                return prevSteps.map((prevStep, index) => {
                    const newData = {}
                    for(var key in formData[index]) {
                        newData[key] = {
                            required: prevStep.data[key]?.required,
                            value: formData[index][key],
                            originalValue: formData[index][key]
                        }
                    }
                    return {
                        ...prevStep,
                        data: {
                            ...prevStep.data,
                            ...newData
                            
                        }
                    }
                })
            });
        }
    }, [formData])

    const preparePayload = form => {
        
        const payload = {
            addressLine1: form.address.value.addressLine1,
            addressLine2: form.address.value.addressLine2,
            addressCity: form.address.value.city,
            addressState: form.address.value.state,
            addressZip: form.address.value.zipCode,
            eMail: form.email,
            primaryPhoneNumber: form.primaryPhone,
            emergencyPhoneNumber: form.emergencyPhone,
            agentCode: form.agentCode,
            primaryCenterId: form.center,
            planInfoId: form.planType.planInfoId,
            applicationId: form.applicationId,
            modificationInvoice: {
                cost: form.totalPayment,
                comment: form.totalAmountComment,
                fromDate: form.fromDate,
                toDate: form.toDate,
                balance: form.balance,
                isHospitalUpgrade: form.isHospitalUpgrade,
                monthsCovered: form.monthsPay
            },
            stripeCustomerId: form.stripeCustomerId || '',
            stripePaymentMethodId: form.stripePaymentMethodId || '',
            enableAutomaticPayments: form.enableAutomaticPayments === '' ? false : form.enableAutomaticPayments,
            automaticCharge: form.enableAutomaticPayments === '' ? false : form.enableAutomaticPayments,
            isActive: form.isActive === '' ? false : form.isActive,
            generateInvoices: form.generateInvoices === '' ? false : form.generateInvoices,
            comments: form.notes,
            membershipId: form.membershipId
        }
        const members = [];

        if(form.dependents && form.dependents.length) {
            for(let i = 0; i < form.dependents.length; i++) {
                members.push({
                    firstName: form.dependents[i].firstName,
                    lastName: form.dependents[i].lastName,
                    middleName: form.dependents[i].middleName,
                    dateOfBirth: form.dependents[i].dateOfBirth,
                    phoneNumber: form.dependents[i].phone,
                    genderId: form.dependents[i].gender,
                    relationshipTypeId: form.dependents[i].relationship.relationshipTypeId,
                    applicationId: form.applicationId,
                    memberId: form.dependents[i].memberId || undefined,
                    isActive: form.dependents[i].isActive,
                    deactivationDate: moment(form.dependents[i].deactivationDate).format('YYYY-MM-DD'),
                    effectiveDate: moment(form.dependents[i].effectiveDate).format('YYYY-MM-DD')
                })
            }
        }

        payload.members = members;
        payload.comments = form.comments;

        if(form.membershipEffectiveDate) payload.startDate = form.membershipEffectiveDate;

        return payload;
    }
    
    const handleNextClick = form => {
        return new Promise((resolve, reject) => {
            saveApplication(axios.CancelToken.source(), preparePayload(form), authToken)
            .then(response => resolve(response))
            .catch(error => reject(error))
        });
    }

    const handleFormSubmit = form => {  
        const formWithPaymentDetails = {...preparePayload(form), 
            dateOfAutomaticPayment: isNaN(parseInt(form.dayOfAutomaticPayment)) ? undefined : parseInt(form.dayOfAutomaticPayment),
            enableAutomaticPayments: form.enableAutomaticPayments,
            automaticCharge: form.enableAutomaticPayments};
        formWithPaymentDetails.initialAmount = form.totalPayment;
        if(form.paymentType.toLowerCase() === 'c') {
            if(form.useCardOnFile === true){
                formWithPaymentDetails.paymentMethod = {
                    paymentTypeId: "C",
                    cardNumber: undefined,
                    cardOwnerName: undefined,
                    cardExpirationMonth: undefined,
                    cardExpirationYear: undefined,
                    cvcCode: undefined,
                    // enableAutomaticPayments: form.enableAutomaticPayments,
                    useCardOnFile: true,
                    updateCardOnFile: false,
                    // dateOfAutomaticPayment: isNaN(parseInt(form.dayOfAutomaticPayment)) ? undefined : parseInt(form.dayOfAutomaticPayment),
                    // automaticCharge: form.enableAutomaticPayments
                }
            } else {
                formWithPaymentDetails.paymentMethod = {
                    paymentTypeId: "C",
                    cardNumber: form.cardNumber,
                    cardOwnerName: form.nameOnTheCard,
                    cardExpirationMonth: moment(form.cardExpiryDate).format('M'),
                    cardExpirationYear: moment(form.cardExpiryDate).format('YYYY'),
                    cvcCode: form.cardCVVNumber,
                    // enableAutomaticPayments: form.enableAutomaticPayments,
                    useCardOnFile: false,
                    updateCardOnFile: form.updateCardOnFile,
                    // dateOfAutomaticPayment: isNaN(parseInt(form.dayOfAutomaticPayment)) ? undefined : parseInt(form.dayOfAutomaticPayment),
                    // automaticCharge: form.enableAutomaticPayments
                }
            }
        }
        else {
            formWithPaymentDetails.paymentMethod = {
                paymentTypeId: form.paymentType
            }
        } 
            
        submitApplication(axios.CancelToken.source(), formWithPaymentDetails, authToken)
        .then(response => {   
            if(response && response.success !== undefined && response.success && response.application.membershipId > 0) {
                setConfirmationData({
                    membershipId: response.application.membershipId
                });

                setShouldRefresh(true);
            }
            else {
                setNotification({
                    open: true,
                    type: "error",
                    message: response && response.error && response.error.message ? response.error.message :  `Failed to submit application, please try again.`
                });
                setAllowAnotherSubmit(true);
            }
        })
        .catch(error => {
            const response = error.response.data;
            console.log('Failed to submit application', error)
            setNotification({
                open: true,
                type: "error",
                message: response && response.error && response.error.message ? response.error.message : `Failed to submit application, please try again.`
            });
            setAllowAnotherSubmit(true);
        });
    }

    const handleCancelDeleteApplication = (applicationId) => {
        setCancelDeleteApplicationLoading(true);
        cancelDeleteApplication(axios.CancelToken.source(), applicationId, authToken)
        .then(response => {
            setNotification({
                open: true,
                type: "success",
                message: `Success! ${response.confirmation}`
            });
            setCancelDeleteApplicationLoading(false);            
        })
        .catch(error => {
            setNotification({
                open: true,
                type: "error",
                message: `Failed to process.`
            });
            setCancelDeleteApplicationLoading(false);
        })
    }

    return <Grid container>
        <Grid item xs={12}>
            {
                confirmationData ?
                <ConfirmationPage data={confirmationData} /> :
                <FormWizard 
                    authToken={authToken}
                    steps={steps}
                    onSubmit={handleFormSubmit}
                    handleNextClick={handleNextClick}
                    allowAnotherSubmit={allowAnotherSubmit}
                    resetAllowAnotherSubmit={() => setAllowAnotherSubmit(false)}
                    isApplicationProcessed={isApplicationProcessed}
                    handleCancelDeleteApplication={handleCancelDeleteApplication}
                    cancelDeleteApplicationLoading={cancelDeleteApplicationLoading}
                    isReadOnly={isLimited(accountInfo)}
                    canCancel={isElevatedPermissions(accountInfo)}
                    closeAndSearch={closeAndSearch}
                />                
            }
        </Grid>
        <Notification
            open={notification.open}
            type={notification.type}
            message={notification.message}
            onClose={() => {
                setNotification({...DEFAULT_NOTIFICATION})
                if(closeAndSearch){
                    closeAndSearch()
                }
            }}
        />
    </Grid>
}

const mapStateToProps = state => {
    return {
      accountInfo: state.accountInfo
    }
}
  
export default connect(mapStateToProps)(NewApplication)