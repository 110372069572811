const local = {
    functionsURL: "http://localhost:7071/api/",
    azureADRedirectURI: "http://localhost:3000",
    backOfficeGroupId: "cebd99b2-5237-42ee-9e79-c36d874b8000",
    stripeUrl: "https://dashboard.stripe.com/test/"
}

const dev = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-backoffice-webapp.azurewebsites.net",
    backOfficeGroupId: "cebd99b2-5237-42ee-9e79-c36d874b8000",
    stripeUrl: "https://dashboard.stripe.com/test/"
}

const test = {
    functionsURL: "https://orange-medical-plan-functions.azurewebsites.net/api/",
    azureADRedirectURI: "https://orange-medical-plan-backoffice-webapp.azurewebsites.net",
    backOfficeGroupId: "cebd99b2-5237-42ee-9e79-c36d874b8000",
    stripeUrl: "https://dashboard.stripe.com/test/"
}

const prod = {
    functionsURL: "https://prod-omp-functionapp.azurewebsites.net/api/",
    azureADRedirectURI: "https://prod-omp-backoffice-webapp.azurewebsites.net",
    backOfficeGroupId: "9b07b90a-9884-4e32-93c5-ad77d21c44ca",
    stripeUrl: "https://dashboard.stripe.com/"
}

const environment = () => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'PROD':
            return prod;
        case 'TEST':
            return test;
        case 'DEV':
            return dev;
        case 'LOCAL':
            return local;
        default:
            return dev;
    }
};

export default environment;