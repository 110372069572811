import {getAxiosInstance} from "../../config/axios";
import environment from "../../config/environment";

export const saveMembershipStatus = (memberhipUpdates, source, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).put(`${environment().functionsURL}payee`,
            memberhipUpdates,
            {
                cancelToken: source.token                
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
    })
}