import React from "react";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {COLOR_PALETTE} from "../../config/colorPalette";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        padding: '20px 20px 0px 20px'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    pageTitle: {
        color: COLOR_PALETTE.green.opacity100,
        margin: '0px',
    }
}));

const PageHeader = (props) => {
    const classes = useStyles();
    const { pageTitle, customButtons } = props;
    return (
        <Grid 
            container
            direction = "row"
            justify = "space-between"
            alignItems = "flex-start"
            className = {classes.root}
        >
            <Grid item>
                <h1 className = {classes.pageTitle}>{ pageTitle }</h1>
            </Grid>
            <Grid item className = {classes.buttons}>
            {
                customButtons ? customButtons.map( (customButton, index) => {
                    return <div key = {index} style={{ marginLeft: 20 }}>{customButton}</div>
                } ) : null
            }
            </Grid>
        </Grid>
    
    )
}

PageHeader.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    customButtons: PropTypes.array
}

export default PageHeader;
