import {
    Grid
} from '@material-ui/core';
import SmartDropDown from "../common/SmartDropDown/SmartDropDown";

const CommissionFilterTemplate = props => {

    const {filterValues, onFilterChange, agentTypes, planTypes, membershipTypes} = props;

    return <>
                <Grid item xs={6}>
                    <SmartDropDown
                        label="Agent Type"
                        category={'agent-type'}
                        value={filterValues.agentTypeId}
                        loading={true}
                        onChange={val => onFilterChange('agentTypeId', val)}
                        menuItems={agentTypes}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SmartDropDown
                        label="Plan Type"
                        category={'plan-type'}
                        value={filterValues.planTypeId}
                        loading={true}
                        onChange={val => onFilterChange('planTypeId', val)}
                        menuItems={planTypes}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SmartDropDown
                        label="Membership Type"
                        category={'membership-type'}
                        value={filterValues.membershipTypeId}
                        loading={true}
                        onChange={val => onFilterChange('membershipTypeId', val)}
                        menuItems={membershipTypes}
                    />
                </Grid>
            </>
}

export default CommissionFilterTemplate;