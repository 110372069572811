import {useState, useEffect} from 'react';
import {Grid, makeStyles} from '@material-ui/core';
import TextField from "../../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../../components/FormInputs/DateTimeField/DateTimeField";
import Button from "../../../components/FormInputs/Button/Button";
import PropTypes from 'prop-types';
import GenderSmartDropDown from "../GenderSmartDropDown/GenderSmartDropDown";
import RelationshipTypeSmartDropDown from "../RelationshipTypeSmartDropDown/RelationshipTypeSmartDropDown";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const useStyles = makeStyles({
    container: {
        padding: '20px'
    }
})

export default function UpgradeDependentForm(props){
    const {data} = props;

    const classes = useStyles();

    return <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} style={{padding: '0px 8px'}}>
            <h2>{`New Relationship for Current Primary Member`}</h2>
        </Grid>
        <Grid item xs={12}>
            <RelationshipTypeSmartDropDown 
                label="Relationship"
                onChange={val => data.upgradeDependent(data.dependent, val)}
                required={true}
            />
        </Grid>
    </Grid>
}