import react from 'react'
import {TextField, IconButton, SvgIcon} from '@material-ui/core';
import { SearchOutlined, Visibility } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import CustomNumberFormat from "../CustomNumberFormat/CustomNumberFormat";

export const NumericSearchTextBox = (props) => {
    const {onSearchClick, onChange, value, label, disabled, isIntegerOnly} = props;
    
    const handleChange = (prop) => (event) => {
        if(isNaN(parseInt(event.target.value))) {
            if(event.target.value !== ''){
                event.preventDefault()
                return;
            }

            onChange(event.target.value);
        } else {            
            onChange(parseInt(event.target.value));
        }        
    };

    const filterOnEnter = (event) => {
        if(event.keyCode === 13){
            onSearchClick()    
        }
    }

    const handleClick = (event) => {
        onSearchClick()        
    };

    return <TextField
    disabled={disabled}
    label={label}
    InputLabelProps={{shrink: true}}
    // sx={{ m: 1, width: '25ch' }}
    size="small"
    onChange={handleChange('val')}
    variant="outlined"
    value={value !== undefined ? value : ''}
    fullWidth={true}    
    onKeyUp={filterOnEnter}    
    InputProps={{
        endAdornment: <InputAdornment position="end" >
        <IconButton                    
            disabled={disabled || !value || value ===''}   
            onClick={onSearchClick}
        >
            <SearchOutlined/>
        </IconButton>
      </InputAdornment>
    }}
    // variant="filled"
  />
}