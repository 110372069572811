import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table, Box} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {formatDate} from '../../utils/DateHandling'
import Tooltip from '@material-ui/core/Tooltip';
import { AttachMoney, CreditCard, Error, ErrorOutline, HelpOutline, LinkSharp, Loyalty, Money, MoneyOff, QuestionAnswer, Schedule } from '@material-ui/icons';
import RelinkInvoice from './RelinkInvoice';
import NativeDialogBox from '../../components/DialogBox/DialogBox';
import { IconButton } from '@material-ui/core';
import InvoiceIcon from './InvoiceIcon';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    cell: {
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    root: {
        width: '100%',
      },
      container: {
        maxHeight: 440,
        minHeight: 440,
      },
    tableContainer: {
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px'
        },
        '&::-webkit-scrollbar-track': {
            webkitBorderRadius: '2px',
            borderRadius: '2px'
        },
        '&::-webkit-scrollbar-thumb': {
            opacity: 0.3,
            webkitBorderRadius: '2px',
            borderRadius: '2px',
            background: '#00000042',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)'
        },
        height: '70vh',
        spacing: 0,
        justify: 'space-around',
    },
  });   

function InvoiceList(props) {
    const classes = useStyles();
    const {invoices, isCorporate, invoiceAttempts, currentAttempt, authToken, currentInvoiceFromParent, 
            onDoubleClick, onClick, onClearAttempt, onSave} = props;

    const [isLinkInvoiceOpen, setIsLinkInvoiceOpen] = useState(false);
    const [applicationId, setApplicationId] = useState(undefined)

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}></TableCell>  
                            {isCorporate ? <TableCell>Membership</TableCell> : null}                                                      
                            <TableCell className={classes.cell} align="center">Inv. Nr.</TableCell>
                            <TableCell className={classes.cell} align="center">Inv. Date</TableCell>
                            <TableCell className={classes.cell} align="center">Period</TableCell>
                            {/* <TableCell align="center">To</TableCell> */}
                            <TableCell className={classes.cell} align="center">Bill Amount</TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {invoices.map((invoice) => invoice ? (
                        <TableRow key={invoice.invoiceId} 
                            className={(invoice.invoiceId === currentInvoiceFromParent 
                                ? 'selected-row' 
                                :  invoiceAttempts?.find(ia => ia.invoiceId === invoice.invoiceId && ia.attemptId === currentAttempt) 
                                    ? 'linked-row'
                                    : invoice.paymentId ? (invoice.refundedAmount > 0 || invoice.chargeBackAmount > 0) ? 'unpaid-row' : 'paid-row' : invoice.isAttempted ? 'unpaid-row' : 'pending-row') }
                            onDoubleClick={() => {                            
                                if(onDoubleClick) {
                                    onDoubleClick(invoice.invoiceId)
                                }}}                        
                            onClick={() => {
                                if(onClearAttempt){
                                    onClearAttempt();
                                }
                                if(onClick){
                                    onClick(invoice.invoiceId);
                                }
                            }}>
                            <TableCell className={classes.cell}>
                                <InvoiceIcon invoice={invoice}></InvoiceIcon>
                            </TableCell> 
                            {isCorporate ? <TableCell align="left">{invoice.membershipId}</TableCell> : null}                          
                            <TableCell  className={classes.cell} align="left">
                                <Box alignItems="flex-start">
                                    {isCorporate ? null :
                                        <IconButton size="small" onClick={
                                            () => {
                                                onClick(invoice.invoiceId);
                                                setIsLinkInvoiceOpen(true);
                                                setApplicationId(invoice.applicationId)
                                            }}>
                                            <LinkSharp></LinkSharp>
                                        </IconButton>    
                                    }
                                    {invoice.invoiceId}
                                </Box>
                            </TableCell>
                            <TableCell className={classes.cell} align="center">{formatDate(invoice.invoiceDate)}</TableCell>
                            <TableCell className={classes.cell} align="center">{formatDate(invoice.fromDate)} - {formatDate(invoice.toDate)}</TableCell>
                            <TableCell className={classes.cell} align="center">{invoice.invoiceAmount.toFixed(2)}</TableCell>                            
                        </TableRow>
                    ): null)}
                    </TableBody>
                </Table>
            </TableContainer>               
            <NativeDialogBox 
                openDialog={isLinkInvoiceOpen}                
                onCloseBtnClicked={() => {
                    setIsLinkInvoiceOpen(false)
                }}
                dialogTemplate={
                    <RelinkInvoice 
                        applicationId={applicationId} 
                        authToken={authToken}
                        invoiceId={currentInvoiceFromParent}
                        onSaved={() => {
                            setIsLinkInvoiceOpen(false)
                            if(onSave) {
                                onSave();
                            }
                        }}>
                    </RelinkInvoice>
                }
                size="sm"
            >                
            </NativeDialogBox>          
        </Paper>
    );
}

export default InvoiceList;