const memberStatus = [
    {
        label: 'Active',
        value: 'true'
    },
    {
        label: 'Inactive',
        value: 'false'
    }
]

export default memberStatus;