import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress} from '@material-ui/core';
import axios from 'axios';
import TextField from "../../components/FormInputs/TextField/TextField";
import DateTimeField from "../../components/FormInputs/DateTimeField/DateTimeField";
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Notification from "../../components/Notification/Notification";
import { createInvoice } from '../../apiCalls/Invoices/createInvoice';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(0),
        size: 20,
    },   
    right: {
        marginLeft: 'auto',
        fullWidth: false,
        width: 20
    },
    extendedIcon: {
        marginRight: theme.spacing(0),
    },
    typography: {
        padding: theme.spacing(1),
    },
    invoiceNumber: {
        paddingTop: theme.spacing(1),
    },
  }));


  const DEFAULT_NOTIFICATION = {
    type: "error",
    message: "",
    open: false
};

const INITIAL_STATE = {
    applicationId: undefined,
    invoiceDate: undefined,
    fromDate: undefined,
    toDate: undefined,

    invoiceAmount: undefined,
    comment: ''
}
  
function InvoiceEditor(props) {
    const {membershipId, authToken, onSave} = props;
    const classes = useStyles(props);

    const [data, setData] = useState({
        membershipId,
        ...INITIAL_STATE
    });

    const [hasNotChanged, setHasNotChanged] = useState(true)
    const [enabled, setEnabled] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [childErrorState, setChildErrorState] = useState(false);
    const [errorMsgs, setErrorMsgs] = useState({});
    const [allowSearch, setAllowSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState({...DEFAULT_NOTIFICATION});    
    const [expandedAccordion, setExpandedAccordion] = useState(false);
    const [deleteClicked, setDeleteClicked] = useState(false);
    
    useEffect(() => {
        if(membershipId){
            setData({
                membershipId,
                ...data
            })
            setAllowSearch(false)
        }        
    }, [])

    useEffect(() => {
        if((data?.invoiceAmount || -1) < 0 || !(data?.invoiceDate) || (!data?.fromDate) || (!data?.toDate) || (!data?.comment)){
            setErrorState(true);            
            return;
        }
            
        setErrorState(false);
    }, [data]);

    const handleFormDataChange = (element, val) => {
        if(element === 'updateStripeInfo'){
            val = !data.updateStripeInfo;
        }else{            
            setHasNotChanged(false)
        }

        if(element === 'isActive'){
            val = !data.isActive;
        }

        setData({...data, [element]: val});
    }

    const handleSave = () => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setLoading(true)

        if(authToken && data){
            createInvoice(data, source, authToken)
            .then(response => {                                
                setLoading(false);
                setEnabled(false);
                setData({
                    membershipId: membershipId,
                    ...INITIAL_STATE});
                setAllowSearch(true);       
                onSave();
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log("Cancelled Invoice fetching");
                } else {
                    console.log('Failed to update Invoice', error);
                    setLoading(false);
                    setNotification({
                        open: true,
                        type: "error",
                        message: "Failed to update Invoice details, please try again"
                    });
                }
            });
        }
    }
    
    return <Grid container spacing={2}>            
    <Grid item xs={12} >{errorState}</Grid>
            <Grid item xs={6}>
                <DateTimeField
                    label="Invoice Date"
                    fullWidth={true}
                    value={data?.invoiceDate}
                    onChange={date => handleFormDataChange('invoiceDate', date)}
                    disablePast={false}
                    required={true}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField 
                    label="Invoice Amount"
                    value={data?.invoiceAmount}
                    onChange={val => handleFormDataChange('invoiceAmount', val)}
                    isNumericOnly
                    required={true}
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    label="From Date"
                    fullWidth={true}
                    value={data?.fromDate}
                    onChange={date => handleFormDataChange('fromDate', date)}
                    disablePast={false}
                    required={true}
                />
            </Grid>
            <Grid item xs={6}>
                <DateTimeField
                    label="To Date"
                    fullWidth={true}
                    value={data?.toDate}
                    onChange={date => handleFormDataChange('toDate', date)}
                    disablePast={false}
                    required={true}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="filled-multiline-static"
                    label="Notes"
                    multiline
                    rows={4}
                    value={data?.comment || ''}
                    variant="outlined"
                    onChange={val => handleFormDataChange('comment', val)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button 
                    variant="contained" 
                    color="secondary" 
                    size="small"
                    onClick={() => handleSave()}
                    disabled={errorState}
                >
                    Create Invoice
                    {
                        loading ?
                        <CircularProgress style={{color: 'white', width: 14, height: 14, marginRight: 5}}/> :
                        null
                    }
                </Button>
            </Grid>
            <Notification
                    open={notification.open}
                    type={notification.type}
                    message={notification.message}
                    onClose={() => {
                        if(notification.type === "success") {
                            setLoading(false);
                        }
                        setNotification({...DEFAULT_NOTIFICATION});                        
                    }}
                />
        </Grid>
}

export default InvoiceEditor;