import {getAxiosInstance} from "../config/axios";
import environment from "../config/environment";

export const getCenterVisits = (source, offset, limit, filters, authToken) => {
    return new Promise((resolve, reject) => {
        getAxiosInstance(authToken).get(`${environment().functionsURL}center-visit`,
            {
                params: {
                    offset: offset,
                    limit: limit,
                    centerId: filters?.centerId || undefined,
                    membershipId: filters?.membershipId || undefined,
                    dateOfVisit: filters?.dateOfVisit || undefined,
                    visitTypeId: filters?.visitTypeId || undefined,
                },
                cancelToken: source.token                
            })
            .then(response => {
                if(response && response.status === 200) {
                    if(response?.data?.length) {
                        resolve(response);
                    }
                    else {
                        resolve([])
                    }
                }
                reject(response)
            })
            .catch(error => reject(error))
    })
}