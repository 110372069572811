import React, { useEffect, useState } from 'react';
import { Grid, Paper} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { formatDate } from '../../utils/DateHandling';

export default function PaymentDisplay(props) {
    const {data, label} = props;

    return (
    <Paper style={{padding: '5px'}} elevation={3}>
        <Grid container spacing={1}>
            <Grid item xs={12}><b>{label}</b></Grid>
            <Grid item xs={5}>Invoice</Grid>
            <Grid item xs={7}>{data?.invoiceId}</Grid>
            <Grid item xs={5}>Invoice Date</Grid>
            <Grid item xs={7}>{formatDate(data?.invoiceDate)}</Grid>
            <Grid item xs={5}>Period</Grid>
            <Grid item xs={7}>{formatDate(data?.fromDate)} {data?.toDate ? <b>-</b>: null} {formatDate(data?.fromDate)}</Grid>
            <Grid item xs={5}>Invoice Amount</Grid>
            <Grid item xs={7}>{data?.invoiceAmount}</Grid>
            <Grid item xs={5}>Payemnt Date</Grid>
            <Grid item xs={7}>{formatDate(data?.payment?.paymentTimestamp)}</Grid>
            <Grid item xs={5}>Payemnt Amount</Grid>
            <Grid item xs={7}>{data?.payment?.paymentAmount?.toFixed(2)}</Grid>
            <Grid item xs={5}>Comments</Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={12}>
                <TextField
                    style={{padding: '5px'}}
                    id="outlined-multiline-static"
                    multiline
                    fullWidth={true}
                    rows={2}
                    variant="outlined"
                    disabled={true}
                    value={data?.payment?.confirmation}
                />
            </Grid>
        </Grid>
    </Paper>)
}